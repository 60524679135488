import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import Block from "@components/block"
import Animate from "@components/animate"

// -------------------------------------------------------- | styles

import { column } from "./styles.module.scss"

// -------------------------------------------------------- | component

const Column = ({ blocks }) => {
  return (
    <div className={classNames(column, "column")}>
      {(blocks || []).map((block, idx) => {
        if (block.__typename === "ContentfulImage") {
          return (
            <Animate className="image" animate="fade_in" key={idx}>
              <Block {...block} />
            </Animate>
          )
        } else {
          return <Block key={idx} {...block} />
        }
      })}
    </div>
  )
}

Column.propTypes = {
  /**
   * An array of component objects that get passed on to the <Block />.
   */
  blocks: PropTypes.arrayOf(PropTypes.object)
}

Column.defaultProps = {
  blocks: []
}

export default Column
