/* eslint-disable jsx-a11y/no-autofocus */
import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import Button from "@components/button"

import { inputWrapper } from "./styles.module.scss"

const onClick = () => {
  // TODO: add onClick function
}

const InputGroup = (props) => {
  return (
    <div
      className={classNames(inputWrapper, {
        [props.className]: props.className
      })}
    >
      <input
        type="text"
        name="q"
        id="q"
        value={props.value}
        onChange={(e) => props.onChange(e)}
        placeholder={props.placeholder}
      />
      <Button type="submit" onClick={onClick.bind(this)} size="medium">
        {props.label}
      </Button>
    </div>
  )
}

InputGroup.propTypes = {
  /**
   * A CSS class adding to the wrapping element.
   */
  className: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string
}

export default InputGroup
