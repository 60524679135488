import React, { useRef, useEffect, useState } from "react"
import classNames from "classnames"
import { useMediaQuery } from "react-responsive"
import PropTypes from "prop-types"

import RichContentBlock from "@components/rich-content-block"

import * as styles from "./styles.module.scss"

const ProgressTracker = (props) => {
  const isMobile = useMediaQuery({ query: "(max-width: 720px) or (max-height: 700px)" })

  const { entries } = props
  const [activeHeading, setActiveHeading] = useState(0)
  const [animationStates, setAnimationStates] = useState(entries.map(() => false))
  const sectionRefs = useRef(entries.map(() => React.createRef()))
  const timelineRefs = useRef(entries.map(() => React.createRef()))

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const visibleEntries = entries.filter((entry) => entry.isIntersecting)
        visibleEntries.sort((a, b) => {
          return Number(a.target.dataset.index) - Number(b.target.dataset.index)
        })

        // Get the last visible entry, if any
        const lastVisibleEntry = visibleEntries[visibleEntries.length - 1]
        if (lastVisibleEntry) {
          const index = lastVisibleEntry.target.dataset.index
          setActiveHeading(index)
        }
      },
      {
        root: null,
        threshold: 1.0
      }
    )

    ;[...sectionRefs.current].forEach((ref, index) => {
      if (ref.current) {
        ref.current.dataset.index = index.toString()
        observer.observe(ref.current)
      }
    })

    return () => {
      ;[...sectionRefs.current].forEach((ref) => {
        if (ref.current) {
          observer.unobserve(ref.current)
        }
      })
      observer.disconnect()
    }
  }, [entries]) // Removed 'entries' from the dependency array

  const handleScroll = () => {
    if (!isMobile) {
      const viewportHeight = window.innerHeight
      const newAnimationStates = entries.map((_, index) => {
        const timelineRect = timelineRefs.current[index]?.current?.getBoundingClientRect()
        const sectionRect = sectionRefs.current[index]?.current?.getBoundingClientRect()

        // Calculate the vertical midpoint of each element relative to the viewport
        const timelineMidpoint = (timelineRect.top + timelineRect.bottom) / 2
        const sectionTop = sectionRect.top

        // Calculate scroll percentages
        const timelineScrollPercentage = (timelineMidpoint / viewportHeight) * 100
        const sectionScrollPercentage = (sectionTop / viewportHeight) * 100

        // Check if the scroll percentages are within a certain threshold (e.g., 5%)
        return Math.abs(timelineScrollPercentage - sectionScrollPercentage) <= 5
      })

      setAnimationStates(newAnimationStates)
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [entries]) // Dependencies ensure the function updates with the entries

  const scrollToSection = (index) => {
    const sectionRef = sectionRefs.current[index]
    if (sectionRef && sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: "smooth", block: "start" })
    }
  }

  const getTrimmedHeading = (input) => {
    return input ? input.replace(/__/g, "") : ""
  }

  return (
    <>
      <div className={classNames({ [styles.progress_tracker]: !isMobile })}>
        {!isMobile && (
          <div className={styles.timeline}>
            {entries.map((section, index) => (
              <div
                key={index}
                ref={timelineRefs.current[index]}
                className={classNames(styles.point, { [styles.active]: activeHeading == index })}
                onClick={() => scrollToSection(index)}
                onKeyDown={() => {
                  scrollToSection(index)
                }}
                tabIndex={index}
                role="button"
              >
                {getTrimmedHeading(section.heading)}
                {animationStates[index] && (
                  <div className={styles.connection}>
                    <div className={styles.line}></div>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
        <div>
          {entries.map((section, index) => (
            <RichContentBlock
              key={index}
              showAnimation={activeHeading == index}
              forwardedRef={sectionRefs.current[index]}
              {...section}
            />
          ))}
        </div>
      </div>
    </>
  )
}

ProgressTracker.propTypes = {
  entries: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string,
      content: PropTypes.string
    })
  )
}

export default ProgressTracker
