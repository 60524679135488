import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { categoryPath } from "@root/src/utils"

import Animate from "@components/animate"
import Breadcrumbs from "@components/breadcrumbs"
import Button from "@components/button"
import Link from "@components/link"
import SVG from "@components/svg"
import TimeToRead from "@components/time-to-read"

const pluralize = require("pluralize")

// -------------------------------------------------------- | styles

import {
  body_container,
  card,
  card_button,
  card_content,
  card_image_overlay,
  card_image,
  card_media_link,
  category_container,
  theme_author,
  theme_background_image,
  theme_background_image_without_link,
  theme_chyron,
  theme_media,
  theme_list_item,
  white_bg_hover_gradient,
  style_white_bg_hover_gradient,
  btn_default_white,
  heading_card_stack,
  heading_card_image,
  content_card_stack,
  button_card_stack,
  gray_bg_hover_gradient
} from "./styles.module.scss"

const themeOptions = {
  author: theme_author,
  background_image: theme_background_image,
  background_image_without_link: theme_background_image_without_link,
  chyron: theme_chyron,
  media: theme_media,
  list_item: theme_list_item,
  white_bg_hover_gradient: white_bg_hover_gradient,
  heading_card_stack: heading_card_stack,
  gray_bg_hover_gradient: gray_bg_hover_gradient
}

// -------------------------------------------------------- | component

const Card = (props) => {
  let {
    body,
    button_label,
    category,
    currentIndex,
    duration,
    gated,
    heading,
    image,
    image_background,
    image_chyron,
    image_media,
    theme,
    type,
    url,
    wordCount
  } = props

  // ------------------------------------------------------ | Author Config
  if (props.__typename === "ContentfulAuthor") {
    body = props.bio?.childMarkdownRemark?.excerpt
    button_label = `More from ${props.name.split(" ").shift()}`
    category = {
      label: props.category?.title,
      url: categoryPath(props.category)
    }
    heading = props.name
    image_media = props.image
    image = props.image
    theme = "author"
    url = props.slug && `/knowledge-hub/subject-matter-experts/${props.slug}`
  }

  // ------------------------------------------------------

  body = typeof body === "object" ? body?.childMarkdownRemark?.html : body

  if (currentIndex > 0 && currentIndex !== undefined) {
    currentIndex
  }
  theme = theme || "media"

  if (theme === "background_image" && !url) {
    theme = "background_image_without_link"
  }

  // ------------------------------------------------------

  const classes = classNames(card, {
    [themeOptions[theme]]: themeOptions[theme]
  })

  // ------------------------------------------------------

  let imageData

  if (
    theme === "background_image" ||
    theme === "background_image_without_link" ||
    theme === "heading_card_stack"
  ) {
    imageData = image_background
  }

  if (theme === "media" || theme === "author") {
    imageData = image_media
  }

  if (theme === "chyron") {
    imageData = image_chyron || image
  }

  // ------------------------------------------------------

  let postType = type?.label

  if (postType && postType !== "News") {
    postType = pluralize.singular(postType)
  }

  const breadcrumbs_config = {
    links: [
      {
        label: category?.label,
        url: category?.url
      },
      {
        label: postType,
        url: type?.url
      }
    ]
  }

  const renderBreadcrumbs = () => {
    return (
      <div className={category_container}>
        {gated && <SVG name="lock" />}
        {(category || type) && <Breadcrumbs {...breadcrumbs_config} />}
      </div>
    )
  }

  const cardContents = (
    <>
      {imageData && !imageData?.url && !["media", "list_item"].includes(theme) && (
        <GatsbyImage
          alt={imageData.title}
          className={`${card_image} ${theme === "heading_card_stack" ? heading_card_image : ""}`}
          image={getImage(imageData)}
        />
      )}
      {imageData?.url && !["media", "list_item"].includes(theme) && (
        <img src={imageData?.url} className={card_image} alt={imageData?.title} />
      )}

      {(theme === "background_image" || theme === "background_image_without_link") && (
        <div className={card_image_overlay} />
      )}

      <div
        className={`${card_content} ${theme === "heading_card_stack" ? content_card_stack : ""}`}
      >
        <div>
          {!["media", "list_item"].includes(theme) && (
            <>
              {heading && <h3>{heading}</h3>}
              {theme === "author" && renderBreadcrumbs()}
              {body && (
                <div className={body_container} dangerouslySetInnerHTML={{ __html: body }} />
              )}
            </>
          )}

          {theme === "media" && (
            <>
              <Link className={card_media_link} to={url}>
                {imageData && !imageData?.url && (
                  <GatsbyImage
                    alt={imageData?.title}
                    className={card_image}
                    image={getImage(imageData)}
                  />
                )}
                {imageData?.url && (
                  <img src={imageData?.url} className={card_image} alt={imageData?.title} />
                )}
                {heading && <h3>{heading}</h3>}
              </Link>
              {renderBreadcrumbs()}
              {(wordCount || duration) && (
                <TimeToRead wordCount={wordCount} duration={duration} type={postType} />
              )}
            </>
          )}

          {theme === "list_item" && (
            <>
              <Link className={card_media_link} to={url}>
                {heading && <h3>{heading}</h3>}
              </Link>
              {renderBreadcrumbs()}
              {(wordCount || duration) && (
                <TimeToRead wordCount={wordCount} duration={duration} type={postType} />
              )}
            </>
          )}
        </div>
        {button_label && theme === "white_bg_hover_gradient" && (
          <div className={`${card_button} ${btn_default_white}`}>
            <Button size="small" type="button" to={url && url}>
              {button_label} <SVG name="angle-right" />
            </Button>
          </div>
        )}
        {button_label &&
          (theme === "background_image" ||
            theme === "author" ||
            theme === "heading_card_stack") && (
            <div
              className={`${card_button} ${
                theme === "heading_card_stack" ? button_card_stack : ""
              }`}
            >
              <Button size="small" type="button" to={url && url}>
                {button_label} <SVG name="angle-right" />
              </Button>
            </div>
          )}
      </div>
    </>
  )

  // --------------------------------------------------------

  let cardComponent = (
    <div
      className={`${classes} ${
        theme === "white_bg_hover_gradient" && style_white_bg_hover_gradient
      }`}
      data-current-index="${currentIndex}"
    >
      {cardContents}
    </div>
  )

  if ((theme === "background_image" || theme === "heading_card_stack") && url) {
    cardComponent = (
      <Link to={url} className={classes}>
        <div>{cardContents}</div>
      </Link>
    )
  }

  if (theme === "background_image_without_link" || theme === "author") {
    cardComponent = <div className={classes}>{cardContents}</div>
  }

  if (theme === "chyron") {
    cardComponent = (
      <Link to={url} className={classes}>
        {cardContents}
      </Link>
    )
  }

  // -------------------------------------------------------- | Animation

  let animate = "fade_in"
  let delay
  let anim_duration

  if (theme === "background_image") {
    animate = "fade_in_up"
    delay = currentIndex * 300
  }

  if (theme === "author") {
    if (currentIndex) {
      currentIndex++
      animate = "fade_in_left"
      delay = currentIndex * 125
      anim_duration = 750
    } else {
      animate = "fade_in"
      delay = 1
      anim_duration = 1
    }
  }

  if (theme === "media") {
    animate = "fade_in_left"
    delay = currentIndex * 300
  }

  // --------------------------------------------------------

  return (
    <Animate
      animate={animate}
      display="flex"
      duration={anim_duration && anim_duration}
      delay={delay}
      className={theme === "white_bg_hover_gradient" ? white_bg_hover_gradient : null}
    >
      {cardComponent}
    </Animate>
  )
}

Card.propTypes = {
  /**
   * Specifies the content
   * that is displayed under the heading
   */
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /**
   * Specifies the label of the button on the background image theme
   */
  button_label: PropTypes.string,
  /**
   * Specifies array of button objects
   */
  buttons: PropTypes.array,
  /**
   * Specifies the category that the content belongs to in the Knowledge Hub
   */
  category: PropTypes.shape({
    label: PropTypes.string,
    url: PropTypes.string,
    title: PropTypes.string,
    slug: PropTypes.string
  }),
  /**
   * Get current index of the card component
   */
  currentIndex: PropTypes.number,
  /**
   * Specifies if the content is gated
   */
  gated: PropTypes.bool,
  /**
   * Specifies the heading
   */
  heading: PropTypes.string,
  /**
   * Specifies the image src and alt text
   */
  image: PropTypes.object,
  image_background: PropTypes.object,
  image_chyron: PropTypes.object,
  image_media: PropTypes.object,
  /**
   * Specifies the card theme
   */
  theme: PropTypes.oneOf(Object.keys(themeOptions)),
  /**
   * Specifies the type of content in the Knowledge Hub
   */
  type: PropTypes.shape({
    label: PropTypes.string,
    url: PropTypes.string
  }),
  /**
   * Specifies the url for the card
   */
  url: PropTypes.string,
  /**
   * TimeToRead override provided by content editors
   */
  duration: PropTypes.string,
  /**
   * Specifies the word count of the post
   */
  wordCount: PropTypes.number,
  // --------------- Author Card props
  /**
   * Contentful type
   */
  __typename: PropTypes.string,
  /**
   * Author card body
   */
  bio: PropTypes.shape({
    childMarkdownRemark: PropTypes.shape({
      excerpt: PropTypes.string
    })
  }),
  /**
   * Author name
   */
  name: PropTypes.string,
  /**
   * Author slug
   */
  slug: PropTypes.string
}

Card.defaultProps = {}

export default Card
