// ---------------------------------------------------------

import React, { useState } from "react"
import PropTypes from "prop-types"
import { ReactSVG } from "react-svg"
import lowerCase from "lodash/lowerCase"
import OutsideClickHandler from "react-outside-click-handler"
// ---------------------------------------------------------

const { parseContentfulJSON } = require("@src/utils")

// ---------------------------------------------------------

import Animate from "@components/animate"
import CollaborativeCloudAnimatedGraphicItem from "./item"

// ---------------------------------------------------------

import {
  animated_graphic_content,
  animated_graphic_heading_arrow_vertical,
  animated_graphic_heading_arrow,
  animated_graphic_heading_vertical,
  animated_graphic_heading,
  animated_graphic_item_button,
  animated_graphic_items,
  collaborative_cloud_animated_graphic_container,
  collaborative_cloud_animated_graphic,
  collaborative_cloud_heading,
  heading_bottom_column,
  heading_left_column
} from "./styles.module.scss"

// ---------------------------------------------------------

const CollaborativeCloudAnimatedGraphic = ({ config }) => {
  let { mainHeading, mainDescription, headingBottom, headingLeft, items } = parseContentfulJSON(config)

  // ---------------------------------------------------------

  const [activeIndex, setActiveIndex] = useState(null)

  // -------------------------------------------------------

  return (
    <div className={collaborative_cloud_animated_graphic_container}>
      {mainHeading && (
        <Animate animate="fade_in_up" delay={500}>
          <header className={collaborative_cloud_heading}>
            {mainHeading && <h2>{mainHeading}</h2>}
            {mainDescription && <div dangerouslySetInnerHTML={{ __html: mainDescription }} />}
          </header>
        </Animate>
      )}

      <div>
        <Animate animate="fade_in_up" delay={500} className={collaborative_cloud_animated_graphic}>
          <Animate animate="fade_in_up" delay={1200} className={animated_graphic_heading_vertical}>
            <div className={heading_left_column}>
              <div className={animated_graphic_heading_arrow_vertical} />
              <span>{headingLeft}</span>
            </div>

            <ReactSVG src={`/images/svgs/cc-${lowerCase(headingLeft).split(" ").join("-")}.svg`} />
          </Animate>

          <div className={animated_graphic_content}>
            <OutsideClickHandler onOutsideClick={() => setActiveIndex(null)}>
              <div className={animated_graphic_items}>
                {items.map((item, key) => {
                  return (
                    <div
                      className={animated_graphic_item_button}
                      key={key}
                      onClick={() => setActiveIndex(key)}
                      onKeyPress={() => setActiveIndex(key)}
                      role="button"
                      tabIndex="0"
                    >
                      <CollaborativeCloudAnimatedGraphicItem
                        setActiveIndex={setActiveIndex}
                        itemIndex={key}
                        notActive={key !== activeIndex && activeIndex !== null}
                        {...item}
                      />
                    </div>
                  )
                })}
              </div>
            </OutsideClickHandler>

            <Animate animate="fade_in_left" delay={1600} className={animated_graphic_heading}>
              <ReactSVG
                src={`/images/svgs/cc-${lowerCase(headingBottom).split(" ").join("-")}.svg`}
              />
              <div className={heading_bottom_column}>
                <div className={animated_graphic_heading_arrow} />
                <span>{headingBottom}</span>
              </div>
            </Animate>
          </div>
        </Animate>
      </div>
    </div>
  )
}

// ---------------------------------------------------------

CollaborativeCloudAnimatedGraphic.propTypes = {
  config: PropTypes.object
}

export default CollaborativeCloudAnimatedGraphic
