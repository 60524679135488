export default {
  props: {
    heading: "Careers @ 84.51",
    image_chyron: {
      gatsbyImageData: {
        layout: "constrained",
        backgroundColor: "#080808",
        images: {
          fallback: {
            src: "/images/b5d2aec2f491cf749fcf3274a8eb5cab/49963/footer-card.jpg",
            srcSet:
              "/images/b5d2aec2f491cf749fcf3274a8eb5cab/f4400/footer-card.jpg 140w,\n/images/b5d2aec2f491cf749fcf3274a8eb5cab/160d7/footer-card.jpg 280w,\n/images/b5d2aec2f491cf749fcf3274a8eb5cab/49963/footer-card.jpg 560w,\n/images/b5d2aec2f491cf749fcf3274a8eb5cab/21e7c/footer-card.jpg 1120w",
            sizes: "(min-width: 560px) 560px, 100vw"
          },
          sources: [
            {
              srcSet:
                "/images/b5d2aec2f491cf749fcf3274a8eb5cab/c9206/footer-card.webp 140w,\n/images/b5d2aec2f491cf749fcf3274a8eb5cab/74588/footer-card.webp 280w,\n/images/b5d2aec2f491cf749fcf3274a8eb5cab/310b8/footer-card.webp 560w,\n/images/b5d2aec2f491cf749fcf3274a8eb5cab/48f16/footer-card.webp 1120w",
              type: "image/webp",
              sizes: "(min-width: 560px) 560px, 100vw"
            }
          ]
        },
        width: 610,
        height: 260
      }
    },
    theme: "chyron",
    url: "#"
  },
  authorTheme: {
    button_label: "View All Works",
    body: "As vice president of commercial insights for 84.51°, Barbara Connors is an innovator, problem solver and strategist for Kroger and many of the most recognizable consumer packaged goods companies in the global grocery…",
    heading: "Author Name",
    theme: "author",
    image_media: {
      gatsbyImageData: {
        layout: "constrained",
        backgroundColor: "#282828",
        images: {
          fallback: {
            src: "/storybook/b382574fc4a26a2754a43b12af8a18d0/aa807/media-card.jpg",
            srcSet:
              "/storybook/b382574fc4a26a2754a43b12af8a18d0/aff3e/media-card.jpg 78w,\n/storybook/b382574fc4a26a2754a43b12af8a18d0/b0f94/media-card.jpg 155w,\n/storybook/b382574fc4a26a2754a43b12af8a18d0/aa807/media-card.jpg 310w,\n/storybook/b382574fc4a26a2754a43b12af8a18d0/34bef/media-card.jpg 620w",
            sizes: "(min-width: 310px) 310px, 100vw"
          },
          sources: [
            {
              srcSet:
                "/storybook/b382574fc4a26a2754a43b12af8a18d0/e2144/media-card.webp 78w,\n/storybook/b382574fc4a26a2754a43b12af8a18d0/343e4/media-card.webp 155w,\n/storybook/b382574fc4a26a2754a43b12af8a18d0/a189b/media-card.webp 310w,\n/storybook/b382574fc4a26a2754a43b12af8a18d0/c2a7e/media-card.webp 620w",
              type: "image/webp",
              sizes: "(min-width: 310px) 310px, 100vw"
            }
          ]
        },
        width: 140,
        height: 140
      }
    },
    url: "#",
    category: {
      label: "Data science",
      url: "/data-science"
    },
    type: {
      label: null,
      url: null
    }
  },
  backgroundImageTheme: {
    button_label: "Learn more",
    heading: "Media",
    body: "<p>Continually engage with customers through a personalized advertising experience, on Kroger owned and partner properties. We make the media landscape more addressable, actionable, and accountable.</p>",
    image_background: {
      gatsbyImageData: {
        layout: "constrained",
        backgroundColor: "#181818",
        images: {
          fallback: {
            src: "/storybook/9a415748c14d87d57b679358d3f0cb9e/2aa50/background-image-card.jpg",
            srcSet:
              "/storybook/9a415748c14d87d57b679358d3f0cb9e/4241d/background-image-card.jpg 125w,\n/storybook/9a415748c14d87d57b679358d3f0cb9e/67395/background-image-card.jpg 250w,\n/storybook/9a415748c14d87d57b679358d3f0cb9e/2aa50/background-image-card.jpg 500w,\n/storybook/9a415748c14d87d57b679358d3f0cb9e/0ddc3/background-image-card.jpg 1000w",
            sizes: "(min-width: 500px) 500px, 100vw"
          },
          sources: [
            {
              srcSet:
                "/storybook/9a415748c14d87d57b679358d3f0cb9e/2a951/background-image-card.webp 125w,\n/storybook/9a415748c14d87d57b679358d3f0cb9e/87aae/background-image-card.webp 250w,\n/storybook/9a415748c14d87d57b679358d3f0cb9e/f6eba/background-image-card.webp 500w,\n/storybook/9a415748c14d87d57b679358d3f0cb9e/a2928/background-image-card.webp 1000w",
              type: "image/webp",
              sizes: "(min-width: 500px) 500px, 100vw"
            }
          ]
        },
        width: 500,
        height: 712
      }
    },
    theme: "background_image",
    url: "#"
  },
  chyronTheme: {},
  mediaTheme: {
    heading:
      "The Impact of Data Science on Customer Intelligence, Space Strategy and Supply Chain Optimization",
    theme: "media",
    image_media: {
      gatsbyImageData: {
        layout: "constrained",
        backgroundColor: "#282828",
        images: {
          fallback: {
            src: "/storybook/b382574fc4a26a2754a43b12af8a18d0/aa807/media-card.jpg",
            srcSet:
              "/storybook/b382574fc4a26a2754a43b12af8a18d0/aff3e/media-card.jpg 78w,\n/storybook/b382574fc4a26a2754a43b12af8a18d0/b0f94/media-card.jpg 155w,\n/storybook/b382574fc4a26a2754a43b12af8a18d0/aa807/media-card.jpg 310w,\n/storybook/b382574fc4a26a2754a43b12af8a18d0/34bef/media-card.jpg 620w",
            sizes: "(min-width: 310px) 310px, 100vw"
          },
          sources: [
            {
              srcSet:
                "/storybook/b382574fc4a26a2754a43b12af8a18d0/e2144/media-card.webp 78w,\n/storybook/b382574fc4a26a2754a43b12af8a18d0/343e4/media-card.webp 155w,\n/storybook/b382574fc4a26a2754a43b12af8a18d0/a189b/media-card.webp 310w,\n/storybook/b382574fc4a26a2754a43b12af8a18d0/c2a7e/media-card.webp 620w",
              type: "image/webp",
              sizes: "(min-width: 310px) 310px, 100vw"
            }
          ]
        },
        width: 310,
        height: 151
      }
    },
    category: {
      label: "Data science",
      url: "/data-science"
    },
    type: {
      label: "White paper",
      url: "/white-paper"
    },
    gated: true
  },
  listTheme: {
    heading:
      "The Impact of Data Science on Customer Intelligence, Space Strategy and Supply Chain Optimization",
    theme: "list_item",
    category: {
      label: "Data science",
      url: "/data-science"
    },
    type: {
      label: "White paper",
      url: "/white-paper"
    },
    gated: true
  }
}
