import React, { Component } from "react"
import PropTypes from "prop-types"
import { isGatsbyLink } from "@components/link/helpers"
import { navigate } from "gatsby"

import Container from "@components/container"
import SideNavigation from "@components/side-navigation"
import Collapsible from "@components/collapsible"

import { FAQ_container, FAQ_with_sidebar, sidebar, FAQ_items } from "./styles.module.scss"

const { parseContentfulJSON } = require("@src/utils")
const isBrowser = () => typeof window !== "undefined"
class FAQ extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cfg: parseContentfulJSON(props.config),
      data: props.references,
      faqCategory: ""
    }
    this.getFAQCategories = this.getFAQCategories.bind(this)
    this.getFAQwithSidebar = this.getFAQwithSidebar.bind(this)
    this.parseParams = this.parseParams.bind(this)
    this.getFilteredResults = this.getFilteredResults.bind(this)
    this.updateCategoryFilter = this.updateCategoryFilter.bind(this)
    this.getAllFAQ = this.getAllFAQ.bind(this)
  }

  componentDidMount() {
    const query = this.parseParams(isBrowser() ? window.location.search : "")
    if (query && query.category && query.category != "") {
      this.setState({ faqCategory: query.category })
    }
    if (query && !query.category && this.state.cfg.showViewAll) {
      this.setState({ faqCategory: "all" })
    }
  }

  parseParams(querystring) {
    // parse query string
    const params = new URLSearchParams(querystring)
    const obj = {}

    // iterate over all keys
    for (const key of params.keys()) {
      if (params.getAll(key).length > 1) {
        obj[key] = params.getAll(key)
      } else {
        obj[key] = params.get(key)
      }
    }
    return obj
  }

  getFAQCategories() {
    const categories = []

    if (this.state.cfg.showViewAll) {
      categories.push({
        label: "View All",
        link: `${isBrowser() ? window.location.pathname : ""}?category=all`
      })
    }

    this.state.data.map((item) => {
      if (item.collapsibleCategory) {
        item.collapsibleCategory.map((title) => {
          const alreadyExists = categories.some((element) => {
            if (element.label === title) {
              return true
            }
          })

          if (!alreadyExists) {
            categories.push({
              label: title,
              link: `${isBrowser() ? window.location.pathname : ""}?category=${encodeURI(title)}`
            })
          }
        })
      }
    })

    if (
      categories.length > 0 &&
      (!this.state.faqCategory || (this.state.faqCategory == "" && !this.state.cfg.showViewAll))
    ) {
      this.setState({ faqCategory: categories[0].label })
      if (isBrowser() && isGatsbyLink(categories[0].link)) {
        navigate(categories[0].link)
      } else if (isBrowser()) {
        window.location.href = categories[0].link
      }
    }

    return categories
  }

  getFilteredResults() {
    let filteredResults = this.state.data
    if (!this.state.cfg.showViewAll || this.state.faqCategory !== "all") {
      filteredResults = this.state.data.filter((result) => {
        let isCat = false
        if (result.collapsibleCategory) {
          result.collapsibleCategory.map((cat) => {
            if (cat == this.state.faqCategory) {
              isCat = true
            }
          })
        }
        return isCat
      })
    }

    return (
      <div>
        {filteredResults.map((item, index) => (
          <Collapsible key={index} trigger={item.title}>
            <div
              dangerouslySetInnerHTML={{
                __html: item.body ? item.body.childMarkdownRemark.html : ""
              }}
            />
          </Collapsible>
        ))}
      </div>
    )
  }

  getFAQwithSidebar() {
    const sideNavItems = this.getFAQCategories()

    return (
      <div className={FAQ_with_sidebar}>
        <div className={sidebar}>
          <SideNavigation items={sideNavItems} onClick={(e) => this.updateCategoryFilter(e)} />
        </div>
        <div className={FAQ_items}>{this.getFilteredResults()}</div>
      </div>
    )
  }

  getAllFAQ() {
    return (
      <div>
        {this.state.data.map((item, index) => (
          <Collapsible key={index} trigger={item.title}>
            <div
              dangerouslySetInnerHTML={{
                __html: item.body ? item.body.childMarkdownRemark.html : ""
              }}
            />
          </Collapsible>
        ))}
      </div>
    )
  }

  updateCategoryFilter(value) {
    if (value == "View All") {
      this.setState({ faqCategory: "all" })
    } else {
      this.setState({ faqCategory: value })
    }
  }

  render() {
    return (
      <Container padding="large" theme="white_background" className={FAQ_container}>
        <div className={FAQ_container}>
          {this.state.cfg.title && <h1>{this.state.cfg.title}</h1>}
          {this.state.cfg.showSideNav && this.getFAQwithSidebar()}
          {!this.state.cfg.showSideNav && this.getAllFAQ()}
        </div>
      </Container>
    )
  }
}

FAQ.propTypes = {
  config: PropTypes.object,
  references: PropTypes.array
}

export default FAQ
