import React, { useState } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { truncate } from "lodash"

import Image from "@components/image"
import SVG from "@components/svg"
import Link from "@components/link"

import * as styles from "./styles.module.scss"

const AuthorMiniBio = ({ bio, className, image, name, sme = false, title, slug }) => {
  if (!name) return null
  const [isCollapsed, setIsCollapsed] = useState(true)
  const bioContent = bio?.childMarkdownRemark?.rawMarkdownBody

  return (
    <div
      className={classNames(styles.bio_wrapper, className, {
        [styles.is_sme]: sme
      })}
    >
      {image && <Image src={image} className={styles.profile_image} />}
      <div className={styles.wrapper}>
        <div className={styles.header}>
          {sme ? (
            <>
              <Link to={slug && `/knowledge-hub/subject-matter-experts/${slug}`}>
                <span className={styles.name}>{name}</span>
              </Link>

              {title && <span className={styles.title}>{`, ${title}`}</span>}
            </>
          ) : (
            <>
              <span className={styles.name}>{name}</span>
              {title && <span className={styles.title}>{`, ${title}`}</span>}
            </>
          )}
        </div>
        {sme && (
          <div className={styles.sme_tag}>
            <SVG name="certified" /> Subject Matter Expert
          </div>
        )}

        {bio && (
          <div className={styles.bio}>
            {isCollapsed && bioContent.length > 220
              ? truncate(bioContent, { length: 220 })
              : bioContent}
            {sme ? (
              <Link
                to={slug && `/knowledge-hub/subject-matter-experts/${slug}`}
                className={styles.learn_more}
              >
                learn more
              </Link>
            ) : bioContent.length > 220 ? (
              <button className={styles.learn_more} onClick={() => setIsCollapsed(!isCollapsed)}>
                {isCollapsed ? "learn more" : "show less"}
              </button>
            ) : null}
          </div>
        )}
      </div>
    </div>
  )
}

AuthorMiniBio.propTypes = {
  /**
   * Author's Bio
   */
  bio: PropTypes.shape({
    bio: PropTypes.string
  }),
  /**
   * Additional classes on the wrapping element.
   */
  className: PropTypes.string,
  /**
   * Author's Photo
   */
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /**
   * Author's Name
   */
  name: PropTypes.string,
  /**
   * Author is a Subject Matter Expert
   */
  sme: PropTypes.bool,
  /**
   * Author's Job Title
   */
  title: PropTypes.string,
  /**
   * Link to SME detail page
   */
  slug: PropTypes.string
}

export default AuthorMiniBio
