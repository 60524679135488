// ---------------------------------------------------------

import Breadcrumbs from "@components/breadcrumbs"
import IconCard from "@components/snippets/icon-card"
import Button from "@components/button"
import Card from "@components/card"
import Careers from "@components/snippets/careers"
import Carousel from "@components/carousel"
import CollaborativeCloudAnimatedGraphic from "@components/snippets/collaborative-cloud-animated-graphic"
import Column from "@components/column"
import ContactForm from "@components/snippets/contact-form"
import Container from "@components/container"
import Content from "@components/content"
import DataViz from "@components/data-viz"
import EmptyColumn from "@components/snippets/empty-column"
import FocalHighlight from "@components/focal-highlight"
import Form from "@components/form"
import Grid from "@components/grid"
import Icon from "@components/icon"
import Image from "@components/image"
import Jumbotron from "@components/jumbotron"
import LatestPosts from "@components/latest-posts"
import LogoTrain from "@components/logo-train"
import MediaObject from "@components/media-object"
import PostHighlight from "@components/post-highlight"
import ProgressTracker from "@components/progress-tracker"
import RichContentBlock from "@components/rich-content-block"
import Scaffold from "@components/scaffold"
import SparkCallout from "@components/snippets/spark-callout"
import BenefitsList from "@components/snippets/benefits-list"
import SubscribeForm from "@components/snippets/subscribe-form"
import Video from "@components/video"
import Collapsible from "@components/collapsible"
import FAQ from "@components/snippets/faq"

// ---------------------------------------------------------

export default {
  breadcrumbs: Breadcrumbs,
  ContentfulButton: Button,
  ContentfulAuthor: Card,
  ContentfulCard: Card,
  ContentfulCarousel: Carousel,
  ContentfulColumn: Column,
  ContentfulContainer: Container,
  ContentfulContentBlock: Content,
  ContentfulDataVisualization: DataViz,
  ContentfulForm: Form,
  ContentfulIcon: Icon,
  ContentfulImage: Image,
  ContentfulJumbotron: Jumbotron,
  ContentfulMediaObject: MediaObject,
  ContentfulProgressTracker: ProgressTracker,
  ContentfulRichContentBlock: RichContentBlock,
  ContentfulRow: Grid,
  ContentfulVideo: Video,
  Scaffold: Scaffold,
  "benefits-list": BenefitsList,
  "collaborative-cloud-animated-graphic": CollaborativeCloudAnimatedGraphic,
  "empty-column": EmptyColumn,
  "focal-highlight": FocalHighlight,
  "latest-posts": LatestPosts,
  "logo-train": LogoTrain,
  "post-highlight": PostHighlight,
  "spark-callout": SparkCallout,
  "subscribe-form": SubscribeForm,
  careers: Careers,
  "contact-form": ContactForm,
  faq: FAQ,
  ContentfulCollapsible: Collapsible,
  "icon-card": IconCard
}
