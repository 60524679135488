import React from "react"

import Component from "./component"
import fixtures from "./fixtures"

const Form = (props) => <Component {...props} />

export default Form

export { Component as component, fixtures }
