import React, { useState } from "react"
import PropTypes from "prop-types"
import IframeResizer from "iframe-resizer-react"

// ---------------------------------------------------------

import { form_wrapper, align_left } from "./styles.module.scss"

// ---------------------------------------------------------

import classNames from "classnames"

// ---------------------------------------------------------

const Form = (props) => {
  let { align = false, body, title, url } = props
  return (
    <div
      className={classNames(form_wrapper, {
        [align_left]: align
      })}
    >
      {body && <header dangerouslySetInnerHTML={{ __html: body?.childMarkdownRemark?.html }} />}
      <IframeResizer checkOrigin={false} title={title} src={url} style={{ minWidth: "100%" }} />
    </div>
  )
}

Form.propTypes = {
  align: PropTypes.bool,
  body: PropTypes.shape({
    childMarkdownRemark: PropTypes.shape({
      html: PropTypes.string
    })
  }),
  title: PropTypes.string,
  url: PropTypes.string
}

Form.defaultProps = {}

export default Form
