export default {
  default: {
    button: {
      url: "#",
      label: "Contact"
    },
    signInLinks: [
      {
        heading: "Sign In",
        links: [
          {
            url: "https://mp.8451.com",
            label: "Kroger Ad Platform"
          },
          {
            url: "https://stratum.8451.com",
            label: "84.51° Stratum"
          },
          {
            url: "https://prism.8451.com",
            label: "84.51° Prism"
          }
        ]
      }
    ],
    theme: "dark",
    menus: [
      {
        heading: "Solutions",
        links: [
          {
            url: "/solutions/insights/",
            label: "Insights",
            children: [
              {
                url: "/solutions/detail/",
                label: "84.51° Stratum"
              },
              {
                url: "/solutions/research-analytics/",
                label: "Research & Analytics",
                children: [
                  {
                    url: "/solutions/consumer-research/",
                    label: "Consumer Research"
                  },
                  {
                    url: "/solutions/behavioral-analytics/",
                    label: "Custom Insights"
                  }
                ]
              }
            ]
          },
          {
            url: "/solutions/loyalty-marketing/",
            label: "Loyalty Marketing",
            children: [
              {
                url: "/solutions/best-customer-communication/",
                label: "Best Customer Communication"
              }
            ]
          },
          {
            url: "/solutions/retail-media/",
            label: "Media",
            children: [
              {
                url: "https://www.krogerprecisionmarketing.com/",
                label: "KPM"
              }
            ]
          }
        ]
      },
      {
        heading: "Industries",
        links: [
          {
            url: "/industries/consumer-packaged-goods/",
            label: "Consumer Goods"
          },
          {
            url: "/industries/health/",
            label: "Health"
          },
          {
            url: "/industries/food-associations-food-marketers-and-product-licensors/",
            label: "Trade Associations"
          },
          {
            url: "/industries/agencies",
            label: "Ad Agencies"
          }
        ]
      },
      {
        heading: "Knowledge Hub",
        links: [
          {
            url: "#",
            label: "Data Science",
            children: [
              {
                url: "#",
                label: "Category 1"
              },
              {
                url: "#",
                label: "Category 2"
              }
            ]
          },
          {
            url: "/knowledge-hub/the-uplowd-by-8451-podcast/",
            label: "Uplow'd Podcast"
          },
          {
            url: "#",
            label: "84.51 on Medium"
          }
        ]
      },
      {
        heading: "Careers",
        links: [
          {
            url: "/careers-consulting",
            label: "Consulting"
          },
          {
            url: "/careers/data-science/",
            label: "Data Science"
          },
          {
            url: "/careers/consumer-research",
            label: "Consumer Research"
          },
          {
            url: "/careers/engineering/",
            label: "Engineering"
          },
          {
            url: "/careers/infrastructure-and-cloud-technology/",
            label: "Infrastructure & Cloud Technology"
          },
          {
            url: "/careers/product-and-design/",
            label: "Product & Design"
          },
          {
            url: "/careers/science/",
            label: "Science"
          },
          {
            url: "/careers/early-career-paths/",
            label: "Early Career Paths"
          },
          {
            url: "/careers/",
            label: "Open Positions"
          }
        ]
      },
      {
        heading: "About",
        links: [
          {
            url: "/who-we-are",
            label: "Who We Are"
          },
          {
            url: "/diversity-and-inclusion",
            label: "Diversity Inclusion"
          },
          {
            url: "/awards-and-recognition",
            label: "Awards & Recognition"
          },
          {
            url: "/press",
            label: "Press"
          },
          {
            url: "https://www.pearlrock.com/",
            label: "PearlRock Partners"
          }
        ]
      }
    ]
  }
}
