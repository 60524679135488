// extracted by mini-css-extract-plugin
export var focalHighlight = "focalHighlight-8Xk";
export var focalHighlight_default = "focalHighlight_default-91R";
export var focalHighlight_default_svg = "focalHighlight_default_svg-GTb";
export var focalHighlight_fullWidth_left = "focalHighlight_fullWidth_left-7ex";
export var focalHighlight_fullWidth_right = "focalHighlight_fullWidth_right-z0S";
export var focalHighlight_gray_square = "focalHighlight_gray_square-sCo";
export var focalHighlight_gray_square_half = "focalHighlight_gray_square_half-Kqe";
export var focalHighlight_image = "focalHighlight_image-1MO";
export var focalHighlight_image_focus = "focalHighlight_image_focus-DHL";
export var focalHighlight_image_full_width = "focalHighlight_image_full_width-HPf";
export var focalHighlight_isHeading = "focalHighlight_isHeading-mlZ";
export var focalHighlight_linesImage = "focalHighlight_linesImage-0pb";
export var focalHighlight_list = "focalHighlight_list-VRA";