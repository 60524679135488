import React from "react"

import Component from "./component"
import fixtures from "./fixtures"

const Footer = () => {
  return process.env.NODE_ENV === "test" ? <Component {...fixtures.default} /> : <Component />
}

export default Footer

export { Component as component, fixtures }
