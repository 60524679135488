export default {
  props: {
    references: [
      {
        title: "How Brands Can Connect with the Hybrid Shopper",
        body: {
          childMarkdownRemark: {
            excerpt: `Somewhere between these two camps exists a shopper who switches between in-store and online shopping options. These unpredictable shifts in modalities pose a unique challenge to the CPGs seeking to connect with these “hybrid shoppers.”`
          }
        },
        image: {
          gatsbyImageData: {
            layout: "constrained",
            backgroundColor: "#281818",
            images: {
              fallback: {
                src: "/storybook/568ad0979317f5abb6bc6de164b990f7/984e9/atoms.png",
                srcSet:
                  "/storybook/568ad0979317f5abb6bc6de164b990f7/645a7/atoms.png 250w,\n/storybook/568ad0979317f5abb6bc6de164b990f7/e5d44/atoms.png 500w,\n/storybook/568ad0979317f5abb6bc6de164b990f7/984e9/atoms.png 1000w,\n/storybook/568ad0979317f5abb6bc6de164b990f7/32793/atoms.png 2000w",
                sizes: "(min-width: 1000px) 1000px, 100vw"
              },
              sources: [
                {
                  srcSet:
                    "/storybook/568ad0979317f5abb6bc6de164b990f7/8fce2/atoms.webp 250w,\n/storybook/568ad0979317f5abb6bc6de164b990f7/921bf/atoms.webp 500w,\n/storybook/568ad0979317f5abb6bc6de164b990f7/993a1/atoms.webp 1000w,\n/storybook/568ad0979317f5abb6bc6de164b990f7/d2417/atoms.webp 2000w",
                  type: "image/webp",
                  sizes: "(min-width: 1000px) 1000px, 100vw"
                }
              ]
            },
            width: 1000,
            height: 665
          }
        },
        category: {
          title: "Data science",
          slug: "data-science",
          type: "knowledge-hub"
        },
        slug: "/something",
        post_type: "article"
      }
    ]
  }
}
