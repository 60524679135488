import React from "react"
import PropTypes from "prop-types"

import { link_list } from "./styles.module.scss"

import Animate from "@components/animate"
import Button from "@components/button"

const LinkList = ({ heading, links }) => {
  return (
    <div className={link_list}>
      {heading && (
        <Animate animate="fade_in" tag="h4">
          {heading}
        </Animate>
      )}
      <ul>
        {links.map((item, index) => (
          <Animate animate="fade_in_down" key={index} delay={index * 300} tag="li">
            <Button theme="arrow" {...item} />
          </Animate>
        ))}
      </ul>
    </div>
  )
}

LinkList.propTypes = {
  /**
   * Heading is the main heading of the link list component
   */
  heading: PropTypes.string,
  /**
   * A list of links / list items to be displayed
   */
  links: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired
    })
  )
}

export default LinkList
