import { useState, useEffect } from "react"
import { chunk } from "@root/src/utils"

const useFilterSort = (posts, per, handlePage, setCount, isCategory = false) => {
  const [activePosts, setActive] = useState(posts) // pre-sorted posts
  const [filtered, setFiltered] = useState(chunk(posts, per)) // sorted posts, updates UI
  const [sort, setSort] = useState("recent")

  const handleFilter = (active) => {
    const activePosts = !active.length
      ? posts
      : posts.filter((post) => {
          if (isCategory) {
            return active.includes(post.resource_type.title)
          } else {
            return active.includes(post.category.title)
          }
        })

    setActive(activePosts)
  }

  const handleSort = (val) => setSort(val[0])

  useEffect(() => {
    if (!activePosts.length) return
    let sorted = activePosts

    if (sort === "alpha") {
      sorted = activePosts.sort((a, b) => (a.title > b.title ? 1 : b.title > a.title ? -1 : 0))
    }

    if (sort === "recent") {
      sorted = activePosts.sort((a, b) =>
        Date.parse(b.publishDate) > Date.parse(a.publishDate)
          ? 1
          : Date.parse(a.publishDate) > Date.parse(b.publishDate)
          ? -1
          : 0
      )
    }

    setFiltered(chunk(sorted, per))
    handlePage(1)
    setCount(sorted.length)
  }, [activePosts, sort])

  return [filtered, handleFilter, handleSort]
}

export default useFilterSort
