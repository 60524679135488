import React from "react"
import PropTypes from "prop-types"
import ReactSelect from "react-select"

import { wrapper } from "./styles.module.scss"

// -------------------------------------------------------- | component

const Select = ({ hasDefault = false, handleSelect, isMulti = false, options, placeholder }) => {
  const handleChange = (selectedOptions) => {
    const updatedVal = isMulti
      ? Array.from(selectedOptions, (i) => i.value)
      : [selectedOptions.value]
    handleSelect && handleSelect(updatedVal)
  }

  return (
    <ReactSelect
      aria-label={placeholder}
      backspaceRemovesValue
      captureMenuScroll
      className={wrapper}
      classNamePrefix="react-select"
      closeMenuOnSelect={!isMulti}
      defaultValue={hasDefault && options[0]}
      isMulti={isMulti}
      isSearchable={false}
      hideSelectedOptions={false}
      onChange={handleChange}
      options={options}
      onClick={(e) => e.stopPropagation()}
      // menuIsOpen={true}
      placeholder={placeholder}
    />
  )
}

Select.propTypes = {
  /**
   * If `true`, assigns first option as the default selection. False by default.
   */
  hasDefault: PropTypes.bool,
  /**
   * Passes selected options to parent
   */
  handleSelect: PropTypes.func,
  /**
   * Enables multi-select. False by default.
   */
  isMulti: PropTypes.bool,
  /**
   * Array of objects used as select options
   */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  ).isRequired,
  /**
   * Label displayed when no items are selected. Also used for aria label.
   */
  placeholder: PropTypes.string
}

export default Select
