import React, { useState } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import OutsideClickHandler from "react-outside-click-handler"
import { useMediaQuery } from "react-responsive"

import SVG from "@components/svg"
import Animate from "@components/animate"
import { Dropdown } from "@src/components/dropdown"
import Link from "@src/components/link"

import { link_list, link_list_dropdown } from "./styles.module.scss"

const LinkList = ({ activeClassName, className, currentIndex, links = [], heading }) => {
  const classes = classNames(link_list, { [className]: className })
  const [openDropdown, setOpenDropdown] = useState("")

  const handleQueryChange = (match) => {
    if (!match) {
      setOpenDropdown("")
    }
  }

  const trackClick = (e) => {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: "Nav Click",
        category: "Footer Navigation",
        label: `${e.target.innerText} | ${e.target.getAttribute("href")}`
      })
    }
  }

  const isMobile = useMediaQuery({ query: "(min-width: 30em)" }, undefined, handleQueryChange)

  const handleDropdownClick = (label) => {
    label === openDropdown ? setOpenDropdown("") : setOpenDropdown(label)
  }

  const resetDropdownOnOutsideClick = () => {
    setOpenDropdown("")
  }

  const buildDesktopLinkList = (item, index) => {
    const parent = (
      <Link
        activeClassName={activeClassName}
        className={item.className}
        title={item.title}
        to={item.url}
        onClick={trackClick}
      >
        {item.label}
      </Link>
    )

    let children = null

    if (item.children) {
      children = (
        <ul>
          {item.children.map((child, childIndex) => {
            const childParent = (
              <Link
                activeClassName={activeClassName}
                className={child.className}
                title={child.title}
                to={child.url}
                onClick={trackClick}
              >
                {child.label}
              </Link>
            )

            let childChildren = null

            if (child.children) {
              childChildren = (
                <ul>
                  {child.children.map((subchild, subchildIndex) => {
                    return (
                      <li key={subchildIndex}>
                        <Link
                          activeClassName={activeClassName}
                          className={subchild.className}
                          title={subchild.title}
                          to={subchild.url}
                          onClick={trackClick}
                        >
                          {subchild.label}
                        </Link>
                      </li>
                    )
                  })}
                </ul>
              )
            }

            return (
              <li key={childIndex}>
                {childParent}
                {childChildren}
              </li>
            )
          })}
        </ul>
      )

      return (
        <li key={index}>
          {parent}
          {children}
        </li>
      )
    }

    return <li key={index}>{parent}</li>
  }

  return (
    <OutsideClickHandler onOutsideClick={() => resetDropdownOnOutsideClick() || !isMobile}>
      <Animate className={classes} animate="fade_in_up" delay={currentIndex * 300} tag="ul">
        {heading && (
          <li>
            <strong>{heading}</strong>
          </li>
        )}

        {links.map((item, index) => {
          if (item.children && item.children.length > 0 && !isMobile) {
            const dropdownClasses = classNames(item.className, link_list_dropdown)
            return (
              <li key={index}>
                <Dropdown
                  activeClassName={activeClassName}
                  className={dropdownClasses}
                  handleDropdownClick={handleDropdownClick}
                  items={item.children}
                  key={index}
                  label={item.label}
                  openDropdown={openDropdown}
                  url={item.url}
                />
              </li>
            )
          } else if (item.children && item.children.length > 0) {
            return buildDesktopLinkList(item, index)
          } else {
            return (
              <li key={index}>
                <Link
                  activeClassName={activeClassName}
                  className={classNames([], item.className)}
                  title={item.title}
                  to={item.url}
                  onClick={trackClick}
                  dangerouslySetInnerHTML={{ __html: item.label }}
                >
                  {item.label}
                  {item.icon && (
                    <div style={{ width: "50px", marginLeft: "5px", display: "inline-block" }}>
                      <SVG name={item.icon} />
                    </div>
                  )}
                </Link>
              </li>
            )
          }
        })}
      </Animate>
    </OutsideClickHandler>
  )
}

LinkList.propTypes = {
  /**
   * Specifies an active class name, passed on to the <Link /> component
   */
  activeClassName: PropTypes.string,
  /**
   * CSS class(es) applied to the wrapping element
   */
  className: PropTypes.string,
  /**
   * Get the current index.
   */
  currentIndex: PropTypes.number,
  /**
   * Heading rendered before the items, typically only used in vertical lists
   */
  heading: PropTypes.string,
  /**
   * An array of link objects used to build the list
   */
  links: PropTypes.arrayOf(
    PropTypes.shape({
      button: PropTypes.bool,
      className: PropTypes.string,
      icon: PropTypes.string,
      label: PropTypes.string.isRequired,
      url: PropTypes.string,
      heading: PropTypes.bool,
      children: PropTypes.arrayOf(
        PropTypes.shape({
          className: PropTypes.string,
          label: PropTypes.string.isRequired,
          url: PropTypes.string.isRequired,
          children: PropTypes.arrayOf(
            PropTypes.shape({
              className: PropTypes.string,
              label: PropTypes.string.isRequired,
              url: PropTypes.string.isRequired
            })
          )
        })
      )
    })
  ).isRequired
}

LinkList.defaultProps = {}

export default LinkList
