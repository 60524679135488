import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import Animate from "@components/animate"

// --------------------------------------------------------

import { content, has_columns, margin_bottom_36, split_header } from "./styles.module.scss"

const marginBottomOptions = {
  0: null,
  36: margin_bottom_36
}

// --------------------------------------------------------

const Content = ({ body, className, columns = false, margin_bottom }) => (
  <Animate
    className={classNames(content, "content", {
      [className]: className,
      [marginBottomOptions[margin_bottom]]: marginBottomOptions[margin_bottom],
      [split_header]: className === "split_header",
      [has_columns]: columns
    })}
    animate="fade_in"
  >
    <div dangerouslySetInnerHTML={{ __html: body ? body.childMarkdownRemark.html : "" }} />
  </Animate>
)

Content.propTypes = {
  /**
   * HTML string to be rendered to the page.
   */
  body: PropTypes.object,
  /**
   * Additional CSS class to the content element.
   */
  className: PropTypes.string,
  /**
   * Splits content into 2 columns on desktop
   */
  columns: PropTypes.bool,
  /**
   * Specifies the number pixels to push subsequent content down the page
   */
  margin_bottom: PropTypes.oneOf([0, 36, "0", "36"])
}

Content.defaultProps = {}

export default Content
