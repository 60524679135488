/* eslint-disable react/prop-types */
import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { parseContentfulJSON } from "@src/utils"

import Animate from "@components/animate"
import Link from "@components/link"
import SVG from "@components/svg"

import { breadcrumbs, crumb } from "./styles.module.scss"

const Breadcrumbs = (props) => {
  const cfg = parseContentfulJSON(props.config)
  const { className, links } = props.__typename === "ContentfulSnippet" ? cfg : props
  return (
    <Animate
      animate="fade_in"
      className={classNames("breadcrumbs", breadcrumbs, { [className]: className })}
      tag="ul"
    >
      {links.map(({ label, icon, url }, index) => {
        if (url === null) return null

        if (!label) return

        if (url) {
          return (
            <li key={index} data-separator="•">
              <Link className={crumb} to={url}>
                <>
                  {icon && <SVG name={icon} />}
                  {label && label}
                </>
              </Link>
            </li>
          )
        } else {
          return (
            <li key={index} data-separator="•">
              <>
                {icon && <SVG name={icon} />}
                {label && label}
              </>
            </li>
          )
        }
      })}
    </Animate>
  )
}

Breadcrumbs.propTypes = {
  /**
   * Additional classes on the wrapping element.
   */
  className: PropTypes.string,
  /**
   * An array of links used to build the breadcrumbs
   * If a label and icon exist, the icon will be added to the left of the label.
   */
  links: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      icon: PropTypes.string,
      url: PropTypes.string
    })
  )
}

Breadcrumbs.defaultProps = {}

export default Breadcrumbs
