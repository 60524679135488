export default {
  default: {
    heading: "Quisque",
    links: [
      { url: "#", label: "Vestibulum vitae" },
      { url: "#", label: "Pellentesque tempor venenatis" },
      { url: "#", label: "Fusce molestie" },
      { url: "#", label: "Etiam in lacinia dolor" }
    ]
  }
}
