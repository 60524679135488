const path = require("path")
const cookies = require("js-cookie")

/**
 * Checks if a value is a valid JSON string.
 * @param {string} value The value to check.
 * @returns {boolean} True if the value is a valid JSON string; otherwise, false.
 */
const isValidJSONString = (value) => {
  try {
    JSON.parse(value)
    return true
  } catch (error) {
    return false
  }
}

/**
 * Parses a Contentful JSON object and returns its content as a parsed JavaScript object.
 * If the provided object contains a valid "internal" property with content, it will be parsed and returned.
 * If the object is invalid or does not contain the necessary properties, an empty object will be returned.
 * @param {Object} obj The Contentful JSON object to parse.
 * @returns {Object} The parsed JavaScript object containing the content of the provided Contentful JSON object, or an empty object if parsing fails.
 */
const parseContentfulJSON = (obj) => {
  let cfg = {}
  if (obj && Object.keys(obj).includes("internal") && obj.internal) {
    let {
      internal: { content }
    } = obj
    try {
      cfg = JSON.parse(content)
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }
  return cfg
}

/**
 * Generates a category path based on the provided category object.
 * The generated path includes a leading slash ("/") and the category's slug if available.
 * @param {Object} category The category object.
 * @param {string} category.slug The slug of the category (optional).
 * @returns {string|undefined} The generated category path, or undefined if the category is not provided.
 */
const categoryPath = (category) => {
  if (!category) return
  let parts = ["/"]
  if (category.slug) parts.push(category.slug)
  return path.join(...parts)
}

/**
 * Formats a path based on the provided node and category objects.
 * If the node has an external URL, it will be returned as is.
 * Otherwise, the path will be generated based on the node's slug and the category's information, if available.
 * @param {Object} node The node object.
 * @param {Object} [category] The category object (optional).
 * @param {string} [category.slug] The slug of the category (optional).
 * @returns {string|undefined} The formatted path, or undefined if the node is not provided.
 */
const formatPath = (node, category) => {
  if (!node) return
  if (!category) category = node.category
  if (node?.external_url) return node.external_url

  let parts = ["/"]
  if (category) {
    parts = [categoryPath(category)]
  }
  if (node.slug !== "index") parts.push(node.slug)
  return path.join(...parts)
}

/**
 * Converts a string into a human-readable format by replacing non-alphanumeric characters with spaces.
 * @param {string} str The string to be humanized.
 * @returns {string} The humanized string.
 */
const humanize = (str) => {
  return str.replace(/[^a-zA-Z0-9]/g, " ")
}

/**
 * Splits an array into smaller arrays of a specified size.
 * @param {Array} arr The array to be chunked.
 * @param {number} size The size of each chunk.
 * @returns {Array} An array containing the smaller chunked arrays.
 */
const chunk = (arr, size) => {
  return Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
    arr.slice(i * size, i * size + size)
  )
}

/**
 * Executes an asynchronous callback function for each element in an array.
 * @param {Array} array The array to iterate over.
 * @param {Function} callback The callback function to be executed for each element.
 * @returns {Promise<void>} A Promise that resolves when all iterations are complete.
 */
const asyncForEach = async (array, callback) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array)
  }
}

/**
 * Retrieves the user's location asynchronously.
 * If the user's location is stored in cookies, it will be returned.
 * Otherwise, it sends a request to an API endpoint to fetch the user's location,
 * stores it in cookies, and then returns it.
 * @returns {Promise<string>} A Promise that resolves with the user's location.
 */
const getUserLocation = async () => {
  try {
    const key = "userLocale"
    let locale = cookies.get(key)
    if (locale === undefined) {
      const response = await fetch("/.netlify/functions/user-location", {
        method: "POST"
      })
      locale = await response.text()
      cookies.set(key, locale, { expires: 1 })
    }
    return locale
    // eslint-disable-next-line no-empty
  } catch {}
}

/**
 *
 * @param {string} url
 * @returns {string} url: converted url or path, without the domain if internal
 * @returns {bool} isExternal: whether the url links to outside amperity.com
 */
const getUrlAndType = (url) => {
  if (!url) return { url: undefined, isExternal: false }

  // internal url
  if (url.match(/https?:\/\/(www\.)?8451\.com/)) {
    const path = new URL(url).pathname
    return { url: path, isExternal: false }
    // http(s) url that's not internal
  } else if (url.match(/^http/)) {
    return { url: url, isExternal: true }
    // any other url considered internal and feeds back input url
  } else {
    return { url: url, isExternal: false }
  }
}

/**
 * Determines if today's date is greater than a specified date.
 * The comparison respects the user's timezone.
 * @param {String} oldDate The date to compare against today's date.
 * @returns {boolean} True if today's date is greater than the specified date; otherwise, false.
 */
const isTodayAfterDate = (oldDate) => {
  const now = new Date()
  const then = new Date(oldDate)

  // Convert both dates to UTC to compare them fairly
  const nowUTC = new Date(now.toUTCString())
  const thenUTC = new Date(then.toUTCString())

  return nowUTC > thenUTC
}

const toKebabCase = (string = "") => {
  // first removes all dashes,
  // then replaces all whitespace with a single space,
  // then replaces all whitespace and underscores with a dash,
  // then converts to lowercase

  return string
    .replace(/[^a-zA-Z]/g, "-")
    .replace(/\s+/g, " ")
    .replace(/[\s/]+/g, "-")
    .replace(/[-]+/g, "-")
    .toLowerCase()
}

module.exports = {
  chunk,
  humanize,
  formatPath,
  categoryPath,
  getUrlAndType,
  getUserLocation,
  parseContentfulJSON,
  asyncForEach,
  isTodayAfterDate,
  isValidJSONString,
  toKebabCase
}
