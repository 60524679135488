export default {
  props: { name: "ad-agencies" },
  withHeading: {
    name: "coupon-engagement",
    heading: "Coupon Engagement"
  },
  withHeadingAndDescription: {
    name: "data-computer",
    heading: "Dynamically learn & adjust",
    description:
      "Find an insight that drives further questions? Simply change a report parameter to get to the next helpful insight. "
  }
}
