import React from "react"
import PropTypes from "prop-types"

import Animate from "@components/animate"
import Button from "@components/button"
import SVG from "@components/svg"

import {
  component as SubscribeForm,
  fixtures as SubscribeFormFixtures
} from "@components/snippets/subscribe-form"

const { parseContentfulJSON } = require("@src/utils")

import { spark_callout, spark_callout_content, spark_image } from "./styles.module.scss"

const LetConnect = ({ config }) => {
  const cfg = parseContentfulJSON(config)

  return (
    <Animate className={spark_callout}>
      <div className={spark_image}>
        <SVG name="expression-gray" />
      </div>
      <div className={spark_callout_content}>
        {cfg.subscribe ? (
          <SubscribeForm {...SubscribeFormFixtures.props} />
        ) : (
          <>
            {cfg.body && <div dangerouslySetInnerHTML={{ __html: cfg.body }} />}
            {cfg.cta && <Button url={cfg.cta.url}>{cfg.cta.label}</Button>}
          </>
        )}
      </div>
    </Animate>
  )
}

LetConnect.propTypes = {
  config: PropTypes.object
}

export default LetConnect
