export default {
  internal: {
    to: "/components/link",
    children: "I'm an internal link"
  },
  external: {
    to: "https://ample.co",
    children: "And I'm external"
  }
}
