import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import classNames from "classnames"
import ReactFitText from "react-fittext"

import Author from "@src/components/author"
import AuthorMiniBio from "@components/author-mini-bio"
import Block from "@components/block"
import Breadcrumbs from "@src/components/breadcrumbs"
import Container from "@src/components/container"
import Content from "@components/content/component"
import RichContentObject from "@components/rich-content-object"
import Grid from "@components/grid"
import LatestPosts from "@src/components/latest-posts"
import Layout from "@src/layout"
import PostMeta from "../post-meta"
import TimeToRead from "@components/time-to-read"

import Alert from "@components/alert/component"

const { categoryPath } = require("@src/utils")
const pluralize = require("pluralize")
const dig = require("object-dig")

import {
  author_container,
  breadcrumbs_container,
  resource_container,
  content,
  triangle_overlay,
  press_release_overlay,
  content_with_alert,
  grid_container,
  has_authors,
  header_section,
  post_body,
  post_header,
  post_header_with_alert,
  post_type,
  news_icon,
  post_icon,
  post_time,
  content_wrapper,
  social_container,
  sidebar_wrapper,
  sticky_alert,
  post_heading_container,
  top_meta_container
} from "./styles.module.scss"

const Post = (props) => {
  const { post, contact } = props.data
  const latestPosts_Config = {
    type: "knowledge-hub",
    title: "Visit our knowledge hub",
    theme: "gray_background",
    layout: "with_media_cards",
    padding: "medium",
    subtitle: "See what you can learn from our latest posts.",
    cta: {
      url: "/knowledge-hub",
      label: "View All Posts"
    }
  }

  let postSlug = post.resource_type.slug.split("/")
  let postType = postSlug[0] === "" ? postSlug[1] : postSlug[0]
  const stickyAlert = post?.alert?.theme === "post-sticky" ? post?.alert : null
  const layoutAlert = post?.alert?.theme !== "post-sticky" ? post?.alert : null

  if (postType && postType !== "News") {
    postType = pluralize.singular(postType)
  }

  const breadcrumbs_config = {
    links: [
      {
        label: postType
      },
      {
        label: post.category.title,
        url: categoryPath(post.category)
      }
    ]
  }

  const { wordCount } = post

  let meta = post.meta_data || []

  if (!meta.some((t) => t["property"] === "og:image") && post.image) {
    meta.push({
      name: "og:image",
      property: "og:image",
      content: dig(post, "image", "file", "url")
    })
  }

  const renderAuthor = () => {
    if (post?.authors?.length)
      return (
        <div className={author_container}>
          {post.authors.map((author, idx) => (
            <Author
              {...author}
              publishDate={post.showPublishDate ? post.publishDate : null}
              by={post.by}
              key={`author-${idx}`}
            />
          ))}
        </div>
      )

    if (!post?.authors?.length && post.showPublishDate)
      return <div className={author_container}>{post.publishDate}</div>
  }
  return (
    <Layout
      title={post.title}
      meta={meta}
      locationPath={post.post_path}
      canonicalHost={post.canonicalHost}
      alert={layoutAlert}
      smallHeader={true}
      headerShadow={false}
    >
      <div
        className={`${triangle_overlay} ${
          post.category.slug === "press-releases" && press_release_overlay
        }`}
      >
        <div className={header_section}>
          <div className={breadcrumbs_container}>
            <Breadcrumbs
              config={{ internal: { content: JSON.stringify(breadcrumbs_config) } }}
              __typename={"ContentfulSnippet"}
            ></Breadcrumbs>
          </div>

          <div className={resource_container}>
            {post.categoryIcon && (
              <div className={post_icon}>
                <img
                  src={post.categoryIcon.file.url}
                  alt={post.categoryIcon.title ?? "Category icon"}
                />
              </div>
            )}
            {!post.categoryIcon && post.category.slug === "press-releases" && (
              <div className={post_icon}>
                <img
                  src="https://images.ctfassets.net/c23k8ps4z1xm/5sqnQ02TSbNYRQEX6NrVpS/7629848482221d34fa9606482d2c8f28/8451_icons_120x120_rev_01_general-04.svg"
                  alt="Press Release Icon"
                />
              </div>
            )}
            {!post.categoryIcon && post.category.slug !== "press-releases" && (
              <div className={news_icon}>
                <img
                  src="https://images.ctfassets.net/c23k8ps4z1xm/63ejIAmHjsYxJGNlrnlLpA/2ab70899832d5e66d5e13381f7748e35/news-icon.svg"
                  alt="News Icon"
                />
              </div>
            )}
            <h1>{post.resource_type?.title}</h1>
          </div>
        </div>
      </div>
      <Container className={grid_container} padding="none">
        <Grid
          layout="full_width"
          className={classNames(post_header, {
            [post_header_with_alert]: stickyAlert
          })}
        >
          {/* this is the markup to replace the header above at some point. */}

          <div className={post_heading_container}>
            <div className={top_meta_container}>
              {(wordCount || post.duration) && (
                <TimeToRead
                  wordCount={wordCount}
                  duration={post.duration}
                  className={post_time}
                  type={postType}
                />
              )}
              <div className={post_type}>{post.resource_type?.title}</div>
            </div>

            <ReactFitText maxFontSize={66}>
              <h1>{post.title}</h1>
            </ReactFitText>
            {renderAuthor()}
          </div>
        </Grid>
      </Container>

      <div className={content_wrapper}>
        <div
          className={classNames({
            [content]: !stickyAlert,
            [content_with_alert]: stickyAlert
          })}
        >
          {post.rich_body && <RichContentObject content={{ ...post.rich_body }} />}
          {!post.rich_body && <Content className={post_body} body={post.body} />}
          {post?.authors?.length &&
            post.authors.map((author, idx) => (
              <AuthorMiniBio {...author} key={`author-bio-${idx}`} />
            ))}
        </div>

        <div
          className={classNames(sidebar_wrapper, {
            [has_authors]: post?.authors?.length
          })}
        >
          {renderAuthor()}
          <PostMeta
            className={social_container}
            inlineTitle={"Share"}
            postBody={post.rich_body}
            showTableOfContents={post.showTableOfContents || false}
          />
          {stickyAlert && (
            <Alert {...stickyAlert} className={sticky_alert} theme="post-sticky-modal-alert" />
          )}
        </div>
      </div>

      <LatestPosts
        config={{ internal: { content: JSON.stringify(latestPosts_Config) } }}
        references={[post.category]}
      ></LatestPosts>

      <Block {...contact} />
    </Layout>
  )
}

Post.propTypes = {
  /**
   * Components to inject before the page content.
   */
  children: PropTypes.node,
  /**
   * An array of components that get mapped to the <Block /> component.
   */
  blocks: PropTypes.arrayOf(PropTypes.object)
}

Post.defaultProps = {
  containers: []
}

export const query = graphql`
  query PostQuery($id: String!) {
    post: contentfulPost(id: { eq: $id }) {
      ...PostAttributes
      authors {
        __typename
        ... on ContentfulAuthor {
          ...Author
        }
      }
    }
    contact: contentfulContainer(contentful_id: { eq: "4RcDk7ag4JUwJUyD8Vjd7G" }) {
      ...Container
      blocks {
        __typename
        ...Block
        ... on ContentfulRow {
          ...Row
          blocks {
            __typename
            ... on ContentfulSnippet {
              __typename
              ...Snippet
            }
            ... on ContentfulForm {
              __typename
              ...Form
            }
          }
        }
      }
    }
  }
`

export default Post
