import React from "react"
import Hls from "hls.js"
import PropTypes from "prop-types"
import { useEffect, useRef } from "react"

export default function HlsVideoPlayer(props) {
  const videoRef = useRef(null)

  useEffect(() => {
    if (Hls.isSupported()) {
      const hls = new Hls()
      hls.loadSource(props.source)
      hls.attachMedia(videoRef.current)

      return () => {
        if (hls) {
          hls.destroy()
        }
      }
    } else if (videoRef.current.canPlayType("application/vnd.apple.mpegurl")) {
      videoRef.current.src = props.source
    }
  }, [props.source])

  return (
    <span>
      <video ref={videoRef} autoPlay muted></video>
    </span>
  )
}

HlsVideoPlayer.propTypes = {
  source: PropTypes.string
}

HlsVideoPlayer.defaultProps = {}
