export default {
  default: {
    activeClassName: "active",
    activeStyle: {},
    children: "Hello World",
    className: "",
    to: "/",
    onClick: null
  }
}
