import React, { cloneElement, Fragment } from "react"
import PropTypes from "prop-types"
import { useMediaQuery } from "react-responsive"

import Animate from "@components/animate"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

// -------------------------------------------------------- | styles

import { logo_train, logos_container } from "./styles.module.scss"

// -------------------------------------------------------- | component

const LogoTrain = ({ references: logos }) => {
  const medium = useMediaQuery({ query: "(min-width: 65em)" })

  const times = 6
  const margin = 25
  let width = 130

  if (medium) {
    width = 176
  }

  const getLogos = () => (
    <div
      className={logos_container}
      style={{
        animationDuration: `${logos.length * (times / 2)}s`,
        width: `${logos.length * (width + margin)}px`
      }}
    >
      {logos.map((logo, index) => (
        <div
          key={index}
          style={{
            width: `${width}px`,
            margin: `auto ${margin}px`
          }}
        >
          <GatsbyImage image={getImage(logo.image_logo_train)} alt={logo.image_logo_train?.title} />
        </div>
      ))}
    </div>
  )

  return (
    <Animate animate="fade_in" delay={800}>
      <div className={logo_train}>
        {[...Array(times)].map((_, index) => (
          <Fragment key={index}>{cloneElement(getLogos())}</Fragment>
        ))}
      </div>
    </Animate>
  )
}

LogoTrain.propTypes = {
  /**
   * An array of logos
   */
  references: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string
    })
  )
}

export default LogoTrain
