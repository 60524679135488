import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import "intersection-observer"
import handleViewport from "react-in-viewport"
import IframeResizer from "iframe-resizer-react"
import cx from "classnames"
import SVG from "@components/svg"

// ------------------------------------------ #

import {
  container,
  container_small,
  container_medium,
  container_large,
  container_medium_pie,
  dataviz,
  iframe
} from "./styles.module.scss"

// ------------------------------------------ #

export default function DataViz(props) {
  const { size, chartType } = props
  // eslint-disable-next-line react/prop-types
  const Chart = ({ inViewport, forwardedRef }) => {
    const [displayed, setDisplayed] = useState(false)

    useEffect(() => {
      if (!displayed) {
        setDisplayed(inViewport)
      }
    }, [inViewport])

    return (
      <div className={cx(dataviz)}>
        <div
          className={cx("viewport-block", container, {
            [container_small]: size === "Small",
            [container_medium]: size === "Medium",
            [container_large]: size === "Large"
          })}
          ref={forwardedRef}
        >
          {displayed && (
            <IframeResizer
              title="data-viz"
              src={`/dataviz/?id=${props.id}`}
              // log={process.env.NODE_ENV === "development"}
              className={cx(iframe, {
                [container_medium_pie]: chartType === "Pie" && size === "Medium"
              })}
            />
          )}
        </div>
        <footer>
          <div>{props.caption && <p>{props.caption}</p>}</div>
          <SVG name="logo" />
        </footer>
      </div>
    )
  }

  const ViewportBlock = handleViewport(Chart)
  return <ViewportBlock />
}

DataViz.propTypes = {
  options: PropTypes.object,
  caption: PropTypes.string,
  size: PropTypes.oneOf(["Small", "Medium", "Large"]),
  id: PropTypes.string,
  chartType: PropTypes.string
}

DataViz.defaultProps = {}
