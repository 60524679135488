/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { useMediaQuery } from "react-responsive"

import Animate from "@components/animate"
import { GatsbyImage } from "gatsby-plugin-image"
import Link from "@components/link"

// -------------------------------------------------------- | styles

import {
  media_object_content,
  media_object_image,
  media_object,
  theme_dark,
  theme_light,
  layout_update,
  trigger_opacity_change
} from "./styles.module.scss"

const themeOptions = {
  dark: theme_dark,
  light: theme_light,
  layout_update: layout_update
}

// -------------------------------------------------------- | component

const MediaObject = ({ category, currentIndex, label, heading, image, theme, url }) => {
  const mediumScreen = useMediaQuery({ query: "(min-width: 65em)" })
  const [changeOpacity, setChangeOpacity] = useState(false)

  const classes = classNames(media_object, {
    [layout_update]: layout_update,
    [themeOptions[theme]]: themeOptions[theme],
    [trigger_opacity_change]: changeOpacity
  })

  const triggerOpacityChange = () => {
    // set timeout to 11 seconds (length of the video)
    setTimeout(() => {
      setChangeOpacity(true)
    }, 11000)
  }

  useEffect(() => {
    if (mediumScreen) {
      triggerOpacityChange()
    } else {
      setChangeOpacity(false)
    }
  }, [mediumScreen])

  // --------------------------------------------------------

  return (
    <Animate animate="fade_in_down" delay={currentIndex ? currentIndex * 300 : 300}>
      <div className={classes}>
        {image?.gatsbyImageData && (
          <Link to={url}>
            <GatsbyImage
              className={media_object_image}
              image={image.gatsbyImageData}
              alt={image.title}
            />
          </Link>
        )}
        <div className={media_object_content}>
          <Link to={url}>{heading && <strong>{heading}</strong>}</Link>
          {label && <span>{label}</span>}
          {category && (
            <Link to={category.url}>
              <span>{category.label}</span>
            </Link>
          )}
        </div>
      </div>
    </Animate>
  )
}

MediaObject.propTypes = {
  /**
   * Specifies the category that the content belongs to in the Knowledge Hub
   */
  category: PropTypes.object,
  /**
   * Get the current index.
   */
  currentIndex: PropTypes.number,
  /**
   * Specifies the heading
   */
  heading: PropTypes.string.isRequired,
  /**
   * Specifies the label that the content belongs to in the Knowledge Hub
   */
  label: PropTypes.string,
  /**
   * Specifies the image
   */
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  /**
   * Specifies the theme of the media object
   */
  theme: PropTypes.oneOf(Object.keys(themeOptions)),

  /**
   * Specifies the url for the media object
   */
  url: PropTypes.string
}

MediaObject.defaultProps = {
  theme: "light"
}

export default MediaObject
