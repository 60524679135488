export default {
  props: {
    children: "Call to action",
    to: "#"
  },
  purpleTheme: {},
  outlineTheme: {
    theme: "outline"
  },
  transparentTheme: {
    theme: "transparent"
  },
  arrowTheme: {
    theme: "arrow"
  },
  withIconTheme: {
    theme: "with_icon"
  },
  useButtonElement: {
    type: "button"
  }
}
