// extracted by mini-css-extract-plugin
export var author_container = "author_container-LWh";
export var breadcrumbs_container = "breadcrumbs_container-U5U";
export var content = "content-8lM";
export var content_with_alert = "content_with_alert-3uP";
export var content_wrapper = "content_wrapper-rKO";
export var date_wrapper = "date_wrapper--Y3";
export var facebook = "facebook-Ai+";
export var fadeLink = "fade-link-nBk";
export var grid_container = "grid_container-H54";
export var header_section = "header_section-2aF";
export var inline_title = "inline_title-1qU";
export var link = "link-H9X";
export var link_has_been_copied = "link_has_been_copied--Qo";
export var linkedin = "linkedin-oNz";
export var news_icon = "news_icon-27G";
export var post_body = "post_body-8rg";
export var post_header = "post_header-YGw";
export var post_heading_container = "post_heading_container-FN9";
export var post_icon = "post_icon-re2";
export var post_meta_wrapper = "post_meta_wrapper-zs7";
export var post_time = "post_time-UEq";
export var post_type = "post_type-dMg";
export var press_release_overlay = "press_release_overlay-kyq";
export var resource_container = "resource_container-5D-";
export var sidebar_wrapper = "sidebar_wrapper-yMR";
export var social_container = "social_container-CGi";
export var sticky_alert = "sticky_alert-z0V";
export var top_meta_container = "top_meta_container-rsM";
export var triangle_overlay = "triangle_overlay-QEO";
export var twitter = "twitter-T9E";