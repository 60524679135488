import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import { isGatsbyLink } from "./helpers"

import ExternalLink from "./external-link"
import InternalLink from "./internal-link"

import { link, act_like_span } from "./styles.module.scss"

const Link = (props) => {
  if (!props.to) {
    const classes = classNames(act_like_span, { [props.className]: props.className })
    return <div className={classes} {...props} />
  }

  const classes = classNames(link, {
    [props.className]: props.className
  })

  let linkComponent

  if (isGatsbyLink(props.to)) {
    linkComponent = <InternalLink className={classes} {...props} />
  } else {
    if (props.to?.includes("privacyportal.onetrust.com")) {
      linkComponent = (
        <>
          <button
            id="ot-sdk-btn"
            className="ot-sdk-show-settings do-not-sell"
            style={{ display: "none" }}
          >
            Do Not Sell
          </button>
          <ExternalLink
            className={classes}
            {...props}
            onClick={(e) => {
              document.querySelector("#ot-sdk-btn.ot-sdk-show-settings.do-not-sell").click()
              e.preventDefault()
              return false
            }}
          />
        </>
      )
    } else {
      linkComponent = <ExternalLink className={classes} {...props} />
    }
  }

  return linkComponent
}

Link.propTypes = {
  activeClassName: PropTypes.string,
  activeStyle: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  className: PropTypes.string,
  id: PropTypes.string,
  rel: PropTypes.string,
  target: PropTypes.string,
  to: PropTypes.string,
  onClick: PropTypes.func
}

Link.defaultProps = {
  target: "_blank"
}

export default Link
