// extracted by mini-css-extract-plugin
export var button = "button-K36";
export var button_container = "button_container-PZx";
export var size_large = "size_large--pL";
export var size_medium = "size_medium-eU5";
export var size_small = "size_small-MLE";
export var theme_outline = "theme_outline---Q";
export var theme_purple = "theme_purple-xuv";
export var theme_transparent = "theme_transparent-RSu";
export var theme_with_arrow = "theme_with_arrow-NIC";
export var theme_with_arrow_and_shadow = "theme_with_arrow_and_shadow-CUB";
export var theme_with_arrow_and_shadow_container = "theme_with_arrow_and_shadow_container-1S3";
export var theme_with_icon = "theme_with_icon-N1N";
export var theme_with_icon_container = "theme_with_icon_container-dmH";