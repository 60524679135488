import React, { Component } from "react"
import PropTypes from "prop-types"
import Link from "@components/link"
import classNames from "classnames"

import { paginationWrapper, current } from "./styles.module.scss"

class Pagination extends Component {
  constructor(props) {
    super(props)
    this.state = {
      count: props.totalItems,
      per: props.itemsPerPage < 15 ? props.itemsPerPage : 15,
      page: props.currentPage || 1
    }
    this.goTo = this.goTo.bind(this)
  }

  goTo(event) {
    event.preventDefault()
    const n = Number.parseInt(event.target.innerText)
    this.setState({ page: n }, () => {
      this.props.onChange(this.state.page)
    })
  }

  getPageList(totalPages, page, maxLength) {
    if (maxLength < 5) throw "maxLength must be at least 5"

    function range(start, end) {
      return Array.from(Array(end - start + 1), (_, i) => i + start)
    }

    var sideWidth = maxLength < 9 ? 1 : 2
    var leftWidth = (maxLength - sideWidth * 2 - 3) >> 1
    var rightWidth = (maxLength - sideWidth * 2 - 2) >> 1
    if (totalPages <= maxLength) {
      // no breaks in list
      return range(1, totalPages)
    }
    if (page <= maxLength - sideWidth - 1 - rightWidth) {
      // no break on left of page
      return range(1, maxLength - sideWidth - 1).concat(
        0,
        range(totalPages - sideWidth + 1, totalPages)
      )
    }
    if (page >= totalPages - sideWidth - 1 - rightWidth) {
      // no break on right of page
      return range(1, sideWidth).concat(
        0,
        range(totalPages - sideWidth - 1 - rightWidth - leftWidth, totalPages)
      )
    }
    // Breaks on both sides
    return range(1, sideWidth).concat(
      0,
      range(page - leftWidth, page + rightWidth),
      0,
      range(totalPages - sideWidth + 1, totalPages)
    )
  }

  render() {
    const n = this.state.count > 0 ? Math.ceil(this.state.count / this.state.per) : 0
    const currentPage = Number(this.state.page)
    const pages = this.getPageList(n, currentPage, 7)

    const els = pages.map((page, i) => {
      const classes = classNames({
        [current]: currentPage === page
      })

      return (
        <li key={`page-${i}`} className={classes}>
          {page !== 0 && (
            <Link to={`/search?q=${this.state.q}&page=${page}`} onClick={this.goTo}>
              {page}
            </Link>
          )}
          {page === 0 && <span>...</span>}
        </li>
      )
    })
    return <ul className={paginationWrapper}>{els}</ul>
  }
}

Pagination.propTypes = {
  currentPage: PropTypes.number,
  itemsPerPage: PropTypes.number,
  totalItems: PropTypes.number,
  onChange: PropTypes.func
}

Pagination.defaultProps = {
  onChange: (n) => {
    console.log(`Current page is #${n}`)
  }
}

export default Pagination
