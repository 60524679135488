/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/display-name */
import React from "react"
import PropTypes from "prop-types"

const ExternalLink = React.forwardRef(({ children, ...props }, ref) => (
  <a
    ref={ref}
    rel={props.rel || (props.target === "_blank" ? "noopener" : "")}
    href={props.to}
    target={props.target}
    className={props.className}
    onClick={(e) => {
      if (typeof props.onClick === `function`) {
        props.onClick(e)
      }
      let redirect = true
      if (
        e.button !== 0 ||
        e.altKey ||
        e.ctrlKey ||
        e.metaKey ||
        e.shiftKey ||
        e.defaultPrevented
      ) {
        redirect = false
      }
      if (props.target && props.target.toLowerCase() !== `_self`) {
        redirect = false
      }
      if (window.dataLayer) {
        const isDownload = props.to.includes("/api/assets") || props.to.includes("ctfassets")
        window.dataLayer.push({
          event: "click",
          category: isDownload ? `download` : `outbound`,
          label: props.to,
          eventCallback: function () {
            if (redirect) {
              document.location = props.to
            }
          }
        })
      } else {
        if (redirect) {
          document.location = props.to
        }
      }
      return false
    }}
  >
    {children}
  </a>
))

ExternalLink.propTypes = {
  /**
   * Text or elements to render inside the link.
   */
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  /**
   * The "class" attribute of the anchor.
   */
  className: PropTypes.string,
  /**
   * The "target" attribute of the anchor.
   */
  target: PropTypes.string,
  /**
   * The "rel" attribute of the anchor.
   */
  rel: PropTypes.string,
  /**
   * The URL of the link.
   */
  to: PropTypes.string.isRequired,
  /**
   * Event handler for the onClick event
   */
  onClick: PropTypes.func
}

export default ExternalLink
