import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import Image from "@src/components/image"
import SVG from "@components/svg"
import Link from "@components/link"

import * as styles from "./styles.module.scss"

const Author = (props) => {
  let { by, className, image, name, sme = false, title, slug, publishDate } = props
  // Fallback for articles published before this field existed in Contentful
  if (by === null) {
    by = true
  }

  return (
    <div className={classNames(styles.author, { [className]: className })}>
      {image && <Image src={image} className={styles.profile} />}
      <div>
        {name && (
          <div className={styles.description}>
            {publishDate && <div className={styles.publish_date}>{publishDate}</div>}
            {by && `By: `}
            {sme ? (
              <>
                <Link
                  to={slug && `/knowledge-hub/subject-matter-experts/${slug}`}
                  className={styles.sme_wrapper}
                >
                  {name}
                </Link>
                , {title && ` ${title} `}
                <div className={styles.sme_tag}>
                  <SVG name="certified" /> Subject Matter Expert
                </div>
              </>
            ) : (
              <>
                {name}
                {title && <>{`, ${title}`}</>}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

Author.propTypes = {
  /**
   * Include 'By:' in front of the author's name
   */
  by: PropTypes.bool,
  /**
   * Additional classes on the wrapping element.
   */
  className: PropTypes.string,
  /**
   * Author's Photo
   */
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /**
   * Author's Name
   */
  name: PropTypes.string,
  /**
   * Author is a Subject Matter Expert
   */
  sme: PropTypes.bool,
  /**
   * Author's Job Title
   */
  title: PropTypes.string,
  /**
   *   /**
   * Link to SME detail page
   */
  slug: PropTypes.string,
  /**
   * Publish Date
   */
  publishDate: PropTypes.string
}

Author.defaultProps = {}

export default Author
