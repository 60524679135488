/* eslint-disable no-empty */
import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { useMediaQuery } from "react-responsive"

import Animate from "@components/animate"
import Breadcrumbs from "@components/breadcrumbs"
import Container from "@components/container"
import Image from "@components/image"
import SVG from "@components/svg"

// -------------------------------------------------------- | styles

import {
  author_photo,
  author_photo_wrapper,
  breadcrumbs,
  expression_gray,
  full_width_heading,
  has_overlay,
  is_contact_us,
  is_home,
  job_title,
  jumbotron_background_image,
  jumbotron_background_clipped,
  jumbotron_content,
  jumbotron_content_sme,
  jumbotron,
  sme_label,
  theme_dark,
  theme_light,
  video_container,
  jumbotron_layout_update
} from "./styles.module.scss"
import HlsVideoPlayer from "@components/hls-video-player"

const themeOptions = {
  light: theme_light,
  dark: theme_dark,
  sme: null,
  jumbotron_layout_update: jumbotron_layout_update
}

// -------------------------------------------------------- | component

const Jumbotron = (props) => {
  let { background_image, children, currentPath, overlay, sme, theme } = props

  const mediumScreen = useMediaQuery({ query: "(min-width: 65em)" })

  const homepage = currentPath === "/"
  const contactUs = currentPath === "/contact-us/"

  let fullWidthHeading = ""
  try {
    fullWidthHeading =
      children === undefined ? "" : children.length === 1 && children[0].props.blocks.length === 1
  } catch {}

  const classes = classNames(jumbotron, {
    [is_home]: homepage,
    [is_contact_us]: contactUs,
    [full_width_heading]: fullWidthHeading,
    [themeOptions[theme]]: themeOptions[theme]
  })

  const sme_breadcrumbs_config = {
    links: [
      {
        label: "Knowledge Hub",
        url: "/knowledge-hub"
      },
      {
        label: "Subject Matter Experts",
        url: "/knowledge-hub/subject-matter-experts/"
      }
    ]
  }

  // --------------------------------------------------------

  return (
    <div className={classes}>
      {!homepage && background_image && (
        <Image
          className={classNames(jumbotron_background_image, {
            [has_overlay]: overlay,
            [jumbotron_background_clipped]: theme === "sme"
          })}
          src={background_image}
          alt={background_image.title}
          loading="eager"
        />
      )}

      {contactUs && (
        <div className={expression_gray}>
          <div>
            <SVG name="expression-gray" />
          </div>
        </div>
      )}

      {mediumScreen && homepage && (
        <Animate animate="fade_in" className={video_container}>
          <HlsVideoPlayer source="/uploads/hls/video-hls.m3u8" />
        </Animate>
      )}

      {theme === "sme" && (
        <Container theme="none" padding="none">
          <div className={jumbotron_content_sme}>
            <div>
              <Breadcrumbs {...sme_breadcrumbs_config} className={breadcrumbs} />

              <h1>{sme?.name}</h1>
              <span className={job_title}>{sme?.title}</span>
              <span className={sme_label}>
                <SVG name="certified" />
                Subject Matter Expert
              </span>
            </div>

            <div>
              <div className={author_photo_wrapper}>
                <Image src={sme?.image} className={author_photo} loading="eager" />
              </div>
            </div>
          </div>
        </Container>
      )}

      <div
        className={`${jumbotron_content} ${
          theme === "jumbotron_layout_update" ? jumbotron_layout_update : ""
        }`}
      >
        {children}
      </div>
    </div>
  )
}

Jumbotron.propTypes = {
  /**
   * Specifies the image src and alt text for the background image
   */
  background_image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /**
   * Specifies anything between the opening and closing tag.
   */
  children: PropTypes.node,
  /**
   * Contains the current page path.
   */
  currentPath: PropTypes.string,
  /**
   * Specifies the heading
   */
  heading: PropTypes.string,
  /**
   * Specifies if the background image needs the purple overlay
   */
  overlay: PropTypes.bool,
  /**
   * Specifies content in SME theme
   */
  sme: PropTypes.shape({
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    image: PropTypes.object.isRequired
  }),
  /**
   * Specifies the card theme
   */
  theme: PropTypes.oneOf(Object.keys(themeOptions))
}

Jumbotron.defaultProps = {}

export default Jumbotron
