export default {
  withDuration: {
    duration: "12:24"
  },
  withWordcountNum: {
    wordCount: 1200
  },
  withWordcountObj: {
    wordCount: {
      words: 800
    }
  }
}
