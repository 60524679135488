import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import { Icon } from "@components/icon"
const { parseContentfulJSON } = require("@src/utils")

import { card_icon_box, size_large } from "./styles.module.scss"

const IconCard = ({ config, configObject, large }) => {
  let { title, icon, body } = parseContentfulJSON(config)

  return (
    <div className={classNames(card_icon_box, { [size_large]: large })}>
      <Icon name={icon || configObject?.icon} />
      <h3>{title || configObject?.title}</h3>
      <p>{body || configObject?.body}</p>
    </div>
  )
}

IconCard.propTypes = {
  /**
   * Config JSON object from Contentful
   */
  config: PropTypes.object,
  /**
   * Config JS object
   */
  configObject: PropTypes.shape({
    body: PropTypes.string,
    icon: PropTypes.string,
    title: PropTypes.string
  }),
  /**
   * Increases padding below header
   */
  large: PropTypes.bool
}

export default IconCard
