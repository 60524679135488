import React from "react"
import PropTypes from "prop-types"

import Animate from "@components/animate"
import Button from "@components/button"
import { job } from "./styles.module.scss"

const Job = (props) => {
  const {
    id,
    title,
    url,
    location: { name: location },
    currentLocation
  } = props
  const selectJob = (title, url) => {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: "click",
        category: "Careers",
        label: `${title} | ${url}`
      })
    }
    return false
  }

  return (
    <Animate animate="fade_in" className={job} key={id}>
      <div>
        <h4>{title}</h4>
        <p>{currentLocation === "all" ? location.split(";").join(", ") : currentLocation}</p>
      </div>

      <Button to={url} size="large" onClick={() => selectJob(title, url)} theme="purple">
        Learn more
      </Button>
    </Animate>
  )
}

Job.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  offices: PropTypes.object,
  url: PropTypes.string,
  location: PropTypes.string,
  currentLocation: PropTypes.string
}

export default Job
