// extracted by mini-css-extract-plugin
export var arc = "arc-YAA";
export var background_wrapper = "background_wrapper-e4c";
export var benefit_item = "benefit_item-nYi";
export var benefits_group = "benefits_group-vs8";
export var benefits_list = "benefits_list-XvS";
export var container = "container-ixu";
export var growLineDesktop = "growLineDesktop-Gw-";
export var growLineMobile = "growLineMobile-aDJ";
export var header = "header-UZI";
export var is_visible = "is_visible-dym";
export var rotate = "rotate-K1U";
export var rotateReverse = "rotateReverse-7G3";