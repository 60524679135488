import React from "react"
import Block from "@components/block"

export const renderBlocks = (blocks = [], owner) => {
  blocks = Array.isArray(blocks) ? blocks : []

  return (blocks || []).map((block, idx) => (
    <Block key={idx} __typename={block.__typename} owner={owner} {...block} />
  ))
}
