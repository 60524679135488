export default {
  default: {
    links: [
      { url: "#", icon: "linkedin" },
      { url: "#", icon: "twitter" },
      { url: "#", icon: "facebook" },
      { url: "#", icon: "instagram" }
    ]
  }
}
