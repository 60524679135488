/* eslint-disable jsx-a11y/no-autofocus */
import React, { Component } from "react"

import fetch from "isomorphic-fetch"
import Layout from "@src/layout"
import Container from "@src/components/container"
import withLocation from "./withLocation"
import Link from "@components/link"
import Pagination from "@components/pagination"
import InputGroup from "@components/input-group"
import {
  category,
  no_results,
  result_header,
  result_wrapper,
  search_field,
  search_wrapper
} from "./styles.module.scss"

class Search extends Component {
  constructor(props) {
    super(props)
    this.state = {
      touched: false,
      count: 0,
      per: Number(props.search.per) <= 10 ? Number(props.search.per) : 10,
      page: Number(props.search.page) || 1,
      q: props.search.q || "",
      results: []
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.goTo = this.goTo.bind(this)
  }

  componentDidMount() {
    if (this.state.q !== "") {
      this.getResults()
    }
  }

  handleChange(event) {
    this.setState({ q: event.target.value })
    event.preventDefault()
  }

  async handleSubmit(event) {
    event.preventDefault()
    if (window.dataLayer) {
      window.dataLayer.push({
        event: "submit",
        category: "Site Search",
        label: this.state.q
      })
    }
    this.getResults()
  }

  getResults() {
    const url = new URL(process.env.AZURE_SEARCH_INDEX_URL)
    url.searchParams.append("api-version", "2020-06-30-Preview")
    url.searchParams.append("search", this.state.q)
    url.searchParams.append("$count", true)
    url.searchParams.append("$top", this.state.per)
    url.searchParams.append("$skip", this.state.per * (this.state.page - 1))

    fetch(url.toString(), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.AZURE_SEARCH_QUERY_KEY
      }
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({ touched: true, results: data.value, count: data["@odata.count"] }, () => {
          this.updateQuery()
        })
      })
  }

  updateQuery() {
    window.history.replaceState(
      null,
      null,
      `?q=${this.state.q}&page=${this.state.page}&per=${this.state.per}`
    )
  }

  renderResults() {
    return this.state.results.map((result, i) => {
      let categoryName = result.path.split(/[//]/)[1].replace(/-/g, " ")
      if (categoryName === "") {
        categoryName = "home"
      }

      return (
        <div key={`result-${i}`} className={result_wrapper}>
          {categoryName && <span className={category}>{categoryName}</span>}
          <h4>
            <Link to={result.path}>{result.title}</Link>
          </h4>
          <p>{this.truncate(result.body)}</p>
        </div>
      )
    })
  }

  zeroResults() {
    return (
      <div className={no_results}>
        <p>There are no results for your query.</p>
      </div>
    )
  }

  truncate(str, num = 250) {
    return str.length > num ? str.slice(0, num > 3 ? num - 3 : num) + "..." : str
  }

  pluralize(str, n) {
    return n > 1 ? `${n} ${str}s` : `${n} ${str}`
  }

  goTo(n) {
    this.setState({ page: n }, () => {
      this.getResults()
      window.scrollTo(0, 0)
    })
  }

  render() {
    return (
      <Layout title={`Site Search`} locationPath="/search">
        <Container padding="large">
          <div className={search_wrapper}>
            <h1>Search</h1>
            <form onSubmit={this.handleSubmit}>
              <InputGroup
                className={search_field}
                label="Search"
                placeholder="Keyword Search"
                value={this.state.q}
                onChange={this.handleChange}
              />
            </form>
            {this.state.count > 0 && (
              <>
                <header className={result_header}>
                  {this.pluralize("Result", this.state.count)} for "{this.state.q}"
                </header>
                {this.renderResults()}
                <Pagination
                  key={this.state.results}
                  currentPage={Number(this.state.page)}
                  itemsPerPage={this.state.per}
                  totalItems={this.state.count}
                  onChange={this.goTo}
                />
              </>
            )}
            {this.state.count === 0 && this.state.touched && this.zeroResults()}
          </div>
        </Container>
      </Layout>
    )
  }
}

Search.propTypes = {}

Search.defaultProps = {}

export default withLocation(Search)
