/* eslint-disable react/prop-types */
import React, { useState } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import fixtures from "./fixtures"

import Animate from "@components/animate"
import InputGroup from "@components/input-group"
import Link from "@components/link"

import * as styles from "./styles.module.scss"

const SubscribeForm = (props) => {
  const { title, label, placeholder, link, body, theme } =
    props.__typename === "ContentfulSnippet" ? fixtures.dark : props

  const [email, setEmail] = useState("")

  const handleInputChange = (e) => {
    e.preventDefault()
    setEmail(e.target.value)

    console.log(email)
  }

  return (
    <Animate
      animate="fade_in"
      className={classNames(styles.subscribe_form, {
        [styles.subscribe_form_dark]: theme === "dark",
        [styles.subscribe_form_light]: theme === "light"
      })}
    >
      <h2>{title}</h2>
      <InputGroup
        className={styles.input_group}
        label={label}
        placeholder={placeholder}
        value={email}
        onChange={handleInputChange}
      />
      <p>
        {body} {link && <Link to={link.url}>{link.label}</Link>}
      </p>
    </Animate>
  )
}

SubscribeForm.propTypes = {
  /**
   * Subscribe form title
   */
  title: PropTypes.string,
  /**
   * input label
   */
  label: PropTypes.string,
  /**
   * Body copy displayed below input
   */
  body: PropTypes.string,
  /**
   * input placeholder
   */
  placeholder: PropTypes.string,
  /**
   * defines a link displayed at the end of the body text
   */
  link: PropTypes.shape({
    label: PropTypes.string,
    url: PropTypes.string
  }),
  /**
   * A choice between two themes
   */
  theme: PropTypes.oneOf(["light", "dark"])
}

SubscribeForm.defaultProps = {
  label: "Submit",
  theme: "light"
}

export default SubscribeForm
