import React, { useState } from "react"
import cookie from "js-cookie"

// --------------------------------------------------------

import { banner, container, close_icon } from "./styles.module.scss"
import Button from "@components/button"
import SVG from "@components/svg"
import Link from "@components/link/component"

// --------------------------------------------------------

const GpcBanner = () => {
  const [isBannerVisible, setIsBannerVisible] = useState(!cookie.get("gpc_banner_close"))

  const closeBanner = () => {
    cookie.set("gpc_banner_close", "true", { expires: 365 })
    setIsBannerVisible(false)
  }

  // If the banner is not visible, return null
  if (!isBannerVisible) {
    return null
  }
  return (
    <div className={banner}>
      <button className={close_icon} type="button" aria-label="Close" onClick={closeBanner}>
        <SVG name="close" />
      </button>

      <div className={container}>
        <div>
          <h3>Your Opt-Out Preference Signal is Honored</h3>
          <p>
            This confirms that we acknowledge and honor your opt-out preference signal, such as
            Global Privacy Control or other authorized Universal Opt-Out Mechanism. You can click on{" "}
            <Link to="https://privacyportal.onetrust.com/webform/f95f67ef-e8ad-4274-9c69-04fd38042f86/bdcb0219-178b-4c21-845c-2c58736c7a6e">
              Your Privacy Choices
            </Link>{" "}
            to learn more and exercise other opt out rights you may have.
          </p>
        </div>
        <div>
          <Button onClick={closeBanner}>Close</Button>
        </div>
      </div>
    </div>
  )
}

export default GpcBanner
