import React from "react"
import PropTypes from "prop-types"
import { StaticImage } from "gatsby-plugin-image"

import Animate from "@components/animate"
import Button from "@components/button"
import Container from "@components/container"

import {
  callout_block,
  callout_grid,
  callout_img_1,
  callout_img_2,
  callout_wrapper
} from "./styles.module.scss"

const CalloutBlock = ({ body, heading, last }) => (
  <div className={callout_block}>
    <h3>{heading}</h3>
    <p>{body} </p>
    {last && (
      <Button to="/careers/early-career-paths/" size="large" theme="purple">
        View Early Career Opportunities
      </Button>
    )}
  </div>
)

const Callouts = ({ blocks }) => {
  return (
    <Animate animate="fade_in">
      <Container padding="none" className={callout_wrapper}>
        <div className={callout_grid}>
          {blocks?.[0] && <CalloutBlock {...blocks[0]} />}

          <div className={callout_img_1}>
            <StaticImage
              objectFit="contain"
              placeholder="none"
              src={`https://images.ctfassets.net/c23k8ps4z1xm/a6uoSbuyz6OW9Aa0Y1sZC/ab83117533667ee59d890014476ab992/careers-opportunities-revised.png`}
            />
          </div>

          {blocks?.[1] && <CalloutBlock {...blocks[1]} />}

          <div className={callout_img_2}>
            <StaticImage
              objectFit="contain"
              placeholder="none"
              src={`https://images.ctfassets.net/c23k8ps4z1xm/z9pK8rrCOcBumCk92KfWq/950c33166fdeea18aed75b129b84d7c3/careers-trifecta.png`}
            />
          </div>

          {blocks?.[2] && <CalloutBlock {...blocks[2]} last />}
        </div>
      </Container>
    </Animate>
  )
}

CalloutBlock.propTypes = {
  body: PropTypes.string,
  heading: PropTypes.string,
  last: PropTypes.bool
}

Callouts.propTypes = {
  blocks: PropTypes.shape({
    body: PropTypes.string,
    heading: PropTypes.string
  })
}

export default Callouts
