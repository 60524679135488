import React, { useRef } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import Animate from "@components/animate"
import Image from "@components/image"
import Flourish from "@components/flourish"

import { useIsVisible } from "react-is-visible"
import "intersection-observer"

// -------------------------------------------------------- | styles

import {
  container_content_with_shadow,
  container_content,
  content_animation_container,
  container,
  padding_48,
  padding_bottom_48,
  padding_top_48,
  padding_72,
  padding_bottom_72,
  padding_top_72,
  padding_120,
  padding_bottom_120,
  padding_top_120,
  theme_background_image,
  theme_gray_background,
  theme_light_gray_background,
  theme_gray_content_block,
  theme_left_column_raised_with_shadow,
  theme_white_background,
  theme_white_content_block_with_shadow,
  theme_white_section_with_shadow,
  type_fixed,
  product_jumbotron_update,
  theme_careers_jumbotron_update,
  theme_pillar_jumbotron_update,
  theme_careers_slider_section,
  section_with_icon_card_grid,
  section_with_icon_card_grid_content,
  video_section_update,
  soft_gray_background,
  animation_container
} from "./styles.module.scss"

const paddingOptions = {
  none: null,
  small: padding_48,
  small_top: padding_top_48,
  small_bottom: padding_bottom_48,
  medium: padding_72,
  medium_top: padding_top_72,
  medium_bottom: padding_bottom_72,
  large: padding_120,
  large_top: padding_top_120,
  large_bottom: padding_bottom_120
}

const themeOptions = {
  gray_background: theme_gray_background,
  light_gray_background: theme_light_gray_background,
  gray_content_block: theme_gray_content_block,
  has_background_image: theme_background_image,
  left_column_raised_with_shadow: theme_left_column_raised_with_shadow,
  none: null,
  white_background: theme_white_background,
  white_content_block_with_shadow: theme_white_content_block_with_shadow,
  white_section_with_shadow: theme_white_section_with_shadow,
  product_jumbotron_update: product_jumbotron_update,
  pillar_jumbotron_update: theme_pillar_jumbotron_update,
  careers_jumbotron_update: theme_careers_jumbotron_update,
  careers_slider_section: theme_careers_slider_section,
  section_with_icon_card_grid: section_with_icon_card_grid,
  soft_gray_background: soft_gray_background,
  video_section_update: video_section_update
}

const typeOptions = {
  fixed: type_fixed,
  fluid: null
}

// -------------------------------------------------------- | component

const Container = (props) => {
  let { background_image, children, className, padding, id, theme, type, showAnimation } = props

  if (
    background_image &&
    theme !== "white_content_block_with_shadow" &&
    theme !== "pillar_jumbotron_update" &&
    theme !== "careers_jumbotron_update"
  ) {
    theme = "has_background_image"
  }

  const classes = classNames(container, {
    [className]: className,
    [themeOptions[theme]]: themeOptions[theme]
  })

  const nodeRef = useRef()
  const isVisible = useIsVisible(nodeRef, { once: true })

  // --------------------------------------------------------

  return (
    <div className={classes} id={id && id}>
      {showAnimation && (
        <div className={animation_container} ref={nodeRef}>
          <Flourish animate={isVisible} />
        </div>
      )}

      {background_image && (
        <Image src={background_image} loading={theme.includes("jumbotron") ? "eager" : "lazy"} />
      )}

      <div
        className={classNames(container_content, {
          [paddingOptions[padding]]: paddingOptions[padding],
          [typeOptions[type]]: typeOptions[type]
        })}
      >
        <Animate
          animate={theme === "white_content_block_with_shadow" ? "fade_in_up" : null}
          delay={theme === "white_content_block_with_shadow" ? 1000 : 0}
          className={classNames(content_animation_container, {
            [container_content_with_shadow]:
              theme === "white_content_block_with_shadow" || theme === "white_section_with_shadow",
            [section_with_icon_card_grid_content]: theme === "section_with_icon_card_grid"
          })}
        >
          {children}
        </Animate>
      </div>
    </div>
  )
}

Container.propTypes = {
  /**
   * Specifies the image src and alt text for the background image
   */
  background_image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /**
   * A CSS class adding to the wrapping element. This is not used in CMS.
   */
  className: PropTypes.string,
  /**
   * Anything being passed in from the CMS or Template.
   */
  children: PropTypes.node,
  /**
   * Assigned an id to the wrapper element
   */
  id: PropTypes.string,
  /**
   * Specifies top and bottom padding for the container.
   */
  padding: PropTypes.oneOf(Object.keys(paddingOptions)),
  /**
   * Specifies the theme of the container
   */
  theme: PropTypes.oneOf(Object.keys(themeOptions)),
  /**
   * Specifies the type of container
   */
  type: PropTypes.oneOf(Object.keys(typeOptions)),
  showAnimation: PropTypes.bool
}

Container.defaultProps = {
  className: null,
  padding: "large_top",
  theme: "white_background",
  type: "fixed"
}

export default Container
