import React, { useState } from "react"
import { useMediaQuery } from "react-responsive"
import classNames from "classnames"
import OutsideClickHandler from "react-outside-click-handler"
import PropTypes from "prop-types"

import { Dropdown } from "@src/components/dropdown"

import { navigation, nav_dropdown, nav_dropdown_right, is_active } from "./styles.module.scss"

const Navigation = ({ className, links = [], onClick, handleDropdownOpen, rightAlignDropdown }) => {
  const [openDropdown, setOpenDropdown] = useState("")
  const classes = classNames(navigation, className, {})

  const handleQueryChange = (match) => {
    if (!match) {
      setOpenDropdown("")
    }
  }

  const isMobileNavigation = useMediaQuery(
    { query: "(max-width: 65em)" },
    undefined,
    handleQueryChange
  )

  const handleDropdownClick = (label) => {
    label === openDropdown ? setOpenDropdown("") : setOpenDropdown(label)

    if (handleDropdownOpen) {
      openDropdown !== "" ? handleDropdownOpen(false) : handleDropdownOpen(true)
    }
  }

  const resetDropdownOnOutsideClick = () => {
    setOpenDropdown("")
    handleDropdownOpen(false)
  }

  return (
    <OutsideClickHandler onOutsideClick={() => resetDropdownOnOutsideClick() || isMobileNavigation}>
      <ul className={classes}>
        {links.map((item, index) => {
          return (
            <li key={index}>
              <Dropdown
                theme="header"
                alert={item.alert}
                activeClassName={is_active}
                className={rightAlignDropdown ? nav_dropdown_right : nav_dropdown}
                handleDropdownClick={handleDropdownClick}
                items={item.links}
                key={index}
                label={item.heading}
                onClick={onClick}
                openDropdown={openDropdown}
                addUnderlineOnHover={true}
                rightAlignDropdown={rightAlignDropdown}
              />
            </li>
          )
        })}
      </ul>
    </OutsideClickHandler>
  )
}

Navigation.propTypes = {
  /**
   * CSS class(es) applied to the wrapping element
   */
  className: PropTypes.string,
  /**
   * An array of menu objects used to build the list
   */
  links: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string.isRequired,
      links: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          url: PropTypes.string.isRequired,
          children: PropTypes.arrayOf(
            PropTypes.shape({
              label: PropTypes.string.isRequired,
              url: PropTypes.string.isRequired,
              children: PropTypes.arrayOf(
                PropTypes.shape({
                  label: PropTypes.string.isRequired,
                  url: PropTypes.string.isRequired
                })
              )
            })
          )
        })
      )
    })
  ).isRequired,
  /**
   * A onClick function passed to all navigation menu items
   */
  onClick: PropTypes.func,
  /**
   * A function used to let the parent know when a dropdown is open
   */
  handleDropdownOpen: PropTypes.func
}

Navigation.defaultProps = {}

export default Navigation
