// extracted by mini-css-extract-plugin
export var bobbing1 = "bobbing1-POv";
export var bobbing2 = "bobbing2-9+h";
export var bobbing3 = "bobbing3-4Sp";
export var bobbing4 = "bobbing4-+g-";
export var bobbing5 = "bobbing5-ds9";
export var bobbing6 = "bobbing6-hMK";
export var bobbing7 = "bobbing7-4EN";
export var bobbing8 = "bobbing8-31u";
export var bobbing9 = "bobbing9-MtW";
export var flourish = "flourish-h7H";
export var flourish_dot = "flourish_dot-cMz";
export var flourish_line = "flourish_line-cOy";
export var start_animation = "start_animation-+dk";