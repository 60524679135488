export default {
  default: {
    src: {
      gatsbyImageData: {
        layout: "constrained",
        backgroundColor: "#d8e8f8",
        images: {
          fallback: {
            src: "/storybook/9e01fe059bae0bedd117446c113abf11/b44b0/large-dark.jpg",
            srcSet:
              "/storybook/9e01fe059bae0bedd117446c113abf11/f782f/large-dark.jpg 250w,\n/storybook/9e01fe059bae0bedd117446c113abf11/24d85/large-dark.jpg 500w,\n/storybook/9e01fe059bae0bedd117446c113abf11/b44b0/large-dark.jpg 1000w,\n/storybook/9e01fe059bae0bedd117446c113abf11/8ba2d/large-dark.jpg 2000w",
            sizes: "(min-width: 1000px) 1000px, 100vw"
          },
          sources: [
            {
              srcSet:
                "/storybook/9e01fe059bae0bedd117446c113abf11/2a4db/large-dark.webp 250w,\n/storybook/9e01fe059bae0bedd117446c113abf11/c0386/large-dark.webp 500w,\n/storybook/9e01fe059bae0bedd117446c113abf11/e8e37/large-dark.webp 1000w,\n/storybook/9e01fe059bae0bedd117446c113abf11/9dca3/large-dark.webp 2000w",
              type: "image/webp",
              sizes: "(min-width: 1000px) 1000px, 100vw"
            }
          ]
        },
        width: 1000,
        height: 414.99999999999994
      }
    }
  },
  native: {
    src: "/storybook/9e01fe059bae0bedd117446c113abf11/b44b0/large-dark.jpg",
    width: "1077",
    height: "718"
  }
}
