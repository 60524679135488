export default {
  default: {
    body: {
      childMarkdownRemark: {
        html:
          "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>"
      }
    }
  }
}
