import React, { Fragment } from "react"
import PropTypes from "prop-types"
import svgs from "./svgs"

const svgOptions = {
  "angle-down": "angle-down",
  "angle-right": "angle-right",
  "arrow-left": "arrow-left",
  "arrow-right": "arrow-right",
  bars: "bars",
  certified: "certified",
  "chevron-circle-right": "chevron-circle-right",
  clock: "clock",
  close: "close",
  "expression-color": "expression-color",
  "expression-gray": "expression-gray",
  facebook: "facebook",
  instagram: "instagram",
  link: "link",
  linkedin: "linkedin",
  lock: "lock",
  logo: "logo",
  play: "play",
  privacy: "privacy",
  search: "search",
  twitter: "twitter"
}

const SVG = (props) => {
  const { name } = props
  return <Fragment>{svgs[name]}</Fragment>
}

SVG.propTypes = {
  /**
   * The name of the svg
   */
  name: PropTypes.oneOf(Object.keys(svgs)).isRequired
}

SVG.defaultProps = {}

export default SVG

export { SVG, svgOptions }
