import React, { useRef } from "react"
import classNames from "classnames"
import PropTypes from "prop-types"

import "intersection-observer"
import { useIsVisible } from "react-is-visible"

// -------------------------------------------------------- | styles

import {
  animated,
  display_block,
  display_flex,
  fadeIn,
  fadeInBottomLeft,
  fadeInBottomRight,
  fadeInDown,
  fadeInLeft,
  fadeInRight,
  fadeInTopLeft,
  fadeInTopRight,
  fadeInUp,
  z_index,
  no_animation
} from "./styles.module.scss"

const animateOptions = {
  fade_in_bottom_left: fadeInBottomLeft,
  fade_in_bottom_right: fadeInBottomRight,
  fade_in_down: fadeInDown,
  fade_in_left: fadeInLeft,
  fade_in_right: fadeInRight,
  fade_in_top_left: fadeInTopLeft,
  fade_in_top_right: fadeInTopRight,
  fade_in_up: fadeInUp,
  fade_in: fadeIn
}

const displayOptions = {
  block: display_block,
  flex: display_flex
}

// -------------------------------------------------------- | component

const Animate = (props) => {
  let {
    animate,
    children,
    className,
    delay,
    display,
    disabled,
    duration,
    id,
    onClick,
    style,
    tag: Tag,
    type,
    zIndex,
    noAnimation
  } = props

  const nodeRef = useRef()
  const isVisible = useIsVisible(nodeRef, { once: true })

  let animateStyles = {
    animationDelay: isVisible && delay ? `${delay}ms` : "300ms",
    animationDuration: duration ? `${duration}ms` : "1000ms",
    ...style
  }

  return (
    <Tag
      ref={nodeRef}
      className={classNames({
        [animated]: animate,
        [animateOptions[animate]]: isVisible,
        [displayOptions[display]]: display,
        [z_index]: zIndex,
        [className]: className,
        [no_animation]: noAnimation
      })}
      id={id && id}
      onClick={onClick}
      style={animateStyles}
      type={type}
      disabled={disabled}
    >
      {children}
    </Tag>
  )
}

Animate.propTypes = {
  disabled: PropTypes.bool,
  noAnimation: PropTypes.bool,
  animate: PropTypes.oneOf(Object.keys(animateOptions)),
  children: PropTypes.node,
  className: PropTypes.string,
  delay: PropTypes.number,
  display: PropTypes.oneOf(Object.keys(displayOptions)),
  duration: PropTypes.number,
  id: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.object,
  tag: PropTypes.string,
  type: PropTypes.string,
  zIndex: PropTypes.bool
}

Animate.defaultProps = {
  tag: "div",
  noAnimation: true
}

export default Animate
