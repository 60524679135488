/* eslint-disable react/prop-types */
import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import Animate from "@components/animate"
import SVG from "@components/svg"

import { time_to_read } from "./styles.module.scss"

const TimeToRead = (props) => {
  const { className, duration, type, wordCount } = props

  // ---------------------------- | Display based on post type
  const hidden = ["news"]

  if (!type || typeof type !== "string" || hidden.some((val) => val === type.toLowerCase())) {
    return null
  }

  // Display podcast time *only* if it has a manually entered duration
  if (type.toLowerCase() === "podcast" && !duration) return null

  // ---------------------------- | Calculate read time

  const val = typeof wordCount === "number" ? wordCount : wordCount?.words

  if (!duration && !val) return null

  let readTime = val < 238 ? 1 : Math.round(val / 238)

  return (
    <Animate animate="fade_in" className={classNames(time_to_read, { [className]: className })}>
      <>
        <SVG name="clock" />
        {duration ? duration : `${readTime} MIN READ`}
      </>
    </Animate>
  )
}

TimeToRead.propTypes = {
  /**
   * Additional classes on the wrapping element.
   */
  className: PropTypes.string,
  /**
   * Manual override provided by content editors. Displays as text without a caluclation.
   */
  duration: PropTypes.string,
  /**
   * Show/hide TimeToRead based on the post's type
   */
  type: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /**
   * Word count generated by Contentful, used to calculate read time
   */
  wordCount: PropTypes.oneOfType([PropTypes.number, PropTypes.shape({ words: PropTypes.number })])
}

TimeToRead.defaultProps = {}

export default TimeToRead
