/* eslint-disable react/prop-types */
import React from "react"
import PropTypes from "prop-types"

import Animate from "@components/animate"
import Breadcrumbs from "@components/breadcrumbs"
import Container from "@components/container"
import Image from "@components/image"
import Link from "@components/link"
import TimeToRead from "@components/time-to-read"

const { categoryPath } = require("@src/utils")

import * as styles from "./styles.module.scss"

const PostHighlight = (props) => {
  let { references } = props

  const renderPostHighlight = () => {
    return (references || []).map((el, index) => {
      const { title, body, resource_type: type, category, image, duration } = el

      const wordCount = el.body?.childMarkdownRemark?.wordCount

      const breadcrumbs_config = {
        links: [
          {
            label: category.title,
            url: categoryPath(category)
          },
          {
            label: type?.title && type?.title,
            url: categoryPath(type)
          }
        ]
      }

      return (
        <div className={styles.post_highlight} key={index}>
          <Animate
            animate="fade_in_left"
            delay={500}
            className={styles.post_highlight_image_container}
          >
            {image && <Image className={styles.post_highlight_image} src={image} />}
            <div className={styles.gray_box}></div>
          </Animate>
          <Animate
            animate="fade_in_right"
            delay={500}
            className={styles.post_highlight_content_container}
          >
            <h4>Post Highlight</h4>

            {title && (
              <h2>
                <Link to={el.post_path}>{title}</Link>
              </h2>
            )}
            {body && <p>{body.childMarkdownRemark.excerpt}</p>}

            <Breadcrumbs className={styles.category_container} {...breadcrumbs_config} />

            {(wordCount || duration) && (
              <TimeToRead wordCount={wordCount} duration={duration} type={type?.title} />
            )}
          </Animate>
        </div>
      )
    })
  }

  return <Container padding="none">{renderPostHighlight()}</Container>
}

PostHighlight.propTypes = {
  /**
   * Main title of highlight
   */
  title: PropTypes.string,
  /**
   * Body copy displayed in the highlight
   */
  body: PropTypes.string,
  /**
   * Specifies the type of content in the Knowledge Hub
   */
  post_type: PropTypes.string,
  /**
   * Specifies the category that the content belongs to in the Knowledge Hub
   */
  category: PropTypes.shape({
    title: PropTypes.string,
    slug: PropTypes.string,
    type: PropTypes.string
  }),
  /**
   * Specifies the image src and alt text
   */
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /**
   * TimeToRead override provided by content editors
   */
  duration: PropTypes.string
}

export default PostHighlight
