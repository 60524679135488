import React from "react"
import PropTypes from "prop-types"

import Animate from "@components/animate"
import Image from "@components/image"
import Link from "@components/link"
import SVG from "@components/svg"

import {
  video_container,
  video_thumbnail,
  video_thumbnail_play_button,
  rounded
} from "./styles.module.scss"

const Video = ({ currentIndex, description, image, url, theme }) => {
  return (
    <Animate animate="fade_in_up" delay={currentIndex * 300}>
      <Link to={url} className={`${video_container} ${theme === "rounded" ? rounded : ""}`}>
        {image && (
          <div className={`${video_thumbnail} ${theme === "rounded" ? rounded : ""}`}>
            <Image src={image} />
            <div className={`${video_thumbnail_play_button} ${theme === "rounded" ? rounded : ""}`}>
              <span>
                Play <SVG name="play" />
              </span>
            </div>
          </div>
        )}

        {description && <p>{description}</p>}
      </Link>
    </Animate>
  )
}

Video.propTypes = {
  /**
   * Get the current index.
   */
  currentIndex: PropTypes.number,
  /**
   * Specifies the description of the video
   */
  description: PropTypes.string,
  /**
   * Specifies the image src attribute as a string or a Gatsby image object
   */
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /**
   * URL to video
   */
  url: PropTypes.string,
  /**
   * Specifies the theme of the video
   */
  theme: PropTypes.string
}

Video.defaultProps = {}

export default Video
