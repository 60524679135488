export default {
  cardsWithContentTheme: {
    theme: "cards-with-content",
    title: "84.51° Stratum case studies",
    body: {
      childMarkdownRemark: {
        html: "<p>84.51° Stratum is a breakthrough analytics solution that combines rich customer behavior insights with retail performance measures.</p>"
      }
    }
  },
  cardsWithContentCenteredTheme: {
    theme: "cards-with-content-centered",
    title: "84.51° Stratum case studies",
    body: {
      childMarkdownRemark: {
        html: "<h2>Carousel Header</h1><p>84.51° Stratum is a breakthrough analytics solution that combines rich customer behavior insights with retail performance measures.</p>"
      }
    },
    button_link: {
      label: "View All Subject Matter Experts",
      size: "small",
      theme: "outline",
      url: "/subject-matter-experts",
      __typename: "ContentfulButton"
    }
  },
  desktopDisplayTheme: {
    theme: "desktop-display",
    monitor: {
      gatsbyImageData: {
        layout: "constrained",
        backgroundColor: "#486848",
        images: {
          fallback: {
            src: "/storybook/fe4e39e949fe225b4fce4b59c9e7db4e/4464c/monitor.png",
            srcSet:
              "/storybook/fe4e39e949fe225b4fce4b59c9e7db4e/01b68/monitor.png 250w,\n/storybook/fe4e39e949fe225b4fce4b59c9e7db4e/bf436/monitor.png 500w,\n/storybook/fe4e39e949fe225b4fce4b59c9e7db4e/4464c/monitor.png 1000w",
            sizes: "(min-width: 1000px) 1000px, 100vw"
          },
          sources: [
            {
              srcSet:
                "/storybook/fe4e39e949fe225b4fce4b59c9e7db4e/c9650/monitor.webp 250w,\n/storybook/fe4e39e949fe225b4fce4b59c9e7db4e/8ce7d/monitor.webp 500w,\n/storybook/fe4e39e949fe225b4fce4b59c9e7db4e/dac8f/monitor.webp 1000w",
              type: "image/webp",
              sizes: "(min-width: 1000px) 1000px, 100vw"
            }
          ]
        },
        width: 1000,
        height: 829.0000000000001
      }
    },
    images: [
      {
        gatsbyImageData: {
          layout: "constrained",
          backgroundColor: "#f8f8f8",
          images: {
            fallback: {
              src: "/storybook/706a17bbf3840ebed53010e1d2e895bd/0b094/google.png",
              srcSet:
                "/storybook/706a17bbf3840ebed53010e1d2e895bd/03fcf/google.png 250w,\n/storybook/706a17bbf3840ebed53010e1d2e895bd/60c1e/google.png 500w,\n/storybook/706a17bbf3840ebed53010e1d2e895bd/0b094/google.png 1000w,\n/storybook/706a17bbf3840ebed53010e1d2e895bd/f9f0f/google.png 2000w",
              sizes: "(min-width: 1000px) 1000px, 100vw"
            },
            sources: [
              {
                srcSet:
                  "/storybook/706a17bbf3840ebed53010e1d2e895bd/8451e/google.webp 250w,\n/storybook/706a17bbf3840ebed53010e1d2e895bd/73572/google.webp 500w,\n/storybook/706a17bbf3840ebed53010e1d2e895bd/3f89f/google.webp 1000w,\n/storybook/706a17bbf3840ebed53010e1d2e895bd/b2e8b/google.webp 2000w",
                type: "image/webp",
                sizes: "(min-width: 1000px) 1000px, 100vw"
              }
            ]
          },
          width: 1000,
          height: 639
        }
      },
      {
        gatsbyImageData: {
          layout: "constrained",
          backgroundColor: "#383848",
          images: {
            fallback: {
              src: "/storybook/705dcaee69526a141db6c4a45457cbbe/c4c49/ample.png",
              srcSet:
                "/storybook/705dcaee69526a141db6c4a45457cbbe/03fcf/ample.png 250w,\n/storybook/705dcaee69526a141db6c4a45457cbbe/60c1e/ample.png 500w,\n/storybook/705dcaee69526a141db6c4a45457cbbe/c4c49/ample.png 1000w,\n/storybook/705dcaee69526a141db6c4a45457cbbe/266d2/ample.png 2000w",
              sizes: "(min-width: 1000px) 1000px, 100vw"
            },
            sources: [
              {
                srcSet:
                  "/storybook/705dcaee69526a141db6c4a45457cbbe/8451e/ample.webp 250w,\n/storybook/705dcaee69526a141db6c4a45457cbbe/73572/ample.webp 500w,\n/storybook/705dcaee69526a141db6c4a45457cbbe/257df/ample.webp 1000w,\n/storybook/705dcaee69526a141db6c4a45457cbbe/61a74/ample.webp 2000w",
                type: "image/webp",
                sizes: "(min-width: 1000px) 1000px, 100vw"
              }
            ]
          },
          width: 1000,
          height: 641
        }
      }
    ]
  }
}
