import React from "react"

import Component from "./component"
import fixtures from "./navigation/fixtures"

const Header = () => {
  return process.env.NODE_ENV === "test" ? <Component {...fixtures.default} /> : <Component />
}

export default Header

export { Component as component, fixtures }
