import React, { useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import classNames from "classnames"
import { useMediaQuery } from "react-responsive"

// ---------------------------------------------------------

import Alert from "@components/alert"
import Animate from "@components/animate"
import Button from "@src/components/button"
import Link from "@src/components/link"
import SVG from "@src/components/svg"

// ---------------------------------------------------------

import Navigation from "./navigation"

// ---------------------------------------------------------

import {
  additional_logo,
  contact_button,
  contains_alert,
  container_global_width,
  dropdown_is_showing,
  header_content_container,
  header,
  header_with_shadow,
  logo_collaborative_cloud,
  logo_container,
  logo_in_queries,
  logo_kroger_precision_marketing,
  logo_stratum,
  logo_best_customer_communications,
  logo,
  logos_container,
  main_navigation_container,
  menu_button_container,
  menu_button_open,
  menu_button,
  navigation_container,
  navigation_is_showing,
  right_options_container,
  search_icon,
  search_icon_container,
  sign_in_link,
  sign_in_link_container,
  wrapper
} from "./styles.module.scss"

const Header = (props) => {
  let {
    alert,
    button,
    signInLinks,
    locationPath,
    menus: propMenus,
    smallHeader,
    headerShadow = true
  } = props
  const [menus, setMenus] = useState(propMenus)

  const allContentfulAlerts = useStaticQuery(graphql`
    query fetchAlerts {
      allContentfulAlert {
        alerts: nodes {
          id: contentful_id
          body
          theme
          title
          image {
            gatsbyImageData
          }
          image_modal: image {
            gatsbyImageData(width: 452, height: 288)
            title
          }
          image_modal_small: image {
            gatsbyImageData(
              height: 186
              aspectRatio: 1.6
              quality: 100
              outputPixelDensities: [2, 3]
            )
            title
          }
          button {
            ...Button
          }
        }
      }
    }
  `)

  // ------------------------------------------------------ | Mobile Menu

  useEffect(() => {
    if (allContentfulAlerts?.allContentfulAlert?.alerts) {
      const alerts = allContentfulAlerts?.allContentfulAlert?.alerts
      const newMenus = menus.map((menu) => ({
        ...menu,
        alert: (alerts || []).find((alert) => alert.id === menu.alertId)
      }))
      setMenus(newMenus)
    }
  }, [propMenus, allContentfulAlerts])

  const [menuIsOpen, setMenu] = useState(false)
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false)

  useEffect((isOpen) => {
    if (isOpen) {
      document.documentElement.style.overflow = "hidden"
    } else {
      document.documentElement.style.removeProperty("overflow")
    }
  }, [])

  const smallScreenMenuClick = () => {
    if (isMobileNavigation) {
      lockScroll(!menuIsOpen)
      setMenu(!menuIsOpen)
    }
  }

  const lockScroll = (isOpen) => {
    if (isOpen) {
      document.documentElement.style.overflow = "hidden"
    } else {
      document.documentElement.style.removeProperty("overflow")
    }
  }

  const handleMediaQueryChange = (match) => {
    if (!match) {
      lockScroll(false)
      setMenu(false)
    }
  }

  const handleDropdownOpen = (value) => {
    setDropdownIsOpen(value)
  }

  const searchIconButton = (
    <Link className={search_icon} to="/search">
      <SVG name="search" />
    </Link>
  )

  // ------------------------------------------------------

  const isMobileNavigation = useMediaQuery({ maxWidth: 1200 }, null, handleMediaQueryChange)

  // ------------------------------------------------------ | additional logos

  const collaborativeCloud =
    locationPath === "/solutions/collaborative-cloud/" ||
    locationPath === "/solutions/collaborative-cloud"
  const inQueries =
    locationPath === "/solutions/in-queries/" || locationPath === "/solutions/in-queries"
  const krogerPrecisionMarketing =
    locationPath === "/solutions/kroger-precision-marketing/" ||
    locationPath === "/solutions/kroger-precision-marketing"
  const stratum = locationPath === "/solutions/stratum/" || locationPath === "/solutions/stratum"
  const bestCustomerCommunications =
    locationPath === "/solutions/best-customer-communications/" ||
    locationPath === "/solutions/best-customer-communications"

  const additionalLogos =
    collaborativeCloud ||
    inQueries ||
    krogerPrecisionMarketing ||
    stratum ||
    bestCustomerCommunications

  // ------------------------------------------------------ | Component

  return (
    <header
      className={classNames(wrapper, {
        [contains_alert]: alert
      })}
    >
      <div
        className={classNames(header, {
          [navigation_is_showing]: menuIsOpen,
          [dropdown_is_showing]: dropdownIsOpen,
          [header_with_shadow]: headerShadow
        })}
      >
        {" "}
        {alert && alert.theme === "bar-top" && <Alert {...alert} />}
        <div
          className={classNames(header_content_container, {
            [container_global_width]: smallHeader
          })}
        >
          <div className={logo_container}>
            <button className={menu_button} onClick={smallScreenMenuClick}>
              <span>Menu</span>
              <div
                className={classNames(menu_button_container, {
                  [menu_button_open]: menuIsOpen
                })}
              >
                <div></div>
              </div>
            </button>

            <div className={logos_container}>
              <Link className={logo} to="/">
                <SVG name="logo" />
              </Link>

              {additionalLogos && (
                <div
                  className={classNames(additional_logo, {
                    [logo_collaborative_cloud]: collaborativeCloud,
                    [logo_in_queries]: inQueries,
                    [logo_kroger_precision_marketing]: krogerPrecisionMarketing,
                    [logo_stratum]: stratum,
                    [logo_best_customer_communications]: bestCustomerCommunications
                  })}
                >
                  {collaborativeCloud && <SVG name="logo-collaborative-cloud" />}
                  {inQueries && <SVG name="logo-in-queries" />}
                  {krogerPrecisionMarketing && <SVG name="logo-kroger-precision-marketing" />}
                  {stratum && <SVG name="logo-stratum" />}
                  {bestCustomerCommunications && <SVG name="logo-best-customer-communications" />}
                </div>
              )}
            </div>

            <div className={search_icon_container}>{isMobileNavigation && searchIconButton}</div>
          </div>

          <div className={navigation_container}>
            <Navigation
              className={main_navigation_container}
              links={!isMobileNavigation ? menus : [...menus, ...signInLinks]}
              onClick={smallScreenMenuClick}
              handleDropdownOpen={handleDropdownOpen}
            />
          </div>

          <div className={right_options_container}>
            {!isMobileNavigation && searchIconButton}
            {!isMobileNavigation && signInLinks && (
              <Navigation
                className={main_navigation_container}
                links={signInLinks}
                onClick={smallScreenMenuClick}
                handleDropdownOpen={handleDropdownOpen}
                rightAlignDropdown={true}
              />
            )}
            {button && (
              <div className={contact_button}>
                <Button
                  theme="purple"
                  size={isMobileNavigation ? "large" : "medium"}
                  to={button.url}
                >
                  {button.label}
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  )
}
Header.propTypes = {
  /**
   * An object containing the button url and label information
   */
  alert: PropTypes.object,

  /**
   * An object containing the button url and label information
   */
  button: PropTypes.shape({
    label: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
  }),

  /**
   * An object containing a link url and label information, to be displayed next to the button
   */
  link: PropTypes.shape({
    label: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
  }),

  /**
   * An array of main navigation links.
   * This prop is passed to the Navigation component
   */
  menus: PropTypes.array.isRequired,

  /**
   * An array of sign in links.
   * This prop is passed to the Navigation component
   */
  signInLinks: PropTypes.array,

  /**
   * Specifies the page's path that gets passed down from the page template.
   */
  locationPath: PropTypes.string,

  /**
   * An object containing a link url and label information, to be displayed next to the button
   */
  theme: PropTypes.oneOf(["light", "dark"]),
  /**
   * Smaller width of the container
   */
  smallHeader: PropTypes.bool,
  /**
   * Adds a shadow to the header
   */
  headerShadow: PropTypes.bool
}

Header.defaultProps = {
  theme: "light"
}

export default Header
