export default {
  props: {
    heading: "Shining a Light on Summer of 2021. Take a peek.",
    image: {
      gatsbyImageData: {
        layout: "fixed",
        backgroundColor: "#c8d8d8",
        images: {
          fallback: {
            src: "/storybook/ad44a49cc53abc6342418a231eb625bd/5a4a4/media-object.jpg",
            srcSet:
              "/storybook/ad44a49cc53abc6342418a231eb625bd/1cb07/media-object.jpg 25w,\n/storybook/ad44a49cc53abc6342418a231eb625bd/d27a5/media-object.jpg 50w,\n/storybook/ad44a49cc53abc6342418a231eb625bd/5a4a4/media-object.jpg 100w,\n/storybook/ad44a49cc53abc6342418a231eb625bd/65570/media-object.jpg 200w",
            sizes: "(min-width: 100px) 100px, 100vw"
          },
          sources: [
            {
              srcSet:
                "/storybook/ad44a49cc53abc6342418a231eb625bd/977e6/media-object.webp 25w,\n/storybook/ad44a49cc53abc6342418a231eb625bd/6dec7/media-object.webp 50w,\n/storybook/ad44a49cc53abc6342418a231eb625bd/a7d2e/media-object.webp 100w,\n/storybook/ad44a49cc53abc6342418a231eb625bd/91284/media-object.webp 200w",
              type: "image/webp",
              sizes: "(min-width: 100px) 100px, 100vw"
            }
          ]
        },
        width: 94,
        height: 66
      }
    },
    category: {
      label: "Insights",
      url: "/insights"
    }
  },
  darkTheme: {
    theme: "dark"
  },
  lightTheme: {
    theme: "light"
  }
}
