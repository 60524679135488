import React from "react"

export default function index(props) {
  return (
    <fieldset>
      <legend>{props.__typename}</legend>
      {props.children}
    </fieldset>
  )
}
