// extracted by mini-css-extract-plugin
export var animated_graphic_content = "animated_graphic_content-dKJ";
export var animated_graphic_heading = "animated_graphic_heading-dkM";
export var animated_graphic_heading_arrow = "animated_graphic_heading_arrow-4JE";
export var animated_graphic_heading_arrow_vertical = "animated_graphic_heading_arrow_vertical-8DI";
export var animated_graphic_heading_vertical = "animated_graphic_heading_vertical-1x1";
export var animated_graphic_item = "animated_graphic_item-zwf";
export var animated_graphic_item_0 = "animated_graphic_item_0-6aI";
export var animated_graphic_item_1 = "animated_graphic_item_1-gNO";
export var animated_graphic_item_2 = "animated_graphic_item_2-QIf";
export var animated_graphic_item_3 = "animated_graphic_item_3-eXa";
export var animated_graphic_item_button = "animated_graphic_item_button-hRU";
export var animated_graphic_item_content = "animated_graphic_item_content-cIl";
export var animated_graphic_item_content_fade = "animated_graphic_item_content_fade-r8z";
export var animated_graphic_item_header = "animated_graphic_item_header-vCZ";
export var animated_graphic_item_heading = "animated_graphic_item_heading-nev";
export var animated_graphic_item_list = "animated_graphic_item_list-4RO";
export var animated_graphic_items = "animated_graphic_items-oFC";
export var close_button = "close_button-7eQ";
export var collaborative_cloud_animated_graphic = "collaborative_cloud_animated_graphic-ijO";
export var collaborative_cloud_animated_graphic_container = "collaborative_cloud_animated_graphic_container-QHX";
export var collaborative_cloud_heading = "collaborative_cloud_heading-O4-";
export var contains_close_button = "contains_close_button-W9Y";
export var heading_bottom_column = "heading_bottom_column-s70";
export var heading_left_column = "heading_left_column-3J7";
export var is_closed = "is_closed-13I";
export var is_even = "is_even-tgd";
export var is_not_active = "is_not_active-DvF";
export var is_odd = "is_odd-c-s";
export var is_showing = "is_showing-cab";