import React from "react"

import { categoryPath } from "@root/src/utils"

import Button from "@components/button"
import Block from "@components/block"
import Card from "@components/card"
import Container from "@components/container"
import Content from "@src/components/content"
import FilterSort, { useFilterSort } from "@components/filter-sort"
import Layout from "@src/layout"
import Grid from "@components/grid"
import Pagination, { usePagination } from "@components/pagination"

const SmeAuthorDetail = ({ data }) => {
  const { author, contactForm, jumbotron } = data
  const { nodes: posts } = data.posts
  const { nodes: categories } = data.categories

  // ----------------------------- | Pagination & Filtering

  const per = 12
  const [gridRef, count, setCount, page, handlePage] = usePagination(0, 1)
  const [filtered, handleFilter, handleSort] = useFilterSort(posts, per, handlePage, setCount)

  return (
    <Layout title={author?.name} theme="dark" customLayout>
      <Block {...jumbotron} theme="sme" sme={author} />

      <Container padding="medium">
        <h2>About {author?.name.split(" ").shift()}</h2>
        <Content body={author?.bio} columns margin_bottom={36} />
        {author?.social_linkedin && (
          <Button size="small" to={author?.social_linkedin}>
            Connect on LinkedIn
          </Button>
        )}
      </Container>

      {filtered.length && (
        <>
          <span ref={gridRef} />
          <Container padding="small">
            <Grid centered>
              {author?.smeHeadline ? (
                <div
                  dangerouslySetInnerHTML={{ __html: author?.smeHeadline.childMarkdownRemark.html }}
                />
              ) : (
                <div>
                  <h2>Hear from {author?.name}</h2>
                </div>
              )}
            </Grid>

            <FilterSort
              filterBy={categories}
              filterLabel={"Filter By Category"}
              sort
              handleFilter={handleFilter}
              handleSort={handleSort}
            />

            <Grid layout="3">
              {filtered &&
                filtered[page - 1]?.map((post, idx) => (
                  <Card
                    key={post.slug}
                    body={post.body}
                    category={{ label: post.category?.title, url: categoryPath(post.category) }}
                    currentIndex={idx}
                    duration={post.duration}
                    heading={post.title}
                    image_media={post.image_media_card}
                    image={post.image}
                    theme="media"
                    type={{
                      label: post?.resource_type?.title,
                      url: categoryPath(post?.resource_type)
                    }}
                    url={post.post_path}
                    wordCount={post.wordCount}
                  />
                ))}
              {filtered.length % 3 !== 0 && <div></div>}
            </Grid>
            {count > per && (
              <Pagination
                currentPage={page}
                itemsPerPage={per}
                totalItems={count}
                onChange={handlePage}
              />
            )}
          </Container>
        </>
      )}
      <Block {...contactForm} />
    </Layout>
  )
}

export default SmeAuthorDetail
