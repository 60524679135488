import { graphql } from "gatsby"
import SmeIndex from "./template"

export const query = graphql`
  query SmeLandingPageQuery {
    jumbotron: contentfulJumbotron(slug: { eq: "sme-index-jumbotron" }) {
      ...Jumbotron
    }
    smes: allContentfulAuthor(
      filter: { sme: { eq: true } }
      sort: { post: { publishDate: DESC } }
    ) {
      nodes {
        __typename
        bio {
          childMarkdownRemark {
            excerpt(pruneLength: 220)
          }
        }
        category {
          title
          slug
        }
        id
        image {
          gatsbyImageData(width: 140, height: 140)
          title
        }
        name
        slug
        sme
      }
    }
    categories: allContentfulCategory(
      sort: { title: ASC }
      filter: { author: { elemMatch: { sme: { eq: true } } } }
    ) {
      nodes {
        title
      }
    }
    contactForm: contentfulContainer(slug: { eq: "contact-form-pr" }) {
      __typename
      contentful_id
      ...Container
      blocks {
        __typename
        ...Block
        ... on ContentfulRow {
          ...Row
          blocks {
            __typename
            ... on ContentfulColumn {
              __typename
              ...Column
              blocks {
                __typename
                ... on ContentfulSnippet {
                  ...Snippet
                }
                ... on ContentfulForm {
                  ...Form
                }
              }
            }
          }
        }
      }
    }
  }
`

export default SmeIndex
