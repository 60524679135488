const baseUrl = process.env.URL ? process.env.URL.replace(/\/$/gm, "") : ""
const storybookUrl = `${baseUrl}/storybook-static`

const items = [
  {
    label: "Storybook - Docs Active",
    link: `${storybookUrl}/iframe.html?id=components-side-navigation--props&viewMode=docs`
  },
  {
    label: "Storybook - Canvas Active",
    link: `${storybookUrl}/iframe.html?id=components-side-navigation--props&viewMode=story`
  },
  {
    label: "Storybook Local - Docs Active",
    link: `${baseUrl}/iframe.html?id=components-side-navigation--props&viewMode=docs`
  },
  {
    label: "Storybook Local - Canvas Active",
    link: `${baseUrl}/iframe.html?id=components-side-navigation--props&viewMode=story`
  },
  {
    label: "Advertising basics",
    link: "/faqs/advertising-basics"
  },
  {
    label: "Sponsored products",
    link: "/faqs/sponsored-products"
  },
  {
    label: "Sponsored brands",
    link: "/faqs/sponsored-brands"
  },
  {
    label: "Sponsored display",
    link: "/faqs/sponsored-display"
  },
  {
    label: "Stores",
    link: "/faqs/stores"
  },
  {
    label: "Display ads",
    link: "/faqs/display-ads"
  },
  {
    label: "Video ads",
    link: "/faqs/video-ads"
  },
  {
    label: "Audio ads",
    link: "/faqs/audio-ads"
  },
  {
    label: "KPM DSP",
    link: "/faqs/kpm-dsp"
  },
  {
    label: "Custom advertising solutions",
    link: "/faqs/custom-advertising-solutions"
  },
  {
    label: "Reporting basics",
    link: "/faqs/reporting-basics"
  },
  {
    label: "Advertising partners",
    link: "/faqs/advertising-partners"
  }
]

export default {
  props: {
    items
  }
}
