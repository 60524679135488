import { graphql } from "gatsby"
import SmeAuthorDetail from "./template"

export const query = graphql`
  query SmeAuthorDetail($id: String = "") {
    jumbotron: contentfulJumbotron(slug: { eq: "sme-detail-jumbotron" }) {
      __typename
      id
      background_image {
        gatsbyImageData(quality: 80)
        title
      }
    }
    author: contentfulAuthor(id: { eq: $id }, sme: { eq: true }) {
      __typename
      bio {
        childMarkdownRemark {
          html
        }
      }
      id
      image {
        gatsbyImageData(width: 362, height: 362)
      }
      name
      post {
        ...PostAttributes
      }
      sme
      smeHeadline {
        childMarkdownRemark {
          html
        }
      }
      social_linkedin
      title
    }
    posts: allContentfulPost(
      sort: { publishDate: DESC }
      filter: { authors: { elemMatch: { id: { eq: $id } } } }
    ) {
      nodes {
        ...PostAttributes
      }
    }
    categories: allContentfulCategory(
      sort: { post: { category: { title: ASC } } }
      filter: { post: { elemMatch: { author: { id: { eq: $id } } } } }
    ) {
      nodes {
        title
      }
    }
    contactForm: contentfulContainer(slug: { eq: "contact-form-pr" }) {
      ...Container
      blocks {
        __typename
        ...Block
        ... on ContentfulRow {
          ...Row
          blocks {
            __typename
            ... on ContentfulColumn {
              __typename
              ...Column
              blocks {
                __typename
                ... on ContentfulSnippet {
                  ...Snippet
                }
                ... on ContentfulForm {
                  ...Form
                }
              }
            }
          }
        }
      }
    }
  }
`

export default SmeAuthorDetail
