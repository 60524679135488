import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

// ------------------------------------------ #

import { flourish, flourish_line, flourish_dot, start_animation } from "./styles.module.scss"

// ------------------------------------------ #

const Flourish = ({ animate }) => {
  return (
    <div
      className={classNames(flourish, {
        [start_animation]: animate
      })}
    >
      <div className={flourish_line}></div>
      <div className={flourish_line}></div>
      <div className={flourish_line}></div>
      <div className={flourish_line}></div>
      <div className={flourish_line}></div>
      <div className={flourish_line}></div>
      <div className={flourish_line}>
        <div className={flourish_dot}></div>
      </div>
      <div className={flourish_line}></div>
      <div className={flourish_line}></div>
    </div>
  )
}

// ------------------------------------------ #

Flourish.propTypes = {
  animate: PropTypes.bool
}

export default Flourish
