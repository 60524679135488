export default {
  props: {
    title: "Cras mattis consectetur purus sit amet fermentum.",
    button: {
      label: "Consectetur Ornare",
      url: "#"
    },
    image: {
      gatsbyImageData: {
        layout: "constrained",
        backgroundColor: "#080808",
        images: {
          fallback: {
            src: "/storybook/f3324dad52a1a9348d4ba6eaa5bc711f/57085/logo-8451@3x.png",
            srcSet:
              "/storybook/f3324dad52a1a9348d4ba6eaa5bc711f/090be/logo-8451@3x.png 63w,\n/storybook/f3324dad52a1a9348d4ba6eaa5bc711f/aff2e/logo-8451@3x.png 126w,\n/storybook/f3324dad52a1a9348d4ba6eaa5bc711f/57085/logo-8451@3x.png 252w",
            sizes: "(min-width: 252px) 252px, 100vw"
          },
          sources: [
            {
              srcSet:
                "/storybook/f3324dad52a1a9348d4ba6eaa5bc711f/7d8e5/logo-8451%403x.webp 63w,\n/storybook/f3324dad52a1a9348d4ba6eaa5bc711f/88ba8/logo-8451%403x.webp 126w,\n/storybook/f3324dad52a1a9348d4ba6eaa5bc711f/0ad1a/logo-8451%403x.webp 252w",
              type: "image/webp",
              sizes: "(min-width: 252px) 252px, 100vw"
            }
          ]
        },
        width: 300,
        height: 107
      }
    }
  }
}
