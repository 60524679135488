export default {
  default: {
    links: [
      {
        heading: "Solutions",
        links: [
          {
            url: "#",
            label: "Insights",
            children: [
              { url: "#", label: "84.51° Stratun" },
              {
                url: "#",
                label: "Research & Analytics",
                children: [
                  { url: "#", label: "Consumer Research" },
                  { url: "#", label: "Custom Insights" }
                ]
              }
            ]
          },
          {
            url: "#",
            label: "Loyalty Marketing",
            children: [{ url: "#", label: "Best Customer Communication" }]
          },
          {
            url: "#",
            label: "Media",
            children: [{ url: "#", label: "KPM" }]
          }
        ]
      },
      {
        heading: "Industries",
        links: [
          { url: "#", label: "Consumer Goods" },
          { url: "#", label: "Health" },
          { url: "#", label: "Trade Associations" },
          { url: "#", label: "Ad Agencies" }
        ]
      },
      {
        heading: "Knowledge Hub",
        links: [
          {
            url: "#",
            label: "Data Science",
            children: [
              { url: "#", label: "Category 1" },
              { url: "#", label: "Category 2" }
            ]
          },
          { url: "#", label: "Case Studies" },
          { url: "#", label: "Uplow'd Podcast" },
          { url: "#", label: "84.51 on Medium" }
        ]
      },
      {
        heading: "Careers",
        links: [
          { url: "#", label: "Consulting" },
          { url: "#", label: "Data Science" },
          { url: "#", label: "Consumer Research" },
          { url: "#", label: "Engineering" },
          { url: "#", label: "Infrastructure & Cloud Technology" },
          { url: "#", label: "Product & Design" },
          { url: "#", label: "Science" },
          { url: "#", label: "Early Career Paths" },
          { url: "#", label: "Open Positions" }
        ]
      },
      {
        heading: "About",
        links: [
          { url: "#", label: "Who We Are" },
          { url: "#", label: "Diversity Inclusion" },
          { url: "#", label: "Awards & Recognition" },
          { url: "#", label: "Press" },
          { url: "#", label: "PearlRock Partners" }
        ]
      }
    ]
  }
}
