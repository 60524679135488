import { useState, useRef } from "react"

const usePagination = (listLength, currentPage) => {
  const gridRef = useRef(null)
  const [count, setCount] = useState(listLength)
  const [page, setPage] = useState(currentPage || 1)
  const [firstLoad, setFirstLoad] = useState(true)

  const handlePage = (n) => {
    setPage(n)
    if (firstLoad) {
      setFirstLoad(false)
    } else {
      gridRef.current.scrollIntoView({ behavior: "smooth" })
    }
  }

  return [gridRef, count, setCount, page, handlePage]
}

export default usePagination
