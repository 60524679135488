export default {
  Default: {
    references: [
      {
        __typename: "ContentfulImage",
        src: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    "//images.ctfassets.net/c23k8ps4z1xm/6M2lnVb8BZdxrwPVmUxWhw/c629dc8102fb3f3e7b0da28f919d5c15/8451_corporate_logos_for_site_240x120_04_Conagra-01.png?w=60&h=30&q=50&fm=webp 60w,\n//images.ctfassets.net/c23k8ps4z1xm/6M2lnVb8BZdxrwPVmUxWhw/c629dc8102fb3f3e7b0da28f919d5c15/8451_corporate_logos_for_site_240x120_04_Conagra-01.png?w=120&h=60&q=50&fm=webp 120w,\n//images.ctfassets.net/c23k8ps4z1xm/6M2lnVb8BZdxrwPVmUxWhw/c629dc8102fb3f3e7b0da28f919d5c15/8451_corporate_logos_for_site_240x120_04_Conagra-01.png?w=240&h=120&q=50&fm=webp 240w",
                  sizes: "(min-width: 240px) 240px, 100vw",
                  type: "image/webp"
                }
              ],
              fallback: {
                src: "//images.ctfassets.net/c23k8ps4z1xm/6M2lnVb8BZdxrwPVmUxWhw/c629dc8102fb3f3e7b0da28f919d5c15/8451_corporate_logos_for_site_240x120_04_Conagra-01.png?w=240&h=120&q=50&fm=png",
                srcSet:
                  "//images.ctfassets.net/c23k8ps4z1xm/6M2lnVb8BZdxrwPVmUxWhw/c629dc8102fb3f3e7b0da28f919d5c15/8451_corporate_logos_for_site_240x120_04_Conagra-01.png?w=60&h=30&q=50&fm=png 60w,\n//images.ctfassets.net/c23k8ps4z1xm/6M2lnVb8BZdxrwPVmUxWhw/c629dc8102fb3f3e7b0da28f919d5c15/8451_corporate_logos_for_site_240x120_04_Conagra-01.png?w=120&h=60&q=50&fm=png 120w,\n//images.ctfassets.net/c23k8ps4z1xm/6M2lnVb8BZdxrwPVmUxWhw/c629dc8102fb3f3e7b0da28f919d5c15/8451_corporate_logos_for_site_240x120_04_Conagra-01.png?w=240&h=120&q=50&fm=png 240w",
                sizes: "(min-width: 240px) 240px, 100vw"
              }
            },
            layout: "constrained",
            backgroundColor: "#f8f8f8",
            width: 240,
            height: 120
          }
        }
      },
      {
        __typename: "ContentfulImage",
        src: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    "//images.ctfassets.net/c23k8ps4z1xm/74zKQFPqGgHWKUOWW4JAw8/c3986fc42d25d5e48b5beb007e724ada/8451_corporate_logos_for_site_240x120_01_KraftHeinz-01.png?w=60&h=30&q=50&fm=webp 60w,\n//images.ctfassets.net/c23k8ps4z1xm/74zKQFPqGgHWKUOWW4JAw8/c3986fc42d25d5e48b5beb007e724ada/8451_corporate_logos_for_site_240x120_01_KraftHeinz-01.png?w=120&h=60&q=50&fm=webp 120w,\n//images.ctfassets.net/c23k8ps4z1xm/74zKQFPqGgHWKUOWW4JAw8/c3986fc42d25d5e48b5beb007e724ada/8451_corporate_logos_for_site_240x120_01_KraftHeinz-01.png?w=240&h=120&q=50&fm=webp 240w",
                  sizes: "(min-width: 240px) 240px, 100vw",
                  type: "image/webp"
                }
              ],
              fallback: {
                src: "//images.ctfassets.net/c23k8ps4z1xm/74zKQFPqGgHWKUOWW4JAw8/c3986fc42d25d5e48b5beb007e724ada/8451_corporate_logos_for_site_240x120_01_KraftHeinz-01.png?w=240&h=120&q=50&fm=png",
                srcSet:
                  "//images.ctfassets.net/c23k8ps4z1xm/74zKQFPqGgHWKUOWW4JAw8/c3986fc42d25d5e48b5beb007e724ada/8451_corporate_logos_for_site_240x120_01_KraftHeinz-01.png?w=60&h=30&q=50&fm=png 60w,\n//images.ctfassets.net/c23k8ps4z1xm/74zKQFPqGgHWKUOWW4JAw8/c3986fc42d25d5e48b5beb007e724ada/8451_corporate_logos_for_site_240x120_01_KraftHeinz-01.png?w=120&h=60&q=50&fm=png 120w,\n//images.ctfassets.net/c23k8ps4z1xm/74zKQFPqGgHWKUOWW4JAw8/c3986fc42d25d5e48b5beb007e724ada/8451_corporate_logos_for_site_240x120_01_KraftHeinz-01.png?w=240&h=120&q=50&fm=png 240w",
                sizes: "(min-width: 240px) 240px, 100vw"
              }
            },
            layout: "constrained",
            backgroundColor: "#f8f8f8",
            width: 240,
            height: 120
          }
        }
      },
      {
        __typename: "ContentfulImage",
        src: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    "//images.ctfassets.net/c23k8ps4z1xm/9sOVwmXSStBWW847GpSqz/286cbfc28ed6b5d09ff0d477a18c7223/8451_corporate_logos_for_site_240x120_03_Pepsico-01.png?w=60&h=30&q=50&fm=webp 60w,\n//images.ctfassets.net/c23k8ps4z1xm/9sOVwmXSStBWW847GpSqz/286cbfc28ed6b5d09ff0d477a18c7223/8451_corporate_logos_for_site_240x120_03_Pepsico-01.png?w=120&h=60&q=50&fm=webp 120w,\n//images.ctfassets.net/c23k8ps4z1xm/9sOVwmXSStBWW847GpSqz/286cbfc28ed6b5d09ff0d477a18c7223/8451_corporate_logos_for_site_240x120_03_Pepsico-01.png?w=240&h=120&q=50&fm=webp 240w",
                  sizes: "(min-width: 240px) 240px, 100vw",
                  type: "image/webp"
                }
              ],
              fallback: {
                src: "//images.ctfassets.net/c23k8ps4z1xm/9sOVwmXSStBWW847GpSqz/286cbfc28ed6b5d09ff0d477a18c7223/8451_corporate_logos_for_site_240x120_03_Pepsico-01.png?w=240&h=120&q=50&fm=png",
                srcSet:
                  "//images.ctfassets.net/c23k8ps4z1xm/9sOVwmXSStBWW847GpSqz/286cbfc28ed6b5d09ff0d477a18c7223/8451_corporate_logos_for_site_240x120_03_Pepsico-01.png?w=60&h=30&q=50&fm=png 60w,\n//images.ctfassets.net/c23k8ps4z1xm/9sOVwmXSStBWW847GpSqz/286cbfc28ed6b5d09ff0d477a18c7223/8451_corporate_logos_for_site_240x120_03_Pepsico-01.png?w=120&h=60&q=50&fm=png 120w,\n//images.ctfassets.net/c23k8ps4z1xm/9sOVwmXSStBWW847GpSqz/286cbfc28ed6b5d09ff0d477a18c7223/8451_corporate_logos_for_site_240x120_03_Pepsico-01.png?w=240&h=120&q=50&fm=png 240w",
                sizes: "(min-width: 240px) 240px, 100vw"
              }
            },
            layout: "constrained",
            backgroundColor: "#f8f8f8",
            width: 240,
            height: 120
          }
        }
      },
      {
        __typename: "ContentfulImage",
        src: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    "//images.ctfassets.net/c23k8ps4z1xm/432QH2K7UWVFWzUKugh1VM/27bae849d518802e695097e1ecc5c56d/8451_corporate_logos_for_site_240x120_02_GM-01.png?w=60&h=30&q=50&fm=webp 60w,\n//images.ctfassets.net/c23k8ps4z1xm/432QH2K7UWVFWzUKugh1VM/27bae849d518802e695097e1ecc5c56d/8451_corporate_logos_for_site_240x120_02_GM-01.png?w=120&h=60&q=50&fm=webp 120w,\n//images.ctfassets.net/c23k8ps4z1xm/432QH2K7UWVFWzUKugh1VM/27bae849d518802e695097e1ecc5c56d/8451_corporate_logos_for_site_240x120_02_GM-01.png?w=240&h=120&q=50&fm=webp 240w",
                  sizes: "(min-width: 240px) 240px, 100vw",
                  type: "image/webp"
                }
              ],
              fallback: {
                src: "//images.ctfassets.net/c23k8ps4z1xm/432QH2K7UWVFWzUKugh1VM/27bae849d518802e695097e1ecc5c56d/8451_corporate_logos_for_site_240x120_02_GM-01.png?w=240&h=120&q=50&fm=png",
                srcSet:
                  "//images.ctfassets.net/c23k8ps4z1xm/432QH2K7UWVFWzUKugh1VM/27bae849d518802e695097e1ecc5c56d/8451_corporate_logos_for_site_240x120_02_GM-01.png?w=60&h=30&q=50&fm=png 60w,\n//images.ctfassets.net/c23k8ps4z1xm/432QH2K7UWVFWzUKugh1VM/27bae849d518802e695097e1ecc5c56d/8451_corporate_logos_for_site_240x120_02_GM-01.png?w=120&h=60&q=50&fm=png 120w,\n//images.ctfassets.net/c23k8ps4z1xm/432QH2K7UWVFWzUKugh1VM/27bae849d518802e695097e1ecc5c56d/8451_corporate_logos_for_site_240x120_02_GM-01.png?w=240&h=120&q=50&fm=png 240w",
                sizes: "(min-width: 240px) 240px, 100vw"
              }
            },
            layout: "constrained",
            backgroundColor: "#f8f8f8",
            width: 240,
            height: 120
          }
        }
      },
      {
        __typename: "ContentfulImage",
        src: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    "//images.ctfassets.net/c23k8ps4z1xm/5K7B9adEeJ1WQ1Q8fWn28q/82230e5c56813d33fd1180c7310b81e9/8451_corporate_logos_for_site_240x120_05_P_G-01.png?w=60&h=30&q=50&fm=webp 60w,\n//images.ctfassets.net/c23k8ps4z1xm/5K7B9adEeJ1WQ1Q8fWn28q/82230e5c56813d33fd1180c7310b81e9/8451_corporate_logos_for_site_240x120_05_P_G-01.png?w=120&h=60&q=50&fm=webp 120w,\n//images.ctfassets.net/c23k8ps4z1xm/5K7B9adEeJ1WQ1Q8fWn28q/82230e5c56813d33fd1180c7310b81e9/8451_corporate_logos_for_site_240x120_05_P_G-01.png?w=240&h=120&q=50&fm=webp 240w",
                  sizes: "(min-width: 240px) 240px, 100vw",
                  type: "image/webp"
                }
              ],
              fallback: {
                src: "//images.ctfassets.net/c23k8ps4z1xm/5K7B9adEeJ1WQ1Q8fWn28q/82230e5c56813d33fd1180c7310b81e9/8451_corporate_logos_for_site_240x120_05_P_G-01.png?w=240&h=120&q=50&fm=png",
                srcSet:
                  "//images.ctfassets.net/c23k8ps4z1xm/5K7B9adEeJ1WQ1Q8fWn28q/82230e5c56813d33fd1180c7310b81e9/8451_corporate_logos_for_site_240x120_05_P_G-01.png?w=60&h=30&q=50&fm=png 60w,\n//images.ctfassets.net/c23k8ps4z1xm/5K7B9adEeJ1WQ1Q8fWn28q/82230e5c56813d33fd1180c7310b81e9/8451_corporate_logos_for_site_240x120_05_P_G-01.png?w=120&h=60&q=50&fm=png 120w,\n//images.ctfassets.net/c23k8ps4z1xm/5K7B9adEeJ1WQ1Q8fWn28q/82230e5c56813d33fd1180c7310b81e9/8451_corporate_logos_for_site_240x120_05_P_G-01.png?w=240&h=120&q=50&fm=png 240w",
                sizes: "(min-width: 240px) 240px, 100vw"
              }
            },
            layout: "constrained",
            backgroundColor: "#f8f8f8",
            width: 240,
            height: 120
          }
        }
      },
      {
        __typename: "ContentfulImage",
        src: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    "//images.ctfassets.net/c23k8ps4z1xm/5m6LbemJUtXXrzoNRHWdDJ/23de7ce6c232796eee9bab67bab78f32/8451_corporate_logos_for_site_240x120_08_PBFA-01.png?w=60&h=30&q=50&fm=webp 60w,\n//images.ctfassets.net/c23k8ps4z1xm/5m6LbemJUtXXrzoNRHWdDJ/23de7ce6c232796eee9bab67bab78f32/8451_corporate_logos_for_site_240x120_08_PBFA-01.png?w=120&h=60&q=50&fm=webp 120w,\n//images.ctfassets.net/c23k8ps4z1xm/5m6LbemJUtXXrzoNRHWdDJ/23de7ce6c232796eee9bab67bab78f32/8451_corporate_logos_for_site_240x120_08_PBFA-01.png?w=240&h=120&q=50&fm=webp 240w",
                  sizes: "(min-width: 240px) 240px, 100vw",
                  type: "image/webp"
                }
              ],
              fallback: {
                src: "//images.ctfassets.net/c23k8ps4z1xm/5m6LbemJUtXXrzoNRHWdDJ/23de7ce6c232796eee9bab67bab78f32/8451_corporate_logos_for_site_240x120_08_PBFA-01.png?w=240&h=120&q=50&fm=png",
                srcSet:
                  "//images.ctfassets.net/c23k8ps4z1xm/5m6LbemJUtXXrzoNRHWdDJ/23de7ce6c232796eee9bab67bab78f32/8451_corporate_logos_for_site_240x120_08_PBFA-01.png?w=60&h=30&q=50&fm=png 60w,\n//images.ctfassets.net/c23k8ps4z1xm/5m6LbemJUtXXrzoNRHWdDJ/23de7ce6c232796eee9bab67bab78f32/8451_corporate_logos_for_site_240x120_08_PBFA-01.png?w=120&h=60&q=50&fm=png 120w,\n//images.ctfassets.net/c23k8ps4z1xm/5m6LbemJUtXXrzoNRHWdDJ/23de7ce6c232796eee9bab67bab78f32/8451_corporate_logos_for_site_240x120_08_PBFA-01.png?w=240&h=120&q=50&fm=png 240w",
                sizes: "(min-width: 240px) 240px, 100vw"
              }
            },
            layout: "constrained",
            backgroundColor: "#f8f8f8",
            width: 240,
            height: 120
          }
        }
      }
    ]
  }
}
