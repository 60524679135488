import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import Animate from "@components/animate"
import SVG from "@components/svg"

// -------------------------------------------------------- | styles

import {
  content_alignment_bottom,
  content_alignment_center,
  content_alignment_stretch,
  content_alignment_top,
  grid,
  gutter_0,
  gutter_16,
  gutter_33,
  gutter_58,
  is_centered,
  layout_is_always_half,
  layout_is_always_third,
  layout_is_always_fourth,
  layout_is_fifth,
  layout_is_forty_sixty_with_spark,
  layout_is_forty_sixty,
  layout_is_fourth,
  layout_is_half,
  layout_is_left_column_raised_with_shadow,
  layout_is_seventy_thirty_ish,
  layout_is_sixty_forty,
  layout_is_small_left,
  layout_is_third,
  margin_bottom_1,
  margin_bottom_2,
  margin_bottom_3,
  margin_bottom_4,
  margin_bottom_5,
  margin_bottom_6,
  margin_bottom_7,
  margin_bottom_8,
  shadow_section_row_1,
  shadow_section_row_2,
  spark_image,
  layout_stack
  // smart_grid_cards
} from "./styles.module.scss"

const alignmentOptions = {
  bottom: content_alignment_bottom,
  center: content_alignment_center,
  stretch: content_alignment_stretch,
  top: content_alignment_top
}

const gutterOptions = {
  none: gutter_0,
  small: gutter_16,
  medium: gutter_33,
  large: gutter_58
}

const layoutOptions = {
  smart_grid: "smart-grid",
  // smart_grid_cards: smart_grid_cards,
  full_width: "full-width",
  2: layout_is_half,
  3: layout_is_third,
  4: layout_is_fourth,
  5: layout_is_fifth,
  6040: layout_is_sixty_forty,
  "4060_with_spark": layout_is_forty_sixty_with_spark,
  4060: layout_is_forty_sixty,
  half: layout_is_always_half,
  third: layout_is_always_third,
  small_left: layout_is_small_left,
  always_half: layout_is_always_half,
  always_third: layout_is_always_third,
  always_fourth: layout_is_always_fourth,
  layout_stack: layout_stack
}

const marginBottomOptions = {
  0: null,
  6: margin_bottom_1,
  12: margin_bottom_2,
  24: margin_bottom_3,
  36: margin_bottom_4,
  48: margin_bottom_5,
  64: margin_bottom_6,
  100: margin_bottom_7,
  120: margin_bottom_8
}

// -------------------------------------------------------- | component

const Grid = ({
  alignment,
  carouselTheme,
  centered,
  children,
  className,
  containerTheme,
  gutter,
  layout,
  margin_bottom,
  rowIndex
}) => {
  if (!layout || layout === "smart-grid" || layout === "smart_grid_cards") {
    layout = (children || []).length
  }

  const contactCarousel = carouselTheme === "contact"
  const shadowSection = containerTheme === "white_section_with_shadow"
  const shadowSectionRow1 = shadowSection && rowIndex === 0
  const shadowSectionRow2 = shadowSection && rowIndex === 1
  const leftColumnRaisedShadow = containerTheme === "left_column_raised_with_shadow"

  const classes = classNames(grid, {
    [alignmentOptions[alignment]]: alignmentOptions[alignment],
    [gutterOptions[gutter]]: gutterOptions[gutter],
    [gutter_58]: shadowSectionRow1,
    [shadow_section_row_1]: shadowSectionRow1,
    [shadow_section_row_2]: shadowSectionRow2,
    [layoutOptions[layout]]: !shadowSection && layoutOptions[layout],
    [layout_is_seventy_thirty_ish]: shadowSectionRow1 || contactCarousel,
    [layout_is_forty_sixty_with_spark]: shadowSectionRow2,
    [layout_is_left_column_raised_with_shadow]: leftColumnRaisedShadow,
    [layoutOptions[layout]]: layoutOptions[layout],
    [marginBottomOptions[margin_bottom]]: marginBottomOptions[margin_bottom],
    [is_centered]: centered,
    [className]: className
  })

  // -------------------------------------------------------- | Animation

  let animate
  let delay

  if (centered) {
    animate = "fade_in_up"
    delay = 500
  }

  // --------------------------------------------------------

  const multipleOf2 = (n) => {
    if (n % 2 == 0) return true

    return false
  }

  const fiveColumns = (n) => {
    if (n === 5) return true

    return false
  }

  return (
    <Animate className={classes} animate={animate} delay={delay}>
      {shadowSectionRow2 && (
        <Animate animate="fade_in" className={spark_image}>
          <SVG name="expression-gray" />
        </Animate>
      )}
      {children}

      {layout === "always_third" && multipleOf2(children.length) && <div />}
      {children && fiveColumns(children.length) && <div />}
    </Animate>
  )
}

Grid.propTypes = {
  /**
   * Specifies how the content should be aligned with it's container
   */
  alignment: PropTypes.oneOf(Object.keys(alignmentOptions)),
  /**
   * Specifies if the content should be centered in the row.
   * NOTE: When centered the row will not be as wide and will be padded on both sides
   */
  centered: PropTypes.bool,
  /**
   * A CSS class adding to the wrapping element.
   */
  className: PropTypes.string,
  /**
   * Specifies anything between the opening and closing tag.
   */
  children: PropTypes.node,
  /**
   * Get the value of the container components theme
   */
  containerTheme: PropTypes.string,
  carouselTheme: PropTypes.string,
  /**
   * Contains the current row's index
   */
  rowIndex: PropTypes.number,
  /**
   * Specifies the size of the grid's gutter.
   */
  gutter: PropTypes.oneOf(Object.keys(gutterOptions)),
  /**
   * Specifies the width of each child in the grid on larger screens
   */
  layout: PropTypes.oneOf(Object.keys(layoutOptions)),
  /**
   * Specifies the number pixels to push subsequent content down the page
   */
  margin_bottom: PropTypes.oneOf(Object.keys(marginBottomOptions))
}

Grid.defaultProps = {
  centered: false,
  gutter: "small"
}

export default Grid
