import React from "react"

import Component from "./component"
import fixtures from "./fixtures"
import usePagination from "./hook"

const Pagination = (props) => <Component {...props} />

export default Pagination

export { Component as component, fixtures, usePagination }
