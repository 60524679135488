import React from "react"
import { component as Card, fixtures as CardFixture } from "@components/card"

export default {
  props: {
    layout: "half",
    gutter: "33",
    children: (
      <>
        <Card {...CardFixture.backgroundImageTheme} />
        <Card {...CardFixture.backgroundImageTheme} />
      </>
    )
  },
  layoutIsHalf: {
    layout: "half",
    gutter: "16",
    children: (
      <>
        <Card {...CardFixture.props} />
        <Card {...CardFixture.props} />
      </>
    )
  },
  layoutIsThird: {
    layout: "third",
    gutter: "16",
    children: (
      <>
        <Card {...CardFixture.backgroundImageTheme} />
        <Card {...CardFixture.backgroundImageTheme} />
        <Card {...CardFixture.backgroundImageTheme} />
      </>
    )
  }
}
