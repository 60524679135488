import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import Animate from "@components/animate"
import Button from "@components/button"

import Job from "./job"
import { dept, job } from "./styles.module.scss"

const Department = ({ name, jobs: allJobs, key, location: currentLocation }) => {
  const initialNum = 3
  const [jobs, setJobs] = useState(allJobs.slice(0, initialNum))
  const [display, setDisplay] = useState("init")
  const cta = `View All ${name} Positions`

  const showAll = () => {
    setJobs(allJobs)
    setDisplay("all")
  }

  useEffect(() => {
    let jobs =
      currentLocation !== "all"
        ? allJobs.filter((job) => job.location.name.includes(currentLocation))
        : allJobs

    setJobs(display !== "all" ? jobs.slice(0, initialNum) : jobs)
  }, [currentLocation])

  return (
    <Animate animate="fade_in" className={dept} key={key}>
      <h3>{name}</h3>
      {jobs.length > 0 &&
        jobs.map((job, i) => <Job key={`job-${i}`} currentLocation={currentLocation} {...job} />)}
      {jobs.length === 0 && <div className={job}>No results found.</div>}

      {display !== "all" && currentLocation === "all" && allJobs.length > initialNum && (
        <footer>
          <Button onClick={showAll.bind(this)} size="medium" theme="outline">
            {cta}
          </Button>
        </footer>
      )}
    </Animate>
  )
}

Department.propTypes = {
  name: PropTypes.string,
  jobs: PropTypes.array,
  key: PropTypes.string,
  location: PropTypes.string
}

export default Department
