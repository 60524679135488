/* eslint-disable no-unused-vars */
import React from "react"
import PropTypes from "prop-types"
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import classNames from "classnames/bind"
import { getUrlAndType, toKebabCase } from "@src/utils"
import Button from "@components/button"
import DataViz from "@components/data-viz"

// ---------------------------------------------------------

import Image from "@components/image"

// ---------------------------------------------------------

import { container, button_container, data_viz, embedded_entry } from "./styles.module.scss"

// ---------------------------------------------------------

const transformButton = (props) => {
  props = Object.fromEntries(Object.entries(props).filter(([_, v]) => v != null))
  return { ...props }
}

const ContentBlock = ({ className, content }) => {
  if (!content) return <div></div>

  const assetMap = new Map()
  const entryMap = new Map()

  if (content.links) {
    for (const asset of (content.links || []).filter((l) => l.__typename === "ContentfulAsset")) {
      assetMap.set(asset.contentful_id, asset)
    }

    for (const entry of (content.links || []).filter((l) => l.__typename !== "ContentfulAsset")) {
      entryMap.set(entry.contentful_id, entry)
    }
  }

  const options = {
    renderMark: {
      [MARKS.CODE]: (text) => {
        return <div dangerouslySetInnerHTML={{ __html: text }}></div>
      }
    },
    renderNode: {
      [INLINES.HYPERLINK]: (node) => {
        let text = ""
        for (var i = 0; i < node?.content?.length; i++) {
          text = text + node?.content[i]?.value
        }

        const { url, isInternal } = getUrlAndType(node?.data?.uri)

        return (
          // eslint-disable-next-line
          <a
            href={url}
            target={isInternal ? "_self" : "_blank"}
            rel={isInternal ? "" : "noreferrer"}
          >
            {text}
          </a>
        )
      },

      [BLOCKS.QUOTE]: (node, children) => {
        return <blockquote>{children}</blockquote>
      },

      [BLOCKS.TABLE_ROW]: (node, children) => {
        if (children.every((node) => node?.nodeType === BLOCKS.TABLE_HEADER_CELL)) {
          // all children are header cells, so we should wrap the row
          // with a <thead /> tag
          return (
            <thead>
              <tr>{children}</tr>
            </thead>
          )
        } else {
          // not a header row, so we can render an ordinary <tr />
          return <tr>{children}</tr>
        }
      },

      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        // find the asset in the assetMap by ID
        const asset = assetMap.get(node?.data?.target?.sys?.id)

        // render the asset accordingly
        return (
          <div className={classNames(embedded_entry)}>
            {asset && (
              <Image
                src={asset}
                alt={asset?.description || asset?.alt}
                height={asset?.height}
                width={asset?.width}
              />
            )}
          </div>
        )
      },
      [BLOCKS.HEADING_1]: (children) => {
        const anchor = toKebabCase(children?.content[0]?.value)
        return <h1 id={anchor}>{children?.content?.value}</h1>
      },
      [BLOCKS.HEADING_2]: (children) => {
        const anchor = toKebabCase(children?.content[0]?.value)
        return <h2 id={anchor}>{children?.content?.value}</h2>
      },
      [BLOCKS.HEADING_3]: (children) => {
        const anchor = toKebabCase(children?.content[0]?.value)
        return <h3 id={anchor}>{children?.content[0]?.value}</h3>
      },
      [BLOCKS.HEADING_4]: (children) => {
        const anchor = toKebabCase(children?.content[0]?.value)
        return <h4 id={anchor}>{children?.content[0]?.value}</h4>
      },
      [BLOCKS.HEADING_5]: (children) => {
        const anchor = toKebabCase(children?.content[0]?.value)
        return <h5 id={anchor}>{children?.content[0]?.value}</h5>
      },
      [BLOCKS.HEADING_6]: (children) => {
        const anchor = toKebabCase(children?.content[0]?.value)
        return <h6 id={anchor}>{children?.content[0]?.value}</h6>
      },
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        const entry = entryMap.get(node?.data?.target?.sys?.id)

        if (entry?.__typename === "ContentfulButton") {
          return (
            <p>
              <Button className={classNames(button_container)} {...transformButton(entry)}>
                {entry.title}
              </Button>
            </p>
          )
        } else if (entry?.__typename === "ContentfulDataVisualization") {
          return (
            <DataViz
              className={classNames(embedded_entry, data_viz)}
              chartType={entry.chart_type}
              caption={entry.caption}
              plugins={entry.plugins}
              dataLabelFormat={entry.dataLabelFormat}
              size={entry.size}
              id={node.data?.target?.sys?.id}
            />
          )
        } else {
          return null
        }
      }
    }
  }

  return (
    <div className={classNames(className, container)}>
      {content?.json && documentToReactComponents(JSON.parse(content.json), options)}
    </div>
  )
}

ContentBlock.propTypes = {
  /**
   * HTML string to be rendered to the page.
   */
  content: PropTypes.object
}

ContentBlock.defaultProps = {}

export default ContentBlock
