import React, { useState } from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import { container, dropdown, dropdown_button, is_open } from "./styles.module.scss"

import Button from "@components/button"
import SVG from "@components/svg"

const TableOfContents = ({ items }) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false)

  const handleScrollTo = (id) => {
    const element = document.getElementById(id)

    if (!element) return

    setMenuIsOpen(false)

    window.scrollTo({
      top: element?.offsetTop - 85,
      behavior: "smooth"
    })
  }

  return (
    <div className={container}>
      <Button
        className={dropdown_button}
        size="small"
        theme="outline"
        onClick={() => setMenuIsOpen(!menuIsOpen)}
      >
        Table of Contents
        <SVG name="angle-down" />
      </Button>
      <div
        className={classnames(dropdown, {
          [is_open]: menuIsOpen
        })}
      >
        {items.map((item) => (
          <div key={item.id}>
            <button onClick={() => handleScrollTo(item.id)}>{item.label}</button>
          </div>
        ))}
      </div>
    </div>
  )
}

TableOfContents.propTypes = {
  /**
   * Array of items to display in the table of contents
   */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      id: PropTypes.string
    })
  )
}

export default TableOfContents
