export default {
  props: {
    image: {
      gatsbyImageData: {
        layout: "constrained",
        backgroundColor: "#f8f8f8",
        images: {
          fallback: {
            src: "/storybook/e8bd6ea0b5f478c3e7420caaca5dffca/80d19/video-stratum-action-copy%403x.jpg",
            srcSet:
              "/storybook/e8bd6ea0b5f478c3e7420caaca5dffca/726e3/video-stratum-action-copy%403x.jpg 45w,\n/storybook/e8bd6ea0b5f478c3e7420caaca5dffca/99500/video-stratum-action-copy%403x.jpg 90w,\n/storybook/e8bd6ea0b5f478c3e7420caaca5dffca/80d19/video-stratum-action-copy%403x.jpg 180w,\n/storybook/e8bd6ea0b5f478c3e7420caaca5dffca/c19ef/video-stratum-action-copy%403x.jpg 360w",
            sizes: "(min-width: 180px) 180px, 100vw"
          },
          sources: [
            {
              srcSet:
                "/storybook/e8bd6ea0b5f478c3e7420caaca5dffca/2b16f/video-stratum-action-copy%403x.webp 45w,\n/storybook/e8bd6ea0b5f478c3e7420caaca5dffca/eed27/video-stratum-action-copy%403x.webp 90w,\n/storybook/e8bd6ea0b5f478c3e7420caaca5dffca/3e3a8/video-stratum-action-copy%403x.webp 180w,\n/storybook/e8bd6ea0b5f478c3e7420caaca5dffca/992d6/video-stratum-action-copy%403x.webp 360w",
              type: "image/webp",
              sizes: "(min-width: 180px) 180px, 100vw"
            }
          ]
        },
        width: 180,
        height: 111
      }
    },
    url: "https://www.youtube.com/watch?v=o0sUM_DPpvA",
    description: "An introduction to 84.51° Stratum"
  }
}
