// extracted by mini-css-extract-plugin
export var animated = "animated-+sI";
export var display_block = "display_block-F0h";
export var display_flex = "display_flex-TJB";
export var fadeIn = "fadeIn-CPt";
export var fadeInBottomLeft = "fadeInBottomLeft-dXf";
export var fadeInBottomRight = "fadeInBottomRight-YCc";
export var fadeInDown = "fadeInDown-6Fn";
export var fadeInLeft = "fadeInLeft-r6X";
export var fadeInRight = "fadeInRight-TJd";
export var fadeInTopLeft = "fadeInTopLeft-sC3";
export var fadeInTopRight = "fadeInTopRight-y67";
export var fadeInUp = "fadeInUp-iY7";
export var no_animation = "no_animation-31e";
export var z_index = "z_index-ay4";