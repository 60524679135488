import React from "react"

import {
  dot_variant_one,
  dot_variant_two,
  dot_variant_three,
  dot_variant_four,
  dot_variant_five,
  dot_variant_six,
  dot_variant_seven,
  dot_variant_eight
} from "./styles.module.scss"

const dotVariants = [
  <div key="1" className={dot_variant_one}>
    <div></div>
    <div></div>
    <div></div>
  </div>,
  <div key="2" className={dot_variant_two}>
    <div></div>
    <div></div>
  </div>,
  <div key="3" className={dot_variant_three}>
    <div></div>
    <div></div>
    <div></div>
  </div>,
  <div key="4" className={dot_variant_four}>
    <div></div>
    <div></div>
    <div></div>
  </div>,
  <div key="5" className={dot_variant_five}>
    <div></div>
    <div></div>
  </div>,
  <div key="6" className={dot_variant_six}>
    <div></div>
    <div></div>
  </div>,
  <div key="7" className={dot_variant_seven}>
    <div></div>
    <div></div>
    <div></div>
  </div>,
  <div key="8" className={dot_variant_eight}>
    <div></div>
    <div></div>
    <div></div>
  </div>
]

export default dotVariants
