/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-onchange */

import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { useQueryParam, StringParam } from "use-query-params"

import Container from "@components/container"
import Department from "@components/snippets/careers/department"
import SVG from "@components/svg"
import Callouts from "./callouts"
const { parseContentfulJSON } = require("@src/utils")

import { content_container, select, select_container } from "./styles.module.scss"

const Careers = ({ config }) => {
  const [_department] = useQueryParam("department", StringParam)
  const [_location] = useQueryParam("location", StringParam)

  const cfg = parseContentfulJSON(config)
  const title = cfg.title || "Current openings"

  const [departments, setDepartments] = useState({})
  const [departmentLabels, setDepartmentLabels] = useState([])
  const [department, setDepartment] = useState("all")
  const [location, setLocation] = useState("all")

  useEffect(() => {
    const deptJobs = jobs().reduce(function (r, a) {
      a.departments.forEach((dept) => {
        const name = dept.name.trim()
        r[name] = r[name] || []
        r[name].push(a)
      })
      return r
    }, Object.create(null))

    setDepartmentLabels(Object.keys(deptJobs).sort((a, b) => a.localeCompare(b)))
    setDepartments(deptJobs)
  }, [])

  useEffect(() => {
    if (Object.keys(departments).includes(_department)) setDepartment(_department)
    if (locations.includes(_location)) setLocation(_location)
    return () => {}
  }, [_department, _location])

  const data = useStaticQuery(graphql`
    query CareersQuery {
      jobs: allGreenhouseJob {
        edges {
          node {
            url
            title
            id
            location {
              name
            }
            departments {
              name
            }
            offices {
              name
              location
            }
          }
        }
      }
    }
  `)

  const jobs = () => {
    return (data.jobs?.edges || []).map((edge) => edge.node)
  }

  const renderDropdown = (label, rows, callback, selected) => {
    const onChange = (e) => {
      callback(e.target.value)
      if (window.dataLayer) {
        window.dataLayer.push({
          event: "select",
          category: "Careers",
          label: `${label} | ${e.target.value}`
        })
      }
    }
    return (
      <div className={select}>
        <select key={label} onChange={onChange}>
          <option value="all">Filter by {label}</option>
          {rows
            .sort((a, b) => a.localeCompare(b))
            .map((row) => (
              <option key={row} selected={selected && selected === row}>
                {row}
              </option>
            ))}
        </select>

        <SVG name="angle-down" />
      </div>
    )
  }

  const getLocations = () => {
    return jobs()
      .map((job) => job.offices?.map((location) => location?.name))
      .flat()
      .filter((value, index, self) => self.indexOf(value) === index)
      .map((location) => location.trim())
  }

  const locations = getLocations()

  return (
    <>
      <Container theme="gray_background" padding="medium">
        <div className={content_container}>
          <header>
            <h2>{title}</h2>
            <div className={select_container}>
              {renderDropdown("department", departmentLabels, setDepartment, department)}
              {renderDropdown("location", locations, setLocation, location)}
            </div>
          </header>
          {departmentLabels.map((name, i) => (
            <div
              key={`department-${i}`}
              style={{ display: name === department || department === "all" ? "block" : "none" }}
            >
              <Department location={location} name={name} jobs={departments[name]} />
            </div>
          ))}
        </div>
      </Container>
      <Callouts blocks={cfg.blocks} />
    </>
  )
}

Careers.propTypes = {
  config: PropTypes.object
}

export default Careers
