/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react"
import _ from "lodash"
import PropTypes from "prop-types"
import classNames from "classnames"
import { useMediaQuery } from "react-responsive"

import Animate from "@components/animate"
import { Icon, iconOptions } from "@components/icon"
import Image from "@components/image"
import { StaticImage } from "gatsby-plugin-image"
import { parseContentfulJSON } from "@src/utils"

import {
  focalHighlight_default_svg,
  focalHighlight_default,
  focalHighlight_fullWidth_left,
  focalHighlight_fullWidth_right,
  focalHighlight_gray_square_half,
  focalHighlight_gray_square,
  focalHighlight_image_focus,
  focalHighlight_image_full_width,
  focalHighlight_image,
  focalHighlight_isHeading,
  focalHighlight_linesImage,
  focalHighlight_list,
  focalHighlight
} from "./styles.module.scss"

const FocalHighlight = (props) => {
  const cfg = parseContentfulJSON(props.config)

  let { altText, focalPoint, image, theme } = props.__typename === "ContentfulSnippet" ? cfg : props

  if (props.__typename === "ContentfulSnippet" && image === undefined) {
    image = (props.references.find((ref) => ref.__typename === "ContentfulImage") || {}).src
  }

  const isMedium = useMediaQuery({ query: "(max-width: 960px)" })
  const isMobile = useMediaQuery({ query: "(max-width: 840px)" })
  const isMobileTiny = useMediaQuery({ query: "(max-width: 480px)" })

  const [focalImageRect, setFocalImageRect] = useState({ height: 0, width: 0 })
  const [focalContainerRect, setFocalContainerRect] = useState({ height: 0, width: 0, x: 0 })
  const [focalUlRect, setFocalUlRect] = useState({ height: 0, width: 0 })
  const [focalLiRectsOne, setFocalLiRectsOne] = useState([])
  const [focalLiRectsTwo, setFocalLiRectsTwo] = useState([])
  const [check, setCheck] = useState(0)

  const containerId = `container-${altText.replace(/[^A-Z0-9]/gi, "_")}`
  const listId1 = `list-1-${altText.replace(/[^A-Z0-9]/gi, "_")}`
  const listId2 = `list-2-${altText.replace(/[^A-Z0-9]/gi, "_")}`

  const handleResize = (repeat = false) => {
    // this function gets and sets the height and width of the focal highlight container, main image, and list items
    // we need these dimensions for the svg math
    const imageRect = document.querySelector(`.${focalHighlight_image} img`)
    if (imageRect) {
      setFocalImageRect({
        height: imageRect.clientHeight,
        width: imageRect.clientWidth
      })
    }

    const containerRect = document.querySelector(`#${containerId}`)
    if (containerRect) {
      const containerRectInfo = containerRect.getBoundingClientRect()
      if (containerRectInfo) {
        setFocalContainerRect({
          height: containerRectInfo.height,
          width: containerRectInfo.width,
          x: containerRectInfo.x
        })
      }
    }

    const liRectsOne = []
    for (let i = 0; i < focalPoint[0].items.length; i++) {
      const liRectOne = document.querySelector(`#${listId1} li:nth-of-type(${i + 1})`)
      if (liRectOne) {
        const liRectOneInfo = liRectOne.getBoundingClientRect()
        if (liRectOneInfo) {
          liRectsOne.push({
            height: liRectOneInfo.height,
            width: liRectOneInfo.width,
            x: liRectOneInfo.x
          })
        }
      }
    }
    setFocalLiRectsOne(liRectsOne)

    // save the ulRect and liRectsTwo info only for the second list
    if (theme === "full-width") {
      const ulRect = document.querySelector(`#${listId2}`)
      if (ulRect) {
        setFocalUlRect({
          height: ulRect.clientHeight,
          width: ulRect.clientWidth
        })
      }

      const liRectsTwo = []
      for (let i = 0; i < focalPoint[1].items.length; i++) {
        const liRectTwo = document.querySelector(`#${listId2} li:nth-of-type(${i + 1})`)
        if (liRectTwo) {
          const liRectTwoInfo = liRectTwo.getBoundingClientRect()
          if (liRectTwoInfo) {
            liRectsTwo.push({
              height: liRectTwoInfo.height,
              width: liRectTwoInfo.width,
              x: liRectTwoInfo.x
            })
          }
        }
      }
      setFocalLiRectsTwo(liRectsTwo)
    }

    // only doing this on init
    if (repeat) {
      const timer = setTimeout(() => {
        handleResize()
      }, 300)
      return () => clearTimeout(timer)
    }
  }

  useEffect(() => {
    if (check === 0) {
      // we are forcing the DOM to load completely
      const timer = setTimeout(() => {
        setCheck(check + 1)
      }, 300)
      return () => clearTimeout(timer)
    } else {
      window.addEventListener(
        "resize",
        _.debounce(() => {
          handleResize()
        }, 100)
      )

      handleResize(true)

      return () => window.removeEventListener("resize", handleResize)
    }
  }, [check])

  const getHighlightContent = (index) => {
    // this function formats the individual list item content

    const id = index === 1 ? listId2 : listId1

    return (
      <ul
        className={classNames({
          [focalHighlight_default]: theme === "default",
          [focalHighlight_fullWidth_left]: theme === "full-width" && index === 0,
          [focalHighlight_fullWidth_right]: theme === "full-width" && index === 1
        })}
        id={id}
      >
        {focalPoint[index].items.map((item, i) => {
          const liClasses = classNames({
            [focalHighlight_isHeading]: item.heading
          })

          return (
            <Animate animate="fade_in_up" className={liClasses} key={i} delay={i * 300} tag="li">
              {item.icon && <Icon name={item.icon} />}
              {item.heading && <strong>{item.heading}</strong>}
              <p>{item.body}</p>
            </Animate>
          )
        })}
      </ul>
    )
  }

  const getGraySquare = () => {
    // we're defining the gray square after the svg lines, need to get the height manually using svgHeight variable
    const squareClass = classNames(focalHighlight_gray_square, {
      [focalHighlight_gray_square_half]: theme === "default" && !isMobileTiny
    })

    let grayBoxHeight = focalContainerRect.height + 240

    if (theme === "default") {
      grayBoxHeight = focalContainerRect.height - 10
    }

    return <div className={squareClass} style={{ height: `${grayBoxHeight}px` }}></div>
  }

  const getSVGLines = (index) => {
    // first we're getting the starting coordinates for all svg line, these coordinates are the same for each line
    let svgHeight = focalContainerRect.height
    let y1 = focalImageRect.height * focalPoint[index].focalPositionTop * 0.01 + 30
    let x1 =
      focalContainerRect.width * 0.5 -
      focalImageRect.width * 0.5 +
      focalImageRect.width * focalPoint[index].focalPositionLeft * 0.01 +
      17

    if (theme === "full-width" && !isMobile) {
      if (index === 0) {
        y1 = y1 - 10
        x1 = x1 - 13
      }
      if (index === 1) {
        x1 = x1 - 10
        y1 = y1 - 10
      }
    }

    if (theme === "full-width" && focalContainerRect.width > 825)
      svgHeight = focalUlRect.height + 100

    // manually getting the height, width and position of the lines svg based on the theme
    let svgStyle = null

    if (theme === "full-width" && focalContainerRect.width > 825) {
      svgStyle = {
        top: `-${focalImageRect.height}px`,
        marginBottom: `-${focalImageRect.height}px`
      }
    }

    if (theme === "full-width" && index === 1 && focalContainerRect.width > 825) {
      svgStyle = {
        top: "0px",
        position: "absolute"
      }
      x1 = x1 + 22
    }

    // variables needed to record the total height of all li elements in a full-width theme
    let liTotalHeight = 50
    let previousLiTotalHeight = 0

    const svgClass = classNames("focal-highlight-svg", {
      [focalHighlight_default_svg]: theme === "default" || focalContainerRect.width <= 825
    })

    return (
      <>
        <svg
          height={svgHeight}
          width={focalContainerRect.width}
          key={index}
          style={svgStyle}
          className={svgClass}
        >
          {focalPoint[index].items.map((item, i) => {
            const liRect = index === 0 ? focalLiRectsOne[i] : focalLiRectsTwo[i]

            if (liRect) {
              const liRectX = liRect.x - focalContainerRect.x

              // default end x and y coordinates for the svg lines
              let y2 =
                focalContainerRect.height - (focalContainerRect.height - focalImageRect.height) + 33
              let x2 = liRectX + 63

              // line svg x and y coordinates differ depending on theme and browser size
              if (
                (theme === "default" && item.heading) ||
                (theme === "default" && isMedium) ||
                isMobile
              ) {
                x2 = liRectX + 24
              }
              if (theme === "full-width" && !isMobile) {
                // these values only get used for the y2 coordinate for the full-width theme
                liTotalHeight = liTotalHeight + previousLiTotalHeight + 20
                previousLiTotalHeight = liRect.height

                y2 = liTotalHeight + 64

                if (index === 1) {
                  x2 = liRectX - 16
                }
                if (index === 0) {
                  x2 = liRectX + liRect.width + 16
                }
                if (item.heading) {
                  y2 = liTotalHeight + 62
                }
              }

              return <line x1={x1} y1={y1} x2={x2} y2={y2} strokeWidth={2} key={i} />
            }
          })}
        </svg>
      </>
    )
  }

  const getImageFocalPoint = (index) => {
    // this function calculates the "focal point" of the image that all the svg lines connect to
    const top = `${focalImageRect.height * focalPoint[index].focalPositionTop * 0.01}px`
    const left = `${
      focalContainerRect.width * 0.5 -
      focalImageRect.width * 0.5 +
      focalImageRect.width * focalPoint[index].focalPositionLeft * 0.01
    }px`

    return (
      <div
        className={focalHighlight_image_focus}
        style={{
          top,
          left
        }}
      />
    )
  }

  const showSVGLines = (theme == "full-width" && !isMobile) || (theme == "default" && !isMobileTiny)
  const showFullWidthSparkImg = !showSVGLines && theme == "full-width"
  const showDefaultSparkImg = !showSVGLines && theme == "default"

  const content = (
    <>
      {getGraySquare()}

      <Animate animate="fade_in_down">
        <Image
          className={classNames(focalHighlight_image, {
            [focalHighlight_image_full_width]: theme === "full-width"
          })}
          src={image}
          alt={altText}
        />
      </Animate>

      {showSVGLines && (
        <Animate animate="fade_in">
          {getSVGLines(0)}
          {theme === "full-width" && getSVGLines(1)}
        </Animate>
      )}

      {showFullWidthSparkImg && (
        <Animate animate="fade_in">
          <StaticImage
            alt="Spark"
            className={focalHighlight_linesImage}
            placeholder="none"
            src={`../../images/home-insights-digital-expression-2.png`}
          />
        </Animate>
      )}

      {showDefaultSparkImg && (
        <Animate animate="fade_in">
          <StaticImage
            alt="Spark"
            className={focalHighlight_linesImage}
            placeholder="none"
            src={`../../images/home-insights-digital-expression-1.png`}
          />
        </Animate>
      )}

      <Animate animate="fade_in" delay={0}>
        <div className={focalHighlight_list}>
          {getHighlightContent(0)}
          {theme === "full-width" && getHighlightContent(1)}
        </div>
      </Animate>

      {showSVGLines && (
        <Animate animate="fade_in" delay={0}>
          {getImageFocalPoint(0)}
          {theme === "full-width" && getImageFocalPoint(1)}
        </Animate>
      )}
    </>
  )

  return (
    <Animate animate="fade_in" delay={600} style={{ position: "relative" }}>
      <div className={focalHighlight} id={containerId}>
        {content}
      </div>
    </Animate>
  )
}

FocalHighlight.propTypes = {
  /**
   * Alt text used for the main image
   */
  altText: PropTypes.string,
  /**
   * Object with items array and additional focal point information
   */
  focalPoint: PropTypes.arrayOf(
    PropTypes.shape({
      focalPositionLeft: PropTypes.number,
      focalPositionTop: PropTypes.number,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          heading: PropTypes.string,
          body: PropTypes.string,
          icon: PropTypes.oneOf(Object.keys(iconOptions))
        })
      )
    })
  ),
  /**
   * Image link, displayed behind focal point lines
   */
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /**
   * A choice between two focal highlight themes
   */
  theme: PropTypes.oneOf(["default", "full-width"])
}

export default FocalHighlight
