import React, { useState } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import Link from "@src/components/link"
import SVG from "@src/components/svg"
import Alert from "@src/components/alert"

import {
  dropdown_menu,
  dropdown_menu_right,
  dropdown_trigger,
  dropdown,
  is_showing,
  add_underline,
  theme_header_menu,
  has_alert,
  alert_container,
  svg_style
} from "./styles.module.scss"

const themeOptions = {
  header: theme_header_menu
}

const Dropdown = ({
  activeClassName,
  className,
  handleDropdownClick,
  items,
  label,
  onClick,
  openDropdown,
  url,
  theme,
  alert,
  addClassOnHover,
  addUnderlineOnHover,
  rightAlignDropdown
}) => {
  const [itemHover, setItemHover] = useState("")

  const classes = classNames(dropdown, className, {
    [is_showing]: label === openDropdown,
    [addClassOnHover]: label === itemHover,
    [add_underline]: addUnderlineOnHover
  })

  const menuClasses = classNames({
    [themeOptions[theme]]: themeOptions[theme],
    [has_alert]: alert !== null && alert !== undefined,
    [dropdown_menu]: !rightAlignDropdown,
    [dropdown_menu_right]: rightAlignDropdown
  })

  const trackClick = (e) => {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: "Nav Click",
        category: "Top Navigation",
        label: `${label} > ${e.target.innerText} | ${e.target.getAttribute("href")}`
      })
    }
  }

  let displayLabel = <span>{label}</span>
  if (url) {
    // if a url is present, make the parent a link
    displayLabel = (
      <Link activeClassName={activeClassName} title={label} to={url}>
        {label}
      </Link>
    )
  }

  const handleItemHover = (label) => {
    label === itemHover ? setItemHover("") : setItemHover(label)
  }

  const buildChildren = (item, index) => {
    const parent = (
      <div
        {...(addClassOnHover && {
          onFocus: () => handleItemHover(label),
          onBlur: () => handleItemHover(label),
          onMouseOver: () => handleItemHover(label),
          onMouseOut: () => handleItemHover(label)
        })}
      >
        <Link
          activeClassName={activeClassName}
          className={item.className}
          title={item.title}
          to={item.url}
          onClick={(e) => {
            onClick(e)
            trackClick(e)
          }}
        >
          {item.label}
          {item.icon && (
            <span className={svg_style}>
              <SVG name={item.icon} />
            </span>
          )}
        </Link>
      </div>
    )

    let children = null

    if (item.children) {
      children = (
        <ul>
          {item.children.map((child, childIndex) => {
            const childParent = (
              <div
                {...(addClassOnHover && {
                  onFocus: () => handleItemHover(label),
                  onBlur: () => handleItemHover(label),
                  onMouseOver: () => handleItemHover(label),
                  onMouseOut: () => handleItemHover(label)
                })}
              >
                <Link
                  activeClassName={activeClassName}
                  className={child.className}
                  title={child.title}
                  to={child.url}
                  onClick={trackClick}
                >
                  {child.label}
                </Link>
              </div>
            )

            let childChildren = null
            if (child.children) {
              childChildren = (
                <ul>
                  {child.children.map((nextChild, nextChildIndex) => {
                    return (
                      <li key={nextChildIndex}>
                        <div
                          {...(addClassOnHover && {
                            onFocus: () => handleItemHover(label),
                            onBlur: () => handleItemHover(label),
                            onMouseOver: () => handleItemHover(label),
                            onMouseOut: () => handleItemHover(label)
                          })}
                        >
                          <Link
                            activeClassName={activeClassName}
                            className={nextChild.className}
                            title={nextChild.title}
                            to={nextChild.url}
                            onClick={trackClick}
                          >
                            {nextChild.label}
                          </Link>
                        </div>
                      </li>
                    )
                  })}
                </ul>
              )
            }
            return (
              <li key={childIndex}>
                {childParent}
                {childChildren}
              </li>
            )
          })}
        </ul>
      )

      return (
        <li key={index}>
          {parent}
          {children}
        </li>
      )
    }

    return <li key={index}>{parent}</li>
  }

  return (
    <div
      className={classes}
      onKeyPress={() => handleDropdownClick(label)}
      onClick={() => handleDropdownClick(label)}
      role="button"
      tabIndex={0}
    >
      <div className={dropdown_trigger}>
        {displayLabel}
        <SVG name="angle-down" />
      </div>

      <div className={menuClasses}>
        <ul>
          {items.map((item, index) => {
            return buildChildren(item, index)
          })}
        </ul>
        {alert && (
          <div className={alert_container}>
            <Alert {...alert} theme="navigation" />
          </div>
        )}
      </div>
    </div>
  )
}

Dropdown.propTypes = {
  /**
   * Alert Meta data to display an alert in the navigation dropdown menu.
   */
  alert: PropTypes.object,
  /**
   * Specifies an active class name, passed on to the label when it's a <Link /> component
   */
  activeClassName: PropTypes.string,
  /**
   * CSS class(es) applied to the wrapping element when hovering over an item
   */
  addClassOnHover: PropTypes.string,
  /**
   * Boolean - adds an underline style to the dropdown label
   */
  addUnderlineOnHover: PropTypes.bool,
  /**
   * Boolean - aligns the dropdown container to the right side of the parent
   */
  rightAlignDropdown: PropTypes.bool,
  /**
   * CSS class(es) applied to the wrapping element
   */
  className: PropTypes.string,
  /**
   * Element(s) to render to the screen
   */
  items: PropTypes.array.isRequired,
  /**
   * A onClick function passed down from the navigation component
   */
  handleDropdownClick: PropTypes.func,
  /**
   * Dropdown label
   */
  label: PropTypes.string.isRequired,
  /**
   * A onClick function passed down from the navigation component
   */
  onClick: PropTypes.func,
  /**
   * Dropdown state passed down from the navigation component
   */
  openDropdown: PropTypes.string,
  /**
   * A url passed to be used on the label
   */
  url: PropTypes.string,
  /**
   * A url passed to be used on the label
   */
  theme: PropTypes.string
}

export default Dropdown
