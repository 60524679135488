import React from "react"

export default {
  default: {
    trigger: "In enim voluptate id in aliquip deserunt?",
    children: (
      <p>
        Tempor proident id ipsum esse eiusmod aliqua ullamco irure non nisi laboris nulla
        exercitation deserunt tempor. Esse dolor incididunt laborum sit in aute adipisicing occaecat
        eiusmod proident mollit officia sit cupidatat. Excepteur in labore culpa cupidatat esse eu
        dolor ea occaecat nostrud veniam amet consectetur ipsum adipisicing. Culpa irure ea irure
        aliquip mollit laboris labore eu ad nostrud consectetur laboris aute voluptate deserunt. Sit
        ad elit magna anim amet non mollit. Dolore officia voluptate nisi exercitation amet ad minim
        amet consectetur voluptate cupidatat et elit. Duis exercitation ea non dolor minim irure
        dolor quis non minim cillum. Aute cupidatat mollit Lorem Lorem nostrud aliqua velit mollit
        nulla.
      </p>
    )
  }
}
