import React from "react"
import {
  component as FocalHighlight,
  fixtures as FocalHighlightFixtures
} from "@components/focal-highlight"
import Grid from "@components/grid"
import { component as Video, fixtures as VideoFixtures } from "@components/video"

export default {
  props: {
    heading: "Data Science",
    body: "<p>Using a proprietary suite of tools and technology, we deliver unparalleled data science and predictive analytics to transform customer data into actionable knowledge.</p>",
    theme: "light"
  },
  withBackgroundImage: {
    heading: "Insights",
    body: "<p>We bring insights to life through personalized customer communications. We provide unique personalized marketing strategies to connect with your customers. Our products enable self-serve insights and yield predictive and prescriptive analytics through high-tech data science.</p>",
    background_image: {
      gatsbyImageData: {
        layout: "fixed",
        backgroundColor: "#522181",
        images: {
          fallback: {
            src: "/storybook/1bd0b6fba56adbe7a0d7690574e50b19/a1052/jumbotron.jpg",
            srcSet:
              "/storybook/1bd0b6fba56adbe7a0d7690574e50b19/6bdc6/jumbotron.jpg 360w,\n/storybook/1bd0b6fba56adbe7a0d7690574e50b19/79322/jumbotron.jpg 720w,\n/storybook/1bd0b6fba56adbe7a0d7690574e50b19/a1052/jumbotron.jpg 1440w,\n/storybook/1bd0b6fba56adbe7a0d7690574e50b19/2f279/jumbotron.jpg 2880w",
            sizes: "(min-width: 1440px) 1440px, 100vw"
          },
          sources: [
            {
              srcSet:
                "/storybook/1bd0b6fba56adbe7a0d7690574e50b19/320ab/jumbotron.webp 360w,\n/storybook/1bd0b6fba56adbe7a0d7690574e50b19/efe10/jumbotron.webp 720w,\n/storybook/1bd0b6fba56adbe7a0d7690574e50b19/34d85/jumbotron.webp 1440w,\n/storybook/1bd0b6fba56adbe7a0d7690574e50b19/78985/jumbotron.webp 2880w",
              type: "image/webp",
              sizes: "(min-width: 1440px) 1440px, 100vw"
            }
          ]
        },
        width: 1440,
        height: 686.0000000000001
      }
    },
    theme: "dark",
    overlay: true
  },
  withDigitalExpression: {
    homepage: true,
    heading: "Break through the noise and become relevant with your customers",
    button: {
      children: "Talk to us",
      theme: "outline",
      to: "#"
    },
    background_image: {
      gatsbyImageData: {
        layout: "constrained",
        backgroundColor: "#582888",
        images: {
          fallback: {
            src: "/storybook/b457272f059ec348208e4fafe08711c8/48eb2/background-gradient.jpg",
            srcSet:
              "/storybook/b457272f059ec348208e4fafe08711c8/d518d/background-gradient.jpg 350w,\n/storybook/b457272f059ec348208e4fafe08711c8/57c8c/background-gradient.jpg 700w,\n/storybook/b457272f059ec348208e4fafe08711c8/48eb2/background-gradient.jpg 1400w",
            sizes: "(min-width: 1400px) 1400px, 100vw"
          },
          sources: [
            {
              srcSet:
                "/storybook/b457272f059ec348208e4fafe08711c8/3668f/background-gradient.webp 350w,\n/storybook/b457272f059ec348208e4fafe08711c8/dfc53/background-gradient.webp 700w,\n/storybook/b457272f059ec348208e4fafe08711c8/ccf6e/background-gradient.webp 1400w",
              type: "image/webp",
              sizes: "(min-width: 1400px) 1400px, 100vw"
            }
          ]
        },
        width: 1400,
        height: 722
      }
    },
    theme: "dark",
    digital_expression: "true",
    ctas: [
      {
        heading: "Shining a Light on Summer of 2021. Take a peek.",
        image: {
          gatsbyImageData: {
            layout: "fixed",
            backgroundColor: "#c8d8d8",
            images: {
              fallback: {
                src: "/storybook/ad44a49cc53abc6342418a231eb625bd/5a4a4/media-object.jpg",
                srcSet:
                  "/storybook/ad44a49cc53abc6342418a231eb625bd/1cb07/media-object.jpg 25w,\n/storybook/ad44a49cc53abc6342418a231eb625bd/d27a5/media-object.jpg 50w,\n/storybook/ad44a49cc53abc6342418a231eb625bd/5a4a4/media-object.jpg 100w,\n/storybook/ad44a49cc53abc6342418a231eb625bd/65570/media-object.jpg 200w",
                sizes: "(min-width: 100px) 100px, 100vw"
              },
              sources: [
                {
                  srcSet:
                    "/storybook/ad44a49cc53abc6342418a231eb625bd/977e6/media-object.webp 25w,\n/storybook/ad44a49cc53abc6342418a231eb625bd/6dec7/media-object.webp 50w,\n/storybook/ad44a49cc53abc6342418a231eb625bd/a7d2e/media-object.webp 100w,\n/storybook/ad44a49cc53abc6342418a231eb625bd/91284/media-object.webp 200w",
                  type: "image/webp",
                  sizes: "(min-width: 100px) 100px, 100vw"
                }
              ]
            },
            width: 94,
            height: 66
          }
        },
        category: {
          label: "Insights",
          url: "/insights"
        }
      },
      {
        heading: "Shining a Light on Summer of 2021. Take a peek.",
        image: {
          gatsbyImageData: {
            layout: "fixed",
            backgroundColor: "#c8d8d8",
            images: {
              fallback: {
                src: "/storybook/ad44a49cc53abc6342418a231eb625bd/5a4a4/media-object.jpg",
                srcSet:
                  "/storybook/ad44a49cc53abc6342418a231eb625bd/1cb07/media-object.jpg 25w,\n/storybook/ad44a49cc53abc6342418a231eb625bd/d27a5/media-object.jpg 50w,\n/storybook/ad44a49cc53abc6342418a231eb625bd/5a4a4/media-object.jpg 100w,\n/storybook/ad44a49cc53abc6342418a231eb625bd/65570/media-object.jpg 200w",
                sizes: "(min-width: 100px) 100px, 100vw"
              },
              sources: [
                {
                  srcSet:
                    "/storybook/ad44a49cc53abc6342418a231eb625bd/977e6/media-object.webp 25w,\n/storybook/ad44a49cc53abc6342418a231eb625bd/6dec7/media-object.webp 50w,\n/storybook/ad44a49cc53abc6342418a231eb625bd/a7d2e/media-object.webp 100w,\n/storybook/ad44a49cc53abc6342418a231eb625bd/91284/media-object.webp 200w",
                  type: "image/webp",
                  sizes: "(min-width: 100px) 100px, 100vw"
                }
              ]
            },
            width: 94,
            height: 66
          }
        },
        category: {
          label: "Insights",
          url: "/insights"
        }
      },
      {
        heading: "Shining a Light on Summer of 2021. Take a peek.",
        image: {
          gatsbyImageData: {
            layout: "fixed",
            backgroundColor: "#c8d8d8",
            images: {
              fallback: {
                src: "/storybook/ad44a49cc53abc6342418a231eb625bd/5a4a4/media-object.jpg",
                srcSet:
                  "/storybook/ad44a49cc53abc6342418a231eb625bd/1cb07/media-object.jpg 25w,\n/storybook/ad44a49cc53abc6342418a231eb625bd/d27a5/media-object.jpg 50w,\n/storybook/ad44a49cc53abc6342418a231eb625bd/5a4a4/media-object.jpg 100w,\n/storybook/ad44a49cc53abc6342418a231eb625bd/65570/media-object.jpg 200w",
                sizes: "(min-width: 100px) 100px, 100vw"
              },
              sources: [
                {
                  srcSet:
                    "/storybook/ad44a49cc53abc6342418a231eb625bd/977e6/media-object.webp 25w,\n/storybook/ad44a49cc53abc6342418a231eb625bd/6dec7/media-object.webp 50w,\n/storybook/ad44a49cc53abc6342418a231eb625bd/a7d2e/media-object.webp 100w,\n/storybook/ad44a49cc53abc6342418a231eb625bd/91284/media-object.webp 200w",
                  type: "image/webp",
                  sizes: "(min-width: 100px) 100px, 100vw"
                }
              ]
            },
            width: 94,
            height: 66
          }
        },
        category: {
          label: "Insights",
          url: "/insights"
        }
      }
    ]
  },
  withChildren: {
    heading: "84.51° Stratum",
    body: "<p>84.51° Stratum is a breakthrough analytics solution. It combines rich behavioral data with retail performance measurements to deliver science-powered insights that drive results.</p>",
    breadcrumbs: [
      { label: "Knowledge Hub", url: "#" },
      { label: "Data Science", url: "#" }
    ],
    left_side_children: (
      <Grid>
        <Video {...VideoFixtures.props} />
        <Video {...VideoFixtures.props} />
        <div></div>
      </Grid>
    ),
    right_side_children: <FocalHighlight {...FocalHighlightFixtures.default} />
  },
  withSmeTheme: {
    sme: {
      title: "VP, Commercial Insights, 84.51˚",
      name: "Author Name",
      image: {
        gatsbyImageData: {
          images: {
            sources: [
              {
                srcSet:
                  "//images.ctfassets.net/c23k8ps4z1xm/1PIIDyBbKv3AbTWKCla4vB/ebf18b044300c8db09ad1e779b406998/Screenshot_2023-02-22_at_2.42.17_PM.png?w=354&h=354&q=50&fm=webp 354w,\n//images.ctfassets.net/c23k8ps4z1xm/1PIIDyBbKv3AbTWKCla4vB/ebf18b044300c8db09ad1e779b406998/Screenshot_2023-02-22_at_2.42.17_PM.png?w=708&h=708&q=50&fm=webp 708w,\n//images.ctfassets.net/c23k8ps4z1xm/1PIIDyBbKv3AbTWKCla4vB/ebf18b044300c8db09ad1e779b406998/Screenshot_2023-02-22_at_2.42.17_PM.png?w=1415&h=1415&q=50&fm=webp 1415w",
                sizes: "(min-width: 1415px) 1415px, 100vw",
                type: "image/webp"
              }
            ],
            fallback: {
              src: "//images.ctfassets.net/c23k8ps4z1xm/1PIIDyBbKv3AbTWKCla4vB/ebf18b044300c8db09ad1e779b406998/Screenshot_2023-02-22_at_2.42.17_PM.png?w=1415&h=1415&q=50&fm=png",
              srcSet:
                "//images.ctfassets.net/c23k8ps4z1xm/1PIIDyBbKv3AbTWKCla4vB/ebf18b044300c8db09ad1e779b406998/Screenshot_2023-02-22_at_2.42.17_PM.png?w=354&h=354&q=50&fm=png 354w,\n//images.ctfassets.net/c23k8ps4z1xm/1PIIDyBbKv3AbTWKCla4vB/ebf18b044300c8db09ad1e779b406998/Screenshot_2023-02-22_at_2.42.17_PM.png?w=708&h=708&q=50&fm=png 708w,\n//images.ctfassets.net/c23k8ps4z1xm/1PIIDyBbKv3AbTWKCla4vB/ebf18b044300c8db09ad1e779b406998/Screenshot_2023-02-22_at_2.42.17_PM.png?w=1415&h=1415&q=50&fm=png 1415w",
              sizes: "(min-width: 1415px) 1415px, 100vw"
            }
          },
          layout: "constrained",
          backgroundColor: "#f8f8f8",
          width: 1415,
          height: 1415
        },
        title: "SME Placeholder",
        file: {
          url: "//images.ctfassets.net/c23k8ps4z1xm/1PIIDyBbKv3AbTWKCla4vB/ebf18b044300c8db09ad1e779b406998/Screenshot_2023-02-22_at_2.42.17_PM.png"
        }
      }
    },
    theme: "sme"
  }
}
