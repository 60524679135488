// ---------------------------------------------------------

import React, { useState } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import lowerCase from "lodash/lowerCase"
import OutsideClickHandler from "react-outside-click-handler"
import { ReactSVG } from "react-svg"

// ---------------------------------------------------------

import SVG from "@components/svg"

// ---------------------------------------------------------

import {
  animated_graphic_item_0,
  animated_graphic_item_1,
  animated_graphic_item_2,
  animated_graphic_item_3,
  animated_graphic_item_content_fade,
  animated_graphic_item_content,
  animated_graphic_item_header,
  animated_graphic_item_heading,
  animated_graphic_item_list,
  animated_graphic_item,
  close_button,
  contains_close_button,
  is_closed,
  is_even,
  is_not_active,
  is_odd,
  is_showing
} from "../styles.module.scss"

const itemIndices = {
  0: animated_graphic_item_0,
  1: animated_graphic_item_1,
  2: animated_graphic_item_2,
  3: animated_graphic_item_3
}

// ---------------------------------------------------------

const CollaborativeCloudAnimatedGraphicItem = (props) => {
  let { heading, list, itemIndex, notActive, setActiveIndex } = props

  // -------------------------------------------------------

  const [isShowing, setItemIsShowing] = useState(false)
  const [itemIsClosed, setItemIsClosed] = useState(false)

  // ---------------------------------------------------------

  const multipleOf2 = (n) => {
    if (n % 2 == 0) return true

    return false
  }

  const handleCloseButton = (event) => {
    event.stopPropagation()
    setActiveIndex(null)
    setItemIsClosed(true)
    setItemIsShowing(false)
  }

  const handleOutsideClick = () => {
    setItemIsShowing(false)
  }

  // ---------------------------------------------------------

  const Header = (closeButton) => (
    <header
      className={classNames(animated_graphic_item_header, {
        [contains_close_button]: closeButton
      })}
    >
      <div>
        <ReactSVG src={`/images/svgs/cc-${lowerCase(heading).split(" ").join("-")}.svg`} />
        <div className={animated_graphic_item_heading}>
          <h2>{heading}</h2>
          {!closeButton && <SVG name="angle-right" />}
        </div>
      </div>

      {closeButton && (
        <button
          className={close_button}
          onClick={handleCloseButton}
          onKeyPress={handleCloseButton}
          type="button"
        >
          <ReactSVG src={`/images/svgs/close.svg`} />
        </button>
      )}
    </header>
  )

  // ---------------------------------------------------------

  return (
    <OutsideClickHandler onOutsideClick={() => handleOutsideClick()}>
      <div
        className={classNames(animated_graphic_item, {
          [is_not_active]: notActive,
          [itemIndices[itemIndex]]: itemIndices[itemIndex],
          [is_showing]: isShowing
        })}
        onClick={() => setItemIsShowing(true)}
        onKeyPress={() => setItemIsShowing(true)}
        role="button"
        tabIndex="0"
      >
        {Header(false)}

        {list && (
          <div
            className={classNames(animated_graphic_item_content, {
              [is_even]: multipleOf2(itemIndex),
              [is_odd]: !multipleOf2(itemIndex),
              [is_closed]: itemIsClosed && !isShowing,
              [is_showing]: isShowing,
              [itemIndices[itemIndex]]: itemIndices[itemIndex]
            })}
          >
            <div className={animated_graphic_item_content_fade}>
              {Header(true)}
              <ul className={animated_graphic_item_list}>
                {list.map((item, key) => {
                  return <li key={key}>{item.label}</li>
                })}
              </ul>
            </div>
          </div>
        )}
      </div>
    </OutsideClickHandler>
  )
}

// ---------------------------------------------------------

CollaborativeCloudAnimatedGraphicItem.propTypes = {
  /**
   * Specifies the item's heading.
   */
  heading: PropTypes.string,

  /**
   * Specifies the item's description list.
   */
  list: PropTypes.array,

  /**
   * Specifies the item index.
   */
  itemIndex: PropTypes.number,

  /**
   * Specifies if the current item is active.
   */
  notActive: PropTypes.bool,

  /**
   * Specifies the active index that's being passed from the parent component.
   */
  setActiveIndex: PropTypes.number
}

export default CollaborativeCloudAnimatedGraphicItem
