/* eslint-disable no-unused-vars */
import React from "react"

import Block from "@components/block"
import Card from "@components/card"
import Container from "@components/container"
import FilterSort, { useFilterSort } from "@components/filter-sort"
import Grid from "@components/grid"
import Layout from "@src/layout"
import Pagination, { usePagination } from "@components/pagination"

const SmeIndex = ({ data }) => {
  const { nodes: smeList } = data.smes
  const { nodes: categories } = data.categories
  const { jumbotron, contactForm } = data

  // ----------------------------- | Pagination & Filtering

  const per = 12
  const [gridRef, count, setCount, page, handlePage] = usePagination(0)
  const [filtered, handleFilter] = useFilterSort(smeList, per, handlePage, setCount)

  return (
    <Layout title={"Subject Matter Experts"} theme="dark" customLayout>
      <Block {...jumbotron} />
      <span ref={gridRef} />
      <Container padding="medium">
        <FilterSort
          filterBy={categories}
          filterLabel={"Filter By Category"}
          handleFilter={handleFilter}
        />
        <Grid layout="3" centered={false} gutter="small">
          {filtered &&
            filtered[page - 1].map((author, idx) => (
              <Card key={author.id} currentIndex={idx} {...author} />
            ))}
          {filtered && filtered[page - 1].length % 3 !== 0 && <div></div>}
        </Grid>
        {count > per && (
          <Pagination
            currentPage={page}
            itemsPerPage={per}
            totalItems={count}
            onChange={handlePage}
          />
        )}
      </Container>
      <Block {...contactForm} />
    </Layout>
  )
}
export default SmeIndex
