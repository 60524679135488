import React from "react"
import PropTypes from "prop-types"

import Animate from "@components/animate"
import Select from "@components/select"

import { wrapper } from "./styles.module.scss"

const FilterSort = ({ filterBy, filterLabel, handleFilter, handleSort, sort = false }) => {
  if (!filterBy) return null

  // ----------------------------- | Filter Options
  const vals = new Set(filterBy.map(({ title }) => title))

  const filterOptions = Array.from(vals, (option) => {
    return { value: option, label: option }
  })

  // ----------------------------- | Sort Options
  const sortOptions = [
    { value: "recent", label: "Most Recent" },
    { value: "alpha", label: "Sort A-Z" }
  ]

  return (
    <Animate animate={"fade_in_up"} delay={900} duration={500} zIndex>
      <div className={wrapper}>
        <Select
          options={filterOptions}
          isMulti
          placeholder={filterLabel}
          handleSelect={handleFilter}
        />
        {sort && (
          <Select
            options={sortOptions}
            placeholder={"Sort By"}
            hasDefault
            handleSelect={handleSort}
          />
        )}
      </div>
    </Animate>
  )
}

FilterSort.propTypes = {
  /**
   * Array of categories pulled from Contentful posts
   */
  filterBy: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string
    })
  ).isRequired,
  /**
   * Placeholder in Filter field
   */
  filterLabel: PropTypes.string.isRequired,
  /**
   * Passes selected options from Filter back to parent
   */
  handleFilter: PropTypes.func.isRequired,
  /**
   * Passes selected options from Sort back to parent
   */
  handleSort: PropTypes.func,
  /**
   * Enabled the sorting dropdown
   */
  sort: PropTypes.bool
}

export default FilterSort
