export default {
  props: {
    title: "Stay up to date with the latest in your industry news and trends from 84.51°.",
    label: "Subscribe",
    placeholder: "Enter email",
    body: "You can unsubscribe at any time.",
    link: {
      label: "Read our privacy policy",
      url: "#"
    },
    theme: "light"
  },
  dark: {
    title: "Stay up to date with the latest in your industry news and trends from 84.51°.",
    label: "Subscribe",
    placeholder: "Enter email",
    body: "You can unsubscribe at any time.",
    link: {
      label: "Read our privacy policy",
      url: "/privacy-policy"
    },
    theme: "dark"
  }
}
