import React from "react"
import PropTypes from "prop-types"

import { renderBlocks } from "@src/templates/page/utils"
import componentMap from "./component-map"

const Block = (props) => {
  // If no data was passed, render nothing.
  if (!props) return null

  const type = props.__typename == "ContentfulSnippet" ? props.snippet : props.__typename

  // const type = "Scaffold"

  // Name of the component to render comes from the componentMap, defined in
  // component-map.js.
  const TagName = componentMap[type]

  // If the tag is not found, throw a console error and exit.
  if (!TagName) {
    console.warn(`${type} mapping is not supported.`, props)
    return null
  }

  // Render the component
  return <TagName {...props}>{props.blocks && renderBlocks(props.blocks, props.owner)}</TagName>
}

/**
 * This is open-ended. All other props pass-through this component directly to
 * the component.
 */
Block.propTypes = {
  /**
   * The name of the component, which gets mapped to the actual component in
   * component-map.js.
   */
  __typename: PropTypes.string,
  snippet: PropTypes.string,
  blocks: PropTypes.array,
  owner: PropTypes.object
}

Block.defaultProps = {}

export default Block
