import React, { useState } from "react"
import axios from "axios"
import classNames from "classnames"
import PropTypes from "prop-types"
import ReCAPTCHA from "react-google-recaptcha"

import { Formik, Field } from "formik"

import Button from "@components/button"
import Link from "@components/link"
import SVG from "@components/svg"

import { align_left, formWrapper, select } from "@components/form/styles.module.scss"
import {
  success,
  flexForm,
  selectControl,
  button_container,
  recaptcha_container,
  disclaimer_text
} from "./styles.module.scss"

const { parseContentfulJSON } = require("@src/utils")

const ContactForm = ({ config }) => {
  const enableCaptcha = true
  const [verified, setVerified] = useState(!enableCaptcha)

  const onVerifiedRecaptcha = (value) => {
    if (!value) return
    setVerified(true)
  }

  const defaultOptions = [
    ["", "I want to..."],
    "Learn about 84.51&deg; data solutions",
    "Learn about loyalty marketing &amp; advertising",
    "Start a partnership with 84.51&deg;",
    "Ask about 84.51&deg;",
    "Ask about the California Applicant Policy",
    "Ask about the California Associate Policy",
    "Work at 84.51&deg;"
  ]
  const cfg = parseContentfulJSON(config)
  const options = cfg.options || defaultOptions
  const [submitted, setSubmitted] = useState(false)
  const [hasSelect] = useState(Array.isArray(options) && options.length > 0)

  const handleSubmit = async (values, { resetForm }) => {
    if (cfg.recipient) {
      values["template-contactform-recipient"] = cfg.recipient
    }
    const url = "/.netlify/functions/sendgrid"
    const response = await axios.post(url, values)
    if (response.status === 200) {
      setSubmitted(true)
      resetForm({
        values: {
          "template-contactform-name": "",
          "template-contactform-subject": "",
          "template-contactform-email": "",
          "template-contactform-phone": "",
          "template-contactform-message": ""
        }
      })
    }
  }

  const handleValidate = () => ({})

  const initialValues = {}
  return (
    <div className={classNames(formWrapper, align_left)} id="form">
      <header>
        {cfg.heading ? <h4>{cfg.heading}</h4> : <h4>Contact Us!</h4>}
        {cfg.body ? (
          <p>{cfg.body}</p>
        ) : (
          <p>Static cras mattis consectetur purus sit amet fermentum.</p>
        )}
      </header>

      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit.bind(this)}
        validate={handleValidate.bind(this)}
      >
        {(formProps) => (
          <form key={`form-0`} onSubmit={formProps.handleSubmit}>
            <div className={classNames(flexForm)}>
              <div>
                <label htmlFor="template-contactform-name">Name *</label>
                <Field name="template-contactform-name" type="text" required={true} />
                <label htmlFor="template-contactform-email">Email *</label>
                <Field name="template-contactform-email" type="email" required={true} />

                <label htmlFor="template-contactform-phone">Phone *</label>
                <Field name="template-contactform-phone" type="text" required={true} />
              </div>
              <div>
                {hasSelect && (
                  <>
                    <label htmlFor="template-contactform-subject">Subject *</label>
                    <div className={classNames(select, selectControl)}>
                      <Field name="template-contactform-subject" as="select">
                        {cfg.defaultOption && (
                          <option
                            value=""
                            dangerouslySetInnerHTML={{ __html: cfg.defaultOption }}
                          ></option>
                        )}
                        {options.map((option) => {
                          if (Array.isArray(option)) {
                            return (
                              <option
                                value={option[0]}
                                dangerouslySetInnerHTML={{ __html: option }}
                                key={option}
                              ></option>
                            )
                          } else {
                            return (
                              <option
                                value={option}
                                key={option}
                                dangerouslySetInnerHTML={{ __html: option }}
                              ></option>
                            )
                          }
                        })}
                      </Field>
                      <SVG name="angle-down" />
                    </div>
                  </>
                )}
                <label htmlFor="template-contactform-message">Message *</label>
                <Field
                  name="template-contactform-message"
                  as="textarea"
                  required={true}
                  style={{ height: hasSelect ? "124px" : "100%" }}
                />
              </div>
            </div>

            <div className={button_container}>
              <div>
                {enableCaptcha && (
                  <div className={recaptcha_container}>
                    <ReCAPTCHA
                      sitekey={process.env.RECAPTCHA_SITE_KEY}
                      onChange={onVerifiedRecaptcha}
                      onExpired={() => setVerified(false)}
                    />
                    <p>
                      This form is protected by reCAPTCHA and operates under Google's{" "}
                      <a href="https://policies.google.com/privacy?hl=en-US">Privacy Policy</a> and{" "}
                      <a href="https://policies.google.com/terms?hl=en-US">Terms of Service</a>
                    </p>
                  </div>
                )}
              </div>
              <Button type="submit" size="medium" disabled={!verified}>
                {formProps.isSubmitting ? "Submitting..." : "Contact"}
              </Button>
            </div>
            <div className={disclaimer_text}>
              <p>
                By entering your information in this form, you agree to us sending you marketing
                emails and other communication. Your information will be handled in accordance with
                our <Link to="https://www.thekrogerco.com/privacy-policy/">privacy policy</Link>.
              </p>
            </div>
          </form>
        )}
      </Formik>
      {submitted && (
        <div className={success}>
          <strong>Thank you for contacting us.</strong>
          <br />
          We will get back to you within 1 business day. Looking forward to helping you reach your
          goals!
        </div>
      )}
    </div>
  )
}

ContactForm.propTypes = {
  config: PropTypes.object
}

export default ContactForm
