export default {
  default: {
    altText: "Customer Detail",
    focalPoint: [
      {
        focalPositionLeft: 31,
        focalPositionTop: 32,
        items: [
          {
            heading: "Julie",
            body: "48 years old; married; Nashville, TN",
            icon: "demographics"
          },
          {
            body: "She is an avid digital coupon user and downloads them on Kroger.com",
            icon: "coupon-engagement"
          },
          {
            body: "An active Pinterest user, always looking for the next great recipe for a family gathering",
            icon: "media"
          },
          {
            body: "She plans ahead and buys in bulk for her family of five",
            icon: "purchase-data"
          }
        ]
      },
      {
        focalPositionLeft: 85,
        focalPositionTop: 41,
        items: [
          {
            heading: "Miles",
            body: "32 years old; single; Chicago, IL",
            icon: "demographics"
          },
          {
            body: "He receives offers through email and direct mail, but hardly ever uses them",
            icon: "coupon-engagement"
          },
          {
            body: "Frequently listens to podcasts during workouts ",
            icon: "media"
          },
          {
            body: "He shops the perimeter of the store to keep up with his healthy lifestyle",
            icon: "purchase-data"
          }
        ]
      }
    ],
    image: {
      gatsbyImageData: {
        layout: "constrained",
        backgroundColor: "#d8d8d8",
        images: {
          fallback: {
            src: "/storybook/fa8f2be0f12ea526b342649cfc35b139/9f3cb/home-insights.png",
            srcSet:
              "/storybook/fa8f2be0f12ea526b342649cfc35b139/7ce94/home-insights.png 145w,\n/storybook/fa8f2be0f12ea526b342649cfc35b139/69ef8/home-insights.png 290w,\n/storybook/fa8f2be0f12ea526b342649cfc35b139/9f3cb/home-insights.png 579w",
            sizes: "(min-width: 579px) 579px, 100vw"
          },
          sources: [
            {
              srcSet:
                "/storybook/fa8f2be0f12ea526b342649cfc35b139/b7a3a/home-insights.webp 145w,\n/storybook/fa8f2be0f12ea526b342649cfc35b139/90bd7/home-insights.webp 290w,\n/storybook/fa8f2be0f12ea526b342649cfc35b139/7227b/home-insights.webp 579w",
              type: "image/webp",
              sizes: "(min-width: 579px) 579px, 100vw"
            }
          ]
        },
        width: 600,
        height: 400
      }
    },
    theme: "full-width"
  }
}
