import React, { useState } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import SVG from "@components/svg"
import { useMediaQuery } from "react-responsive"
import { navigate } from "gatsby"
import { isGatsbyLink } from "./../link/helpers"

import {
  side_navigation,
  side_navigation_mobile,
  navigation_item_active,
  KPM,
  select_icon
} from "./styles.module.scss"

import Link from "@components/link"

const trimActiveLink = (link) => {
  link = typeof link === "string" ? link : ""
  return link
    .replace(window.location.origin, "")
    .replace(/^\//gm, "")
    .replace(/\/$/gm, "")
    .replace(/\/\?/gm, "?")
    .replace(/\/#/gm, "#")
}

const isActiveLink = (link) => {
  if (typeof window === "undefined") {
    return false
  }

  return trimActiveLink(window.location.href).indexOf(trimActiveLink(link)) !== -1
}

const defaultActiveLink = (items) => {
  const item = items.filter(({ link }) => isActiveLink(link))[0]
  return item ? item.link : undefined
}

const mobileNavigate = (link) => {
  if (link?.to == undefined) return

  if (isGatsbyLink(link)) {
    navigate(link)
    return
  }

  window.location.href = link
}

const SideNavigation = ({ items, mobileMaxWidth, onClick }) => {
  const [activeLink, setActiveLink] = useState(defaultActiveLink(items))

  const isMobile = useMediaQuery({
    query: `(max-width: ${mobileMaxWidth})`
  })

  const onLinkClick = (value) => {
    if (onClick) {
      onClick(value)
    }
  }

  const onMobileLinkClick = (e) => {
    setActiveLink(e.target.value)
    mobileNavigate(e.target.value)

    if (onClick) {
      onClick(e.target.selectedOptions[0].label)
    }
  }

  if (isMobile) {
    return (
      <div className={classNames(side_navigation_mobile)}>
        <select
          onChange={(e) => {
            onMobileLinkClick(e)
          }}
          value={activeLink}
        >
          {items.map(({ label, link }, index) => {
            return (
              <option key={index} value={link} label={label}>
                {label}
              </option>
            )
          })}
        </select>
        <SVG name="angle-down" className={select_icon} />
      </div>
    )
  }

  return (
    <nav className={classNames(side_navigation)}>
      <ul>
        {items.map(({ label, link }, index) => {
          const isActive = isActiveLink(link)
          return (
            <li key={index} className={isActive ? navigation_item_active : null}>
              {link?.to ? (
                <Link
                  to={link}
                  target=""
                  onClick={(e) => {
                    onLinkClick(e.target.firstChild.data)
                  }}
                >
                  {label}
                </Link>
              ) : (
                <button
                  target=""
                  onClick={(e) => {
                    onLinkClick(e.target.firstChild.data)
                  }}
                >
                  {label}
                </button>
              )}
            </li>
          )
        })}
      </ul>
    </nav>
  )
}

SideNavigation.propTypes = {
  /**
   * The list of items for the navigation.
   */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      link: PropTypes.string
    })
  ).isRequired,
  /**
   * The max width for the mobile dropdown.
   */
  mobileMaxWidth: PropTypes.string,
  onClick: PropTypes.func
}

SideNavigation.defaultProps = { mobileMaxWidth: "720px" }

export default SideNavigation
