import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import iconOptions from "./icons"

import Animate from "@components/animate"

import {
  icon_container,
  statistic,
  with_heading_and_description,
  with_heading
} from "./styles.module.scss"
import IconCard from "@components/snippets/icon-card/component"

const Icon = (props) => {
  const { currentIndex, description, name, size, heading, statistics, theme } = props
  let iconSize = (
    <svg width="42" height="42" viewBox="0 0 120 120">
      {iconOptions[name]}
    </svg>
  )

  if (size === "small") {
    iconSize = (
      <svg width="32" height="32" viewBox="0 0 120 120">
        {iconOptions[name]}
      </svg>
    )
  }

  let icon = iconSize

  if (heading) {
    icon = (
      <Animate
        animate="fade_in_up"
        delay={currentIndex * 300}
        className={classNames(icon_container, {
          [with_heading]: heading && !description && !statistics,
          [with_heading_and_description]: heading && description && !statistics,
          [statistic]: statistics
        })}
      >
        {theme === "gradient_card" ? (
          <IconCard
            configObject={{
              title: heading,
              icon: name,
              body: description
            }}
            large
          />
        ) : (
          <>
            {iconSize}
            <div>
              {!statistics && heading && <strong>{heading}</strong>}
              {!statistics && heading && description && <span>{description}</span>}
              {statistics && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: statistics ? statistics.childMarkdownRemark.html : ""
                  }}
                />
              )}
            </div>
          </>
        )}
      </Animate>
    )
  }

  return icon
}

Icon.propTypes = {
  /**
   * Get the current index
   */
  currentIndex: PropTypes.number,
  /**
   * The heading sits to the right of the icon
   * or below if the icon has a description
   */
  heading: PropTypes.string,
  /**
   * If both the heading and description fields have a values, the description will sit below the heading.
   */
  description: PropTypes.string,
  /**
   * The name of the icon
   */
  name: PropTypes.oneOf(Object.keys(iconOptions)).isRequired,
  /**
   * The width of the icon
   */
  size: PropTypes.oneOf(["small", "normal"]),
  statistics: PropTypes.object
}

Icon.defaultProps = {
  name: "ad-agencies",
  size: "normal"
}

export default Icon
