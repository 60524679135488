import React, { useState } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from "react-share"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { toKebabCase } from "@src/utils"
import TableOfContents from "@components/table-of-contents/component"
import Animate from "@components/animate"
import SVG from "@components/svg"

import {
  facebook,
  link,
  link_has_been_copied,
  linkedin,
  post_meta_wrapper,
  social_container,
  inline_title,
  meta_wrapper,
  table_of_contents_container
} from "./styles.module.scss"

const PostMeta = ({ className, inlineTitle, showTableOfContents, postBody }) => {
  const [linkIsCopied, setLink] = useState(false)
  const postUrl = typeof window !== "undefined" ? window.location.href : ""

  const trackClick = (vendor, url) => {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: "Share",
        category: "Social Share",
        label: `${vendor} | ${url}`
      })
    }
  }

  const trackCopy = (url) => {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: "Share",
        category: "Link Copied",
        label: url
      })
    }
  }

  const extractHeadings = () => {
    if (!showTableOfContents) return []
    if (!postBody) return []

    const content = JSON.parse(postBody.json).content
    const headings = []

    content.forEach((heading) => {
      if (
        heading.nodeType === "heading-3" &&
        heading.content[0] &&
        heading.content[0].nodeType === "text"
      ) {
        headings.push({
          label: heading.content[0].value,
          id: toKebabCase(heading.content[0].value)
        })
      }
    })
    return headings
  }

  return (
    <div className={meta_wrapper}>
      <Animate animate="fade_in_up" delay={43} className={post_meta_wrapper}>
        <div className={classNames(social_container, className)}>
          {inlineTitle && <div className={inline_title}>{inlineTitle}</div>}
          <LinkedinShareButton url={postUrl} onClick={() => trackClick("LinkedIn", postUrl)}>
            <div className={linkedin}>
              <SVG name="linkedin" />
            </div>
          </LinkedinShareButton>
          <FacebookShareButton url={postUrl} onClick={() => trackClick("Facebook", postUrl)}>
            <div className={facebook}>
              <SVG name="facebook" />
            </div>
          </FacebookShareButton>
          <CopyToClipboard
            text={postUrl}
            onCopy={() => {
              setLink(!linkIsCopied)
              trackCopy(postUrl)
            }}
          >
            <button className={link}>
              <SVG name="link" />
            </button>
          </CopyToClipboard>
          {linkIsCopied ? <div className={link_has_been_copied}>Copied to clipboard.</div> : null}
        </div>

        {extractHeadings().length > 0 && (
          <div className={table_of_contents_container}>
            <TableOfContents items={extractHeadings()}></TableOfContents>
          </div>
        )}
      </Animate>
    </div>
  )
}

PostMeta.propTypes = {
  /**
   * CSS class(es) applied to the wrapping element
   */
  className: PropTypes.string,
  /**
   * Post Publication Date
   */
  date: PropTypes.string,
  /**
   * Inline Title
   */
  inlineTitle: PropTypes.string
}

export default PostMeta
