import React, { useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import classNames from "classnames"
import dig from "object-dig"
import startCase from "lodash/startCase"
import path from "path"

import { image, circle_container, svg_wrapper } from "./styles.module.scss"

import { circleSvg1, circleSvg2, circleSvg3, circleSvg4 } from "./circle-svgs"

export const defaultAltAttribute = (image) => {
  if (!image) return ""
  const filename = path.basename(image, path.extname(image))
  return startCase(filename)
}

const Image = ({ alt, className, src, theme, ...props }) => {
  const classes = classNames(image, { [className]: className })

  // ---------------------------------------- | Gatsby Image
  const gatsbyImageData = dig(src, "gatsbyImageData")

  if (gatsbyImageData) {
    const gImage = getImage(src)
    const imageName = dig(gatsbyImageData, "images", "fallback", "src")
    const width = dig(gatsbyImageData, "width")
    const height = dig(gatsbyImageData, "height")

    if (theme?.includes("circle")) {
      const svgRef = useRef(null)

      useEffect(() => {
        const handleScroll = () => {
          if (svgRef.current) {
            const scrollTop = window.scrollY
            const rotation = scrollTop * 0.05 // Adjust this multiplier to control the rotation speed
            svgRef.current.style.transform = `rotate(${rotation}deg)`
          }
        }

        window.addEventListener("scroll", handleScroll)
        return () => {
          window.removeEventListener("scroll", handleScroll)
        }
      }, [])

      const circleOptionsMap = {
        "circle-1": circleSvg1({ ref: svgRef }),
        "circle-2": circleSvg2({ ref: svgRef }),
        "circle-3": circleSvg3({ ref: svgRef }),
        "circle-4": circleSvg4({ ref: svgRef })
      }

      return (
        <div className={circle_container}>
          <div className={svg_wrapper}>
            {circleOptionsMap[theme]}

            <GatsbyImage
              className={classes}
              alt={alt || defaultAltAttribute(imageName)}
              image={gImage}
              width={width}
              height={height}
              loading={props?.loading ?? "lazy"}
            />
          </div>
        </div>
      )
    }

    return (
      <GatsbyImage
        className={classes}
        alt={alt || defaultAltAttribute(imageName)}
        image={gImage}
        width={width}
        height={height}
        loading={props?.loading ?? "lazy"}
      />
    )
  }

  // ---------------------------------------- | Native Image

  if (typeof src === "string") {
    return <img className={classes} src={src} alt={alt || defaultAltAttribute(src)} {...props} />
  }

  // ---------------------------------------- | Invalid src

  return null
}

Image.propTypes = {
  /**
   * Specifies the image alt attribute.
   */
  alt: PropTypes.string,
  /**
   * Classes attributed to the wrapping element.
   */
  className: PropTypes.string,
  /**
   * Specifies the image src attribute as a string or
   * Gatsby image object ([Gatsby Docs](https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-plugin-image/#all-options)).
   */
  src: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      childImageSharp: PropTypes.shape({
        gatsbyImageData: PropTypes.shape({
          layout: PropTypes.oneOf(["constrained", "fixed", "fullWidth"]),
          images: PropTypes.shape({
            fallback: PropTypes.shape({
              src: PropTypes.string,
              srcSet: PropTypes.string,
              sizes: PropTypes.string
            }),
            sources: PropTypes.arrayOf(
              PropTypes.shape({
                srcSet: PropTypes.string,
                type: PropTypes.string,
                sizes: PropTypes.string
              })
            )
          }),
          width: PropTypes.number,
          height: PropTypes.number
        })
      })
    })
  ]).isRequired,
  /**
   * Support for directly passing styles to the image component.
   */
  styles: PropTypes.object,
  /**
   * Specifies the image theme.
   */
  theme: PropTypes.oneOf(["default", "circle-1", "circle-2", "circle-3", "circle-4"]),
  loading: PropTypes.oneOf(["eager", "lazy"])
}

Image.defaultProps = {}

export default Image
