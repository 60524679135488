export default {
  props: {
    heading: "Top Categories",
    links: [
      {
        label: "Insights & Loyalty",
        url: "#"
      },
      {
        label: "Media",
        url: "#"
      },
      {
        label: "Data Science",
        url: "#"
      },
      {
        label: "Data Science",
        url: "#"
      }
    ]
  }
}
