import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import classNames from "classnames"
import ReactFitText from "react-fittext"

import Author from "@src/components/author"
import AuthorMiniBio from "@components/author-mini-bio"
import Block from "@components/block"
import Breadcrumbs from "@src/components/breadcrumbs"
import Content from "@components/content/component"
import RichContentObject from "@components/rich-content-object"
import Image from "@src/components/image"
import LatestPosts from "@src/components/latest-posts"
import Layout from "@src/layout"
import PostMeta from "./post-meta"
import TimeToRead from "@components/time-to-read"

import Alert from "@components/alert/component"

const { categoryPath } = require("@src/utils")
const pluralize = require("pluralize")
const dig = require("object-dig")

import {
  author_container,
  breadcrumbs_container,
  content,
  content_with_alert,
  desktop_text_col,
  gray_box,
  half_width,
  image_container_desktop,
  image_container_mobile,
  meta_container,
  post_body,
  post_type,
  post_time,
  content_wrapper,
  sidebar_wrapper,
  sticky_alert,
  top_meta_container_desktop,
  top_meta_container_mobile,
  header_meta_container,
  header_container_row,
  header_title
} from "./styles.module.scss"

const Post = (props) => {
  const { post, contact } = props.data

  const latestPosts_Config = {
    type: "knowledge-hub",
    title: "Visit our knowledge hub",
    theme: "gray_background",
    layout: "with_media_cards",
    padding: "medium",
    subtitle: "See what you can learn from our latest posts.",
    cta: {
      url: "/knowledge-hub",
      label: "View All Posts"
    }
  }

  let postSlug = post.resource_type?.slug?.split("/")
  let postType
  if (postSlug) {
    postType = postSlug[0] === "" ? postSlug[1] : postSlug[0]
  }
  const stickyAlert = post?.alert?.theme === "post-sticky" ? post?.alert : null
  const layoutAlert = post?.alert?.theme !== "post-sticky" ? post?.alert : null

  if (postType && postType !== "News") {
    postType = pluralize.singular(postType)
  }

  const breadcrumbs_config = {
    links: [
      {
        label: postType
      },
      {
        label: post.category.title,
        url: categoryPath(post.category)
      }
    ]
  }

  const { wordCount } = post

  let meta = post.meta_data || []

  if (!meta.some((t) => t["property"] === "og:image") && post.image) {
    meta.push({
      name: "og:image",
      property: "og:image",
      content: dig(post, "image", "file", "url")
    })
  }

  const renderAuthor = () => {
    if (post?.authors?.length)
      return (
        <div className={meta_container}>
          <div className={author_container}>
            {post.authors.map((author, idx) => (
              <Author
                {...author}
                publishDate={post.showPublishDate ? post.publishDate : null}
                by={post.by}
                key={`author-${idx}`}
              />
            ))}
          </div>
        </div>
      )

    if (!post?.authors?.length && post.showPublishDate)
      return (
        <div className={meta_container}>
          <div className={author_container}>{post.publishDate}</div>
        </div>
      )
  }

  useEffect(() => {
    const imageRatio =
      post.image?.gatsbyImageData?.width / post.image?.gatsbyImageData?.height ?? 1.3

    const onResize = () => {
      const desktopTextCol = document.querySelector("#desktop-text-column")
      if (!desktopTextCol) return

      const height = desktopTextCol.clientHeight
      const additionalHeight = 100
      document.body.style.setProperty(
        "--post-heading-img-min-width",
        `${imageRatio * height + additionalHeight}px`
      )
    }
    if (typeof window !== "undefined") {
      onResize()
      window.addEventListener("resize", onResize)
    }
    return () => {
      // cleanup
      if (typeof window !== "undefined") {
        window.removeEventListener("resize", onResize)
        document.body.style.removeProperty("--post-heading-img-min-width")
      }
    }
  }, [])

  return (
    <Layout
      title={post.title}
      meta={meta}
      locationPath={post.post_path}
      canonicalHost={post.canonicalHost}
      alert={layoutAlert}
      smallHeader={true}
      headerShadow={false}
    >
      <div className={gray_box}>
        <div className={desktop_text_col} id="desktop-text-column">
          <div className={header_meta_container}>
            <div className={breadcrumbs_container}>
              <Breadcrumbs
                config={{ internal: { content: JSON.stringify(breadcrumbs_config) } }}
                __typename={"ContentfulSnippet"}
              ></Breadcrumbs>
            </div>
            <div className={top_meta_container_desktop}>
              {(wordCount || post.duration) && (
                <TimeToRead
                  wordCount={wordCount}
                  duration={post.duration}
                  className={post_time}
                  type={postType}
                />
              )}
              <div className={post_type}>{post.resource_type?.title}</div>
            </div>
          </div>
          <div className={header_container_row}>
            <div className={image_container_mobile}>
              <Image src={post.image} loading="eager" />
            </div>
            <div className={top_meta_container_mobile}>
              {(wordCount || post.duration) && (
                <TimeToRead
                  wordCount={wordCount}
                  duration={post.duration}
                  className={post_time}
                  type={postType}
                />
              )}
              <div className={post_type}>{post.resource_type?.title}</div>
            </div>
            <div className={header_title}>
              <ReactFitText minFontSize={40} maxFontSize={65}>
                <h1>{post.title}</h1>
              </ReactFitText>
              {renderAuthor()}
            </div>
          </div>
        </div>
        <div className={image_container_desktop}>
          <Image src={post.image} loading="eager" />
        </div>
      </div>

      <div className={content_wrapper}>
        <div
          className={classNames({
            [content]: !stickyAlert,
            [content_with_alert]: stickyAlert
          })}
        >
          {post.rich_body && <RichContentObject content={{ ...post.rich_body }} />}
          {!post.rich_body && <Content className={post_body} body={post.body} />}
          {post?.authors?.length &&
            post.authors.map((author, idx) => (
              <AuthorMiniBio {...author} key={`author-bio-${idx}`} />
            ))}
        </div>

        <div className={sidebar_wrapper}>
          <PostMeta
            className={half_width}
            inlineTitle={"Share"}
            postBody={post.rich_body}
            showTableOfContents={post.showTableOfContents || false}
          />
          {stickyAlert && (
            <Alert {...stickyAlert} className={sticky_alert} theme="post-sticky-modal-alert" />
          )}
        </div>
      </div>

      <LatestPosts
        config={{ internal: { content: JSON.stringify(latestPosts_Config) } }}
        references={[post.category]}
      ></LatestPosts>

      <Block {...contact} />
    </Layout>
  )
}

Post.propTypes = {
  /**
   * Components to inject before the page content.
   */
  children: PropTypes.node,
  /**
   * An array of components that get mapped to the <Block /> component.
   */
  blocks: PropTypes.arrayOf(PropTypes.object)
}

Post.defaultProps = {
  containers: []
}

export const query = graphql`
  query PostQuery($id: String!) {
    post: contentfulPost(id: { eq: $id }) {
      ...PostAttributes
      authors {
        __typename
        ... on ContentfulAuthor {
          ...Author
        }
      }
    }
    contact: contentfulContainer(contentful_id: { eq: "4RcDk7ag4JUwJUyD8Vjd7G" }) {
      ...Container
      blocks {
        __typename
        ...Block
        ... on ContentfulRow {
          ...Row
          blocks {
            __typename
            ... on ContentfulSnippet {
              __typename
              ...Snippet
            }
            ... on ContentfulForm {
              __typename
              ...Form
            }
          }
        }
      }
    }
  }
`

export default Post
