/* eslint-disable react/prop-types */

import React from "react"
import classNames from "classnames"
import { useMediaQuery } from "react-responsive"

import Animate from "@components/animate"
import Container from "@components/container"
import Grid from "@components/grid"
import Image from "@components/image"

import { renderBlocks } from "@src/templates/page/utils"

import {
  active,
  image_align_left,
  image_align_right,
  image_container,
  pt_heading,
  primary,
  rich_content_block,
  rich_content_image,
  secondary,
  new_pillar_theme,
  new_pillar_theme_image,
  connection,
  line,
  show_overflow,
  no_label
} from "./styles.module.scss"

const RichContentBlock = ({
  primary_blocks,
  secondary_blocks,
  heading,
  label,
  image,
  image_alignment,
  theme,
  forwardedRef,
  showAnimation
}) => {
  const isMobile = useMediaQuery({
    query: "(max-width: 45em)"
  })

  const getAnimatedHeading = (input) => {
    return (
      <div>
        {showAnimation && !isMobile && (
          <div className={connection}>
            <div className={classNames(line, { [active]: showAnimation })}></div>
          </div>
        )}
        <h2 ref={forwardedRef}>{input}</h2>
      </div>
    )
  }

  let content = (
    <div>
      {(label || heading) && (
        <div
          className={classNames(pt_heading, {
            [no_label]: !label
          })}
        >
          {label && <p>{label}</p>}
          {heading && getAnimatedHeading(heading)}
        </div>
      )}
      <Animate
        className={`${primary} ${theme === "new_pillar_theme" ? new_pillar_theme : ""}`}
        animate="fade_in_down"
      >
        {renderBlocks(primary_blocks)}
      </Animate>
      {secondary_blocks && (
        <Animate
          className={`${secondary} ${theme === "new_pillar_theme" ? new_pillar_theme : ""}`}
          animate="fade_in_up"
        >
          {renderBlocks(secondary_blocks)}
        </Animate>
      )}
    </div>
  )

  let img = (
    <Animate className={image_container} animate="fade_in_up">
      <Image
        className={`${rich_content_image} ${
          theme === "new_pillar_theme" ? new_pillar_theme_image : ""
        }`}
        src={image}
      />
    </Animate>
  )

  return (
    <Container className={show_overflow} padding={isMobile ? "none" : "small"}>
      <Grid
        className={classNames(rich_content_block, {
          [image_align_left]: image_alignment === "left",
          [image_align_right]: image_alignment !== "left"
        })}
        layout={image_alignment === "left" ? "4060" : "6040"}
        gutter="small"
      >
        {image_alignment === "left" ? img : content}
        {image_alignment === "left" ? content : img}
      </Grid>
    </Container>
  )
}

export default RichContentBlock
