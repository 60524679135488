import React, { useEffect } from "react"
import { useQueryParam, StringParam } from "use-query-params"

export default function AgencySolutions() {
  const [utm_content] = useQueryParam("utm_content", StringParam)
  const [utm_medium] = useQueryParam("utm_medium", StringParam)
  const [utm_source] = useQueryParam("utm_source", StringParam)

  useEffect(() => {
    const params = new URLSearchParams()
    if (utm_content) params.append("utm_content", utm_content)
    if (utm_medium) params.append("utm_medium", utm_medium)
    if (utm_source) params.append("utm_source", utm_source)

    window.location.replace(`/agency-solutions?${params.toString()}`)
  }, [utm_content, utm_medium, utm_source])

  return <></>
}
