import React, { useRef } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { useMediaQuery } from "react-responsive"
import { useIsVisible } from "react-is-visible"

import SVG from "@components/svg"
import Grid from "@components/grid"
import Animate from "@components/animate"

const { parseContentfulJSON } = require("@src/utils")

import {
  arc,
  background_wrapper,
  benefits_group,
  benefit_item,
  benefits_list,
  container,
  header,
  is_visible
} from "./styles.module.scss"

const BenefitsList = ({ config }) => {
  const cfg = parseContentfulJSON(config)

  const nodeRef = useRef()
  const isVisible = useIsVisible(nodeRef, { once: true })
  const isMobile = useMediaQuery({ query: "(max-width: 45em)" })

  return (
    <div
      className={classNames(background_wrapper, {
        [is_visible]: isVisible
      })}
    >
      <div className={container}>
        <Grid layout="small_left">
          <div className={header}>
            <h2>{cfg.header}</h2>
            <p>{cfg.body}</p>
          </div>
        </Grid>
      </div>
      <div className={arc}>
        <SVG name="expression-arc" />
      </div>
      <div className={benefits_group} ref={nodeRef}>
        <div className={classNames(container, benefits_list)}>
          {cfg.benefits.map((item, i) => (
            <div className={benefit_item} key={`${item.header}-${i}`}>
              <Animate animate="fade_in" delay={isMobile ? 0 : i * 250 + 1000} noAnimation={false}>
                <SVG name="dot-white" />
              </Animate>
              <div>
                <h4>{item.header}</h4>
                <p>{item.body}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

BenefitsList.propTypes = {
  config: PropTypes.object
}

export default BenefitsList
