import React, { useState } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import Collapsible from "react-collapsible"
import slugify from "slugify"

import {
  collapsible_wrapper,
  collapsible_wrapper_open,
  trigger_icon,
  trigger_icon_open,
  trigger_wrapper,
  trigger_label,
  trigger_label_open,
  content_wrap
} from "./styles.module.scss"

const slugifyId = (id) => {
  if (typeof id !== "string") {
    id = ""
  }

  return slugify(id, {
    replacement: "_",
    lower: true
  }).replace(/\s/gm, "_")
}

const shouldAutoOpen = (id) => {
  if (typeof window === "undefined") {
    return false
  }

  return `#${id}` === window.location.hash
}

const ComponentName = ({ children, trigger, id }) => {
  id = slugifyId(id ? id : trigger)

  const [isTriggerOpen, setTriggerOpen] = useState(shouldAutoOpen(id))

  return (
    <Collapsible
      open={shouldAutoOpen(id)}
      containerElementProps={{
        id
      }}
      trigger={
        <div className={trigger_wrapper}>
          <span
            className={classNames(trigger_label, {
              [trigger_label_open]: isTriggerOpen
            })}
          >
            {trigger}
          </span>
          <span
            className={classNames(trigger_icon, {
              [trigger_icon_open]: isTriggerOpen
            })}
          ></span>
        </div>
      }
      className={classNames(collapsible_wrapper)}
      openedClassName={classNames(collapsible_wrapper, collapsible_wrapper_open)}
      onTriggerOpening={() => setTriggerOpen(true)}
      onTriggerClosing={() => setTriggerOpen(false)}
      contentInnerClassName={content_wrap}
    >
      {children}
    </Collapsible>
  )
}

ComponentName.propTypes = {
  /**
   * The text or element to appear in the trigger link.
   */
  trigger: PropTypes.string.isRequired,
  /**
   * Content for the collapsible section.
   */
  children: PropTypes.node.isRequired,
  /**
   * Optional id value, useful for linking to the accordion without worrying about the trigger updating. Defaults to snake_cased trigger.
   */
  id: PropTypes.string
}

ComponentName.defaultProps = {}

export default ComponentName
