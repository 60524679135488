import React from "react"

export const circleSvg1 = (props) => (
  <svg
    width="350"
    height="350"
    viewBox="0 0 350 350"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M315.8 260.2C268.7 337.9 167.4 362.8 89.7 315.7C11.9 268.7 -12.9 167.5 34.3 89.8C81.4 12 182.6 -12.8 260.4 34.2C297.9 56.9 323.1 92.1 333.8 131.3"
      stroke="#E85245"
      strokeMiterlimit="10"
    />
    <path
      d="M338.6 156.8C342.5 191.6 335.3 227.9 315.8 260.2"
      stroke="#E85245"
      strokeMiterlimit="10"
    />
    <path
      d="M86.0936 46.443C86.7704 40.8505 82.7854 35.7682 77.1929 35.0914C71.6004 34.4147 66.5181 38.3997 65.8414 43.9922C65.1646 49.5847 69.1496 54.6669 74.7421 55.3437C80.3346 56.0204 85.4168 52.0355 86.0936 46.443Z"
      fill="#522181"
    />
    <path
      d="M57.9179 285.684C58.2563 282.888 56.2638 280.346 53.4675 280.008C50.6713 279.67 48.1301 281.662 47.7917 284.458C47.4534 287.255 49.4459 289.796 52.2421 290.134C55.0384 290.473 57.5795 288.48 57.9179 285.684Z"
      fill="#E85245"
    />
    <path
      d="M343.173 157.391C343.485 154.814 341.649 152.473 339.072 152.161C336.495 151.849 334.153 153.685 333.841 156.262C333.529 158.839 335.366 161.181 337.943 161.493C340.519 161.805 342.861 159.968 343.173 157.391Z"
      fill="#8451EC"
    />
    <path
      d="M340.005 134.204C340.396 130.97 338.091 128.03 334.856 127.638C331.621 127.247 328.682 129.552 328.29 132.787C327.899 136.022 330.204 138.962 333.438 139.353C336.673 139.744 339.613 137.439 340.005 134.204Z"
      fill="#522181"
    />
    <path
      d="M48.1162 273.658C48.4545 270.862 46.462 268.321 43.6658 267.983C40.8695 267.644 38.3284 269.637 37.99 272.433C37.6516 275.229 39.6441 277.77 42.4404 278.109C45.2366 278.447 47.7778 276.455 48.1162 273.658Z"
      fill="#E85245"
    />
    <path
      d="M69.2972 296.563C69.6356 293.767 67.6431 291.226 64.8468 290.887C62.0506 290.549 59.5095 292.541 59.1711 295.338C58.8327 298.134 60.8252 300.675 63.6214 301.013C66.4177 301.352 68.9588 299.359 69.2972 296.563Z"
      fill="#BFB3EF"
    />
  </svg>
)

export const circleSvg2 = (props) => (
  <svg
    width="350"
    height="350"
    viewBox="0 0 350 350"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M46.9 76.6C49.7167 76.6 52 74.3167 52 71.5C52 68.6834 49.7167 66.4 46.9 66.4C44.0833 66.4 41.8 68.6834 41.8 71.5C41.8 74.3167 44.0833 76.6 46.9 76.6Z"
      fill="#BFB3EF"
    />
    <path
      d="M106.4 324.7C97.2 320.5 88.3 315.3 79.7 309.3C5.6 256.6 -11.8 153.9 40.8 79.8C93.4 5.60003 196.1 -11.8 270.2 40.8C344.3 93.4 361.8 196.1 309.2 270.3C268.1 328.2 196.5 351.5 131.8 333.9"
      stroke="#BFB3EF"
      strokeMiterlimit="10"
    />
    <path
      d="M309.3 88.5C314.105 88.5 318 84.6049 318 79.8C318 74.9951 314.105 71.1 309.3 71.1C304.495 71.1 300.6 74.9951 300.6 79.8C300.6 84.6049 304.495 88.5 309.3 88.5Z"
      fill="#8451EC"
    />
    <path
      d="M322.6 106.6C325.417 106.6 327.7 104.317 327.7 101.5C327.7 98.6834 325.417 96.4 322.6 96.4C319.783 96.4 317.5 98.6834 317.5 101.5C317.5 104.317 319.783 106.6 322.6 106.6Z"
      fill="#E85245"
    />
    <path
      d="M329 123.1C331.817 123.1 334.1 120.817 334.1 118C334.1 115.183 331.817 112.9 329 112.9C326.183 112.9 323.9 115.183 323.9 118C323.9 120.817 326.183 123.1 329 123.1Z"
      fill="#E85245"
    />
    <path
      d="M105.2 329C107.796 329 109.9 326.896 109.9 324.3C109.9 321.704 107.796 319.6 105.2 319.6C102.604 319.6 100.5 321.704 100.5 324.3C100.5 326.896 102.604 329 105.2 329Z"
      fill="#8451EC"
    />
    <path
      d="M128.7 338C131.958 338 134.6 335.359 134.6 332.1C134.6 328.842 131.958 326.2 128.7 326.2C125.442 326.2 122.8 328.842 122.8 332.1C122.8 335.359 125.442 338 128.7 338Z"
      fill="#522181"
    />
  </svg>
)

export const circleSvg3 = (props) => (
  <svg
    width="350"
    height="350"
    viewBox="0 0 350 350"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M41.4 78.8C94.5 5.10002 197.3 -11.7 271.2 41.4C345 94.5 361.7 197.3 308.6 271.2C255.5 345 152.6 361.8 78.8 308.6C43.3 283 21 245.9 13.3 206"
      stroke="#522181"
      strokeMiterlimit="10"
    />
    <path
      d="M10.4 180.1C9.19998 145.1 19.3 109.5 41.4 78.8"
      stroke="#522181"
      strokeMiterlimit="10"
    />
    <path
      d="M177 349.2C182.633 349.2 187.2 344.633 187.2 339C187.2 333.367 182.633 328.8 177 328.8C171.367 328.8 166.8 333.367 166.8 339C166.8 344.633 171.367 349.2 177 349.2Z"
      fill="#8451EC"
    />
    <path
      d="M211.3 341.2C214.117 341.2 216.4 338.917 216.4 336.1C216.4 333.283 214.117 331 211.3 331C208.483 331 206.2 333.283 206.2 336.1C206.2 338.917 208.483 341.2 211.3 341.2Z"
      fill="#E85245"
    />
    <path
      d="M15.1031 182.118C16.4748 179.914 15.8003 177.016 13.5966 175.644C11.3929 174.272 8.49445 174.947 7.12276 177.15C5.75107 179.354 6.42555 182.253 8.62926 183.624C10.833 184.996 13.7314 184.321 15.1031 182.118Z"
      fill="#8451EC"
    />
    <path
      d="M19.6504 207.95C21.3723 205.184 20.5256 201.545 17.7592 199.823C14.9929 198.101 11.3544 198.948 9.6325 201.714C7.91059 204.481 8.75728 208.119 11.5236 209.841C14.29 211.563 17.9284 210.716 19.6504 207.95Z"
      fill="#522181"
    />
    <path
      d="M232.3 334C235.117 334 237.4 331.717 237.4 328.9C237.4 326.083 235.117 323.8 232.3 323.8C229.483 323.8 227.2 326.083 227.2 328.9C227.2 331.717 229.483 334 232.3 334Z"
      fill="#E85245"
    />
    <path
      d="M276.9 50.9C279.717 50.9 282 48.6166 282 45.7999C282 42.9833 279.717 40.7 276.9 40.7C274.083 40.7 271.8 42.9833 271.8 45.7999C271.8 48.6166 274.083 50.9 276.9 50.9Z"
      fill="#BFB3EF"
    />
  </svg>
)

export const circleSvg4 = (props) => (
  <svg
    width="350"
    height="350"
    viewBox="0 0 350 350"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M97.2 320.1C17.1 277.1 -13 177.3 30 97.2C72.9 17.1 172.7 -13 252.8 30C332.9 73 363 172.7 320.1 252.9C299.4 291.5 265.6 318.5 227 331.2"
      stroke="#8451EC"
      strokeMiterlimit="10"
    />
    <path
      d="M201.8 337.5C167.2 343.2 130.5 337.9 97.4 320.1"
      stroke="#8451EC"
      strokeMiterlimit="10"
    />
    <path
      d="M252.4 35.6C255.217 35.6 257.5 33.3167 257.5 30.5C257.5 27.6834 255.217 25.4 252.4 25.4C249.583 25.4 247.3 27.6834 247.3 30.5C247.3 33.3167 249.583 35.6 252.4 35.6Z"
      fill="#E85245"
    />
    <path
      d="M21.3524 142.363C26.3717 139.805 28.3675 133.663 25.81 128.644C23.2525 123.625 17.1103 121.629 12.091 124.186C7.07172 126.744 5.07599 132.886 7.63346 137.905C10.1909 142.925 16.3331 144.92 21.3524 142.363Z"
      fill="#522181"
    />
    <path
      d="M23.9 116C26.7167 116 29 113.717 29 110.9C29 108.083 26.7167 105.8 23.9 105.8C21.0833 105.8 18.8 108.083 18.8 110.9C18.8 113.717 21.0833 116 23.9 116Z"
      fill="#8451EC"
    />
    <path
      d="M231 335.4C234.258 335.4 236.9 332.758 236.9 329.5C236.9 326.241 234.258 323.6 231 323.6C227.742 323.6 225.1 326.241 225.1 329.5C225.1 332.758 227.742 335.4 231 335.4Z"
      fill="#BFB3EF"
    />
    <path
      d="M206.3 341C208.896 341 211 338.896 211 336.3C211 333.704 208.896 331.6 206.3 331.6C203.704 331.6 201.6 333.704 201.6 336.3C201.6 338.896 203.704 341 206.3 341Z"
      fill="#E85245"
    />
    <path
      d="M268.6 44.9C271.417 44.9 273.7 42.6166 273.7 39.7999C273.7 36.9833 271.417 34.7 268.6 34.7C265.783 34.7 263.5 36.9833 263.5 39.7999C263.5 42.6166 265.783 44.9 268.6 44.9Z"
      fill="#E85245"
    />
  </svg>
)
