/* eslint-disable no-unused-vars */
import { graphql } from "gatsby"
import CategoryIndex from "./template"

export const query = graphql`
  query CategoryQuery($id: String = "") {
    category: contentfulCategory(id: { eq: $id }) {
      title
      theme
      slug
      body {
        childMarkdownRemark {
          html
        }
      }
      meta_data {
        name
        content
      }
    }
    posts: allContentfulPost(
      sort: { publishDate: DESC }
      filter: { category: { id: { eq: $id } } }
    ) {
      edges {
        node {
          ...PostAttributes
        }
      }
    }
    categories: allContentfulPostType(
      sort: { title: ASC }
      filter: { post: { elemMatch: { category: { id: { eq: $id } } } } }
    ) {
      nodes {
        title
      }
    }
    contact: contentfulContainer(slug: { eq: "contact-form-kh" }) {
      ...Container
      blocks {
        __typename
        ...Block
        ... on ContentfulRow {
          ...Row
          blocks {
            __typename
            ... on ContentfulSnippet {
              __typename
              ...Snippet
            }
            ... on ContentfulForm {
              __typename
              ...Form
            }
          }
        }
      }
    }
  }
`

export default CategoryIndex
