export default {
  blocks: [
    {
      __typename: "ContentfulRow",
      alignment: "center",
      centered: true,
      blocks: [
        {
          __typename: "ContentfulColumn",
          blocks: [
            {
              __typename: "ContentfulContentBlock",
              body: {
                childMarkdownRemark: {
                  html: "<h2>Lorem ipsum dolor sit amet</h2><p>Consectetur adipiscing elit. Etiam et pretium dolor. Maecenas et tempor libero, dictum efficitur odio. Sed rhoncus nibh felis, quis pharetra sapien tempor at. Vestibulum quis egestas massa. Pellentesque id magna mauris. In pulvinar, diam placerat scelerisque semper, velit elit cursus ipsum, accumsan pharetra sapien mi sit amet ante.</p>"
                }
              }
            }
          ]
        }
      ]
    }
  ]
}
