/* eslint-disable no-unused-vars, valid-typeof */
import React, { useEffect } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { useMediaQuery } from "react-responsive"

import Alert from "@components/alert"
import { component as Footer, fixtures as FooterFixtures } from "./footer"
import { component as Header } from "./header"
import HeaderFixtures from "./header/fixtures"

import { DebugMediaQueries } from "@plugins/gatsby-ample-debuggers"

const dig = require("object-dig")

const Layout = (props) => {
  let {
    alert,
    children,
    className,
    locationPath,
    meta,
    title,
    canonicalHost,
    customLayout,
    smallHeader,
    headerShadow,
    noindexnofollow
  } = props
  let has_jumbotron
  let gtags = []
  let tags_exec = false

  const isMobileNavigation = useMediaQuery({ query: "(max-width: 65em)" })

  const data = useStaticQuery(graphql`
    query layoutQuery {
      site {
        siteMetadata {
          title
          description
          author
          siteUrl
          ogImage
        }
      }
      footer: contentfulNavigation(slug: { eq: "footer" }) {
        fixtures: childContentfulNavigationContentJsonNode {
          copyright
          social_links {
            url
            icon
          }
          ctas {
            heading
            theme
            url
            image {
              title
              gatsbyImageData {
                layout
                backgroundColor
                width
                height
                images {
                  fallback {
                    src
                    srcSet
                    sizes
                  }
                  sources {
                    srcSet
                    type
                    sizes
                  }
                }
              }
            }
          }
          menus {
            heading
            links {
              url
              label
              children {
                url
                label
                children {
                  url
                  label
                }
              }
            }
          }
        }
      }
      header: contentfulNavigation(slug: { eq: "header" }) {
        fixtures: childContentfulNavigationContentJsonNode {
          button {
            url
            label
          }
          signInLinks {
            heading
            links {
              url
              label
            }
          }
          theme
          menus {
            alertId
            heading
            links {
              url
              label
              icon
              children {
                url
                label
                children {
                  url
                  label
                }
              }
            }
          }
        }
      }
    }
  `)

  useEffect(() => {
    if (typeof gtag !== undefined && !tags_exec) {
      try {
        gtags.forEach((tag) => eval(tag["content"]))
      } catch (e) {
        console.warn("Encountered a problem sending gtag events.")
      }
      tags_exec = true
    }
  }, [gtags])

  let FooterFixture = FooterFixtures

  if (data?.footer?.fixtures) {
    FooterFixture = data.footer.fixtures
  }
  if (data?.header?.fixtures) {
    HeaderFixtures.default = data.header.fixtures
  }

  if (children) {
    if (children[1]) {
      if (children[1][0]) {
        if (children[1][0].props.__typename === "ContentfulJumbotron") {
          has_jumbotron = true
        }
      }
    }
  }

  if (customLayout) {
    has_jumbotron = true
  }

  meta = meta || []
  gtags = meta.filter((meta) => meta.name && meta.name.slice(0, 5) === "gtag:")

  if (noindexnofollow) {
    meta.push({
      name: "robots",
      content: "noindex, nofollow"
    })
  }

  if (!meta.some((t) => t["name"] === "description")) {
    meta.push({
      name: "description",
      content: dig(data, "site", "siteMetadata", "description")
    })
    meta.push({
      property: "twitter:description",
      content: dig(data, "site", "siteMetadata", "description")
    })
  }

  if (meta.some((t) => t["name"] === "description")) {
    meta.push({
      property: "twitter:description",
      content: dig(
        meta.find((t) => t["name"] === "description"),
        "content"
      )
    })
  }

  if (meta.some((t) => t["name"] === "og:image")) {
    meta.push({
      property: "twitter:image",
      content: dig(
        meta.find((t) => t["name"] === "og:image"),
        "content"
      )
    })
    meta.push({
      property: "twitter:card",
      content: `summary_large_image`
    })
  }

  if (!meta.some((t) => t["name"] === "og:image")) {
    meta.push({
      property: "og:image",
      content: dig(data, "site", "siteMetadata", "ogImage")
    })
    meta.push({
      property: "twitter:image",
      content: dig(data, "site", "siteMetadata", "ogImage")
    })
    meta.push({
      property: "twitter:card",
      content: `summary_large_image`
    })
  }

  if (meta.some((t) => t["name"] === "title")) {
    title = dig(
      meta.find((t) => t["name"] === "title"),
      "content"
    )
    meta.push({
      property: "twitter:title",
      content: dig(
        meta.find((t) => t["name"] === "title"),
        "content"
      )
    })
    meta = meta.filter((t) => t["name"] !== "title")
  }

  meta = meta
    .filter((meta) => meta.name && meta.name.slice(0, 5) !== "gtag:")
    .map((meta) => {
      if (meta.name && meta.name.slice(0, 3) === "og:") {
        meta["property"] = meta.name
        delete meta["name"]
      } else if (meta.name) {
        delete meta["property"]
      }
      return meta
    })

  let metaProps = {
    title: title || dig(data, "site", "siteMetadata", "title"),
    titleTemplate:
      title && !title.includes("|") ? `%s | ${dig(data, "site", "siteMetadata", "title")}` : `%s`,
    meta: meta
  }

  const orgSchema = {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: "84.51°",
    description:
      "84.51° is a retail data science, insights and media company. We help the Kroger company, consumer packaged goods companies, agencies, publishers and affiliated partners create more personalized and valuable experiences for shoppers across the path to purchase. Powered by cutting edge science, we leverage 1st party retail data from nearly 1 of 2 US households and 2BN+ transactions to fuel a more customer-centric journey utilizing 8451 Insights, 8451 Loyalty Marketing and our retail advertising solution, Kroger Precision Marketing.",
    url: "https://www.8451.com",
    logo: "https://images.ctfassets.net/c23k8ps4z1xm/4lmc5E7hvh9ybHHSU0MumO/0af77062f3974a76678a29873db54607/kroger-8451_1563466527.jpg",
    address: {
      "@type": "PostalAddress",
      streetAddress: "100 W 5th St",
      addressLocality: "Cincinnati",
      addressRegion: "OH",
      postalCode: "45202",
      addressCountry: "USA"
    },
    sameAs: [
      "https://www.linkedin.com/company/84-51/",
      "https://twitter.com/8451group",
      "https://www.facebook.com/8451group/",
      "https://www.instagram.com/84.51/"
    ]
  }

  // Set canonical host - if CMS canonical host is set, use that, otherwise use default
  // One exception is when the canonical host is the default host, then we don't need to append the location path
  const defaultCanonicalHost = "https://www.8451.com"

  const isDefaultHost = /^https:\/\/www\.8451\.com\/?$/.test(canonicalHost)

  const canonicalHostUrl = canonicalHost
    ? isDefaultHost
      ? `${defaultCanonicalHost}${locationPath || "/"}`
      : canonicalHost
    : `${defaultCanonicalHost}${locationPath || "/"}`

  return (
    <>
      <Helmet {...metaProps}>
        <html lang="en" />
        <base href="/" />
        <script type="application/ld+json">{JSON.stringify(orgSchema)}</script>
        <link rel="canonical" href={canonicalHostUrl} />
      </Helmet>

      {alert && alert?.theme !== "bar-top" && (
        <Alert {...alert} theme={(alert?.form && "modal-bottom-right") || alert.theme} />
      )}

      <Header
        {...HeaderFixtures.default}
        signInLinks={HeaderFixtures.default.signInLinks}
        alert={alert}
        locationPath={locationPath}
        smallHeader={smallHeader}
        headerShadow={headerShadow}
      />

      <main
        className={classNames({
          [className]: className,
          ["has-alt-jumbotron"]: !has_jumbotron
        })}
        id="top"
      >
        {children}
      </main>

      <Footer {...FooterFixture} />

      <DebugMediaQueries isShowing={process.env.GATSBY_DEBUG_MEDIA_QUERIES} />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  /**
   * Additional classes on the main element.
   */
  className: PropTypes.string,
  customLayout: PropTypes.bool,
  description: PropTypes.string,
  locationPath: PropTypes.string,
  meta: PropTypes.array,
  title: PropTypes.string,
  canonicalHost: PropTypes.string,
  alert: PropTypes.shape({
    title: PropTypes.string,
    image: PropTypes.PropTypes.object,
    theme: PropTypes.string,
    form: PropTypes.object,
    button: PropTypes.shape({
      label: PropTypes.string,
      url: PropTypes.string
    })
  }),
  smallHeader: PropTypes.bool,
  headerShadow: PropTypes.bool,
  noindexnofollow: PropTypes.bool
}

export default Layout
