import React, { Fragment } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { renderBlocks } from "@src/templates/page/utils"

import SwiperCore, { Navigation, A11y, Autoplay } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

import "swiper/swiper.scss"
import "swiper/components/navigation/navigation.scss"

import Animate from "@components/animate"
import Block from "@components/block"
import Grid from "@components/grid"
import SVG from "@components/svg"
import { StaticImage } from "gatsby-plugin-image"

import dotVariants from "./graphics"

// -------------------------------------------------------- | styles

import {
  button_container,
  carousel_cards,
  carousel_cards_centered,
  carousel_careers,
  carousel_contact,
  carousel_desktop_display,
  content_container,
  copy_container,
  monitor,
  next_slide,
  prev_slide,
  slide_content,
  slider,
  heading_carousel_stack,
  button_carousel_stack,
  image_marquee_slider,
  slider_carousel_stack,
  line
} from "./styles.module.scss"

const themeOptions = {
  heading_carousel_stack: heading_carousel_stack,
  "cards-with-content": carousel_cards,
  "cards-with-content-centered": carousel_cards_centered,
  "desktop-display": carousel_desktop_display,
  careers: carousel_careers,
  contact: carousel_contact,
  image_marquee_slider: image_marquee_slider
}

// -------------------------------------------------------- | component

const imagesDirectory = "../../images/"

SwiperCore.use([Navigation, A11y, Autoplay])

const Carousel = (props) => {
  let { body, button_link, children, theme } = props

  if (props.__typename == "ContentfulCarousel" && (props.slides || []).length > 0) {
    children = renderBlocks(props.slides)
  }

  const classes = classNames({
    [themeOptions[theme]]: themeOptions[theme]
  })

  // ------------------------------------------------------

  const showLine = theme === "heading_carousel_stack" || theme === "image_marquee_slider"

  // ------------------------------------------------------

  let carouselProps

  if (theme === "cards-with-content" || theme === "heading_carousel_stack") {
    carouselProps = {
      breakpoints: {
        320: {
          slidesPerView: 1,
          spaceBetween: 20,
          width: 320
        },
        720: {
          spaceBetween: 30,
          width: 400
        }
      },
      setWrapperSize: true,
      width: 400
    }
  }
  if (theme === "cards-with-content-centered") {
    carouselProps = {
      breakpoints: {
        320: {
          slidesPerView: 1,
          spaceBetween: 20
        },
        600: {
          spaceBetween: 36,
          slidesPerView: 2
        },
        960: {
          spaceBetween: 36,
          slidesPerView: 3
        }
      },
      setWrapperSize: true,
      simulateTouch: false
    }
  }
  if (theme === "desktop-display") {
    carouselProps = {
      autoplay: {
        delay: 3000
      },
      centeredSlides: true
    }
  }

  if (theme === "image_marquee_slider") {
    carouselProps = {
      slidesPerView: "auto",
      spaceBetween: 20,
      variableWidth: true,
      speed: 5000,
      autoplay: {
        delay: 1
      },
      loop: true,
      centeredSlides: true
    }
  }

  // ------------------------------------------------------

  const buttons = (
    <Animate
      animate="fade_in"
      className={`${button_container} ${
        theme === "heading_carousel_stack" ? button_carousel_stack : ""
      }`}
    >
      <button id="prev-slide" className={prev_slide}>
        <SVG name="arrow-left" />
      </button>

      <button id="next-slide" className={next_slide}>
        <SVG name="arrow-right" />
      </button>
    </Animate>
  )

  // ------------------------------------------------------

  return (
    <Animate animate="fade_in" className={classes} delay={600}>
      {(theme === "cards-with-content" ||
        theme === "cards-with-content-centered" ||
        theme === "careers" ||
        theme === "heading_carousel_stack") && (
        <div className={copy_container}>
          {body && <div dangerouslySetInnerHTML={{ __html: body.childMarkdownRemark.html }} />}

          {(theme === "cards-with-content" || theme === "cards-with-content-centered") && buttons}
        </div>
      )}
      <div
        className={`${slider} ${theme === "heading_carousel_stack" ? slider_carousel_stack : ""}`}
      >
        {theme === "heading_carousel_stack" && buttons}
        <Swiper
          a11y
          loop={true}
          loopAdditionalSlides={(children || []).length}
          navigation={{ nextEl: "#next-slide", prevEl: "#prev-slide" }}
          {...carouselProps}
        >
          {(children || []).map((child, idx) => (
            <SwiperSlide key={idx}>
              {theme === "contact" || theme === "careers" ? (
                <Grid {...child.props} classNames={slide_content}>
                  {child.props.blocks.map((block, idx) => {
                    if (idx === 1) {
                      return (
                        <div className={content_container} key={idx}>
                          <Block {...block} />
                          {buttons}
                        </div>
                      )
                    } else {
                      return <Block key={idx} {...block} />
                    }
                  })}
                </Grid>
              ) : theme === "image_marquee_slider" ? (
                <div>
                  {child}
                  {dotVariants[idx > dotVariants.length ? idx - dotVariants.length : idx]}
                </div>
              ) : (
                child
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      {showLine && <div className={line}></div>}
      {theme === "desktop-display" && (
        <>
          <StaticImage
            alt="Desktop Monitor"
            className={monitor}
            placeholder="none"
            src={`${imagesDirectory}monitor.png`}
            width={1440}
          />
          {buttons}
        </>
      )}
      {theme === "cards-with-content-centered" && button_link && (
        <div className={button_container}>
          <Block {...button_link} />
        </div>
      )}
    </Animate>
  )
}

Carousel.propTypes = {
  __typename: PropTypes.string,
  /**
   * body of left aligned content block, only needed for cards-with-content theme
   */
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /**
   * button that displays below the carousel
   */
  button_link: PropTypes.object,
  /**
   * Children are the components that the carousel will display
   */
  children: PropTypes.node,
  slides: PropTypes.array,
  /**
   * Theme of carousel
   */
  theme: PropTypes.oneOf(Object.keys(themeOptions)),
  /**
   * Title of left aligned content block, only needed for cards-with-content theme
   */
  title: PropTypes.string
}

Carousel.defaultProps = {}

export default Carousel
